var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"horizontal-dividing-header"},[_c('h6',{staticClass:"header font-weight-medium",class:{
            'primary--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          }},[_vm._v(" YOUR WORKSPACES ")])]),(
          _vm.user.allcompanies &&
          _vm.$route.query.companyId &&
          (_vm.user.allcompanies.find((data) => data.id == _vm.$route.query.companyId)
            .isManager ||
            _vm.user.allcompanies.find(
              (data) => data.id == _vm.$route.query.companyId
            ).isAdmin)
        )?_c('v-btn',{staticClass:"primary",attrs:{"x-small":"","fab":"","depressed":""},on:{"click":function($event){_vm.showSnackBar = false;
          _vm.selectedUsers = {
            ids: [],
            type: [],
          };
          _vm.addWorkspaceDialog = true;
          _vm.workspacesavestatus = false;}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('WorkspaceSideBar')],1)],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.addWorkspaceDialog),callback:function ($$v) {_vm.addWorkspaceDialog=$$v},expression:"addWorkspaceDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Create workspace")]),_c('v-card-text',[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"}],attrs:{"label":"Workspace Name","name":"workspaceName","error-messages":_vm.errors.collect('create-workspace.workspace name'),"data-vv-name":"workspace name","data-vv-as":"workspace name","data-vv-scope":"create-workspace"},model:{value:(_vm.workspaceName),callback:function ($$v) {_vm.workspaceName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"workspaceName"}}),(_vm.users.length > 0 || _vm.companyinvitations.length > 0)?_c('div',{staticClass:"text-body-2 pb-3"},[_vm._v(" To know more about user types "),_c('v-icon',{staticClass:"mr-0 primary--text pa-0",attrs:{"medium":""},on:{"click":function($event){_vm.roleinfostatus = true}}},[_vm._v("info_outline")])],1):_vm._e(),_vm._l((_vm.users),function(user,index){return [_c('v-list-item',{key:user.id,staticClass:"pa-0 align-center"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row flex-md-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"7","md":"7"}},[_c('v-checkbox',{staticClass:"flex-grow-1",attrs:{"label":_vm.firstletterupercase(user.firstName) +
                        ' ' +
                        _vm.firstletterupercase(user.lastName),"value":user.id},model:{value:(_vm.selectedUsers.ids[index]),callback:function ($$v) {_vm.$set(_vm.selectedUsers.ids, index, $$v)},expression:"selectedUsers.ids[index]"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-select',{staticClass:"user-type pl-4",staticStyle:{"max-width":"200px"},attrs:{"label":"User type","outlined":"","items":_vm.selectItems,"item-text":"name","item-value":"value","error-messages":_vm.assignUserErrors[index] &&
                        (_vm.assignUserErrors[index].id == true ||
                          _vm.assignUserErrors[index].type == true)
                          ? ['Fill it']
                          : [],"attach":"","dense":""},model:{value:(_vm.selectedUsers.type[index]),callback:function ($$v) {_vm.$set(_vm.selectedUsers.type, index, $$v)},expression:"selectedUsers.type[index]"}})],1)],1)],1)]),(index + 1 < _vm.users.length)?_c('v-divider',{key:index}):_vm._e()]}),(_vm.companyinvitations.length > 0)?[_c('v-card-title',{staticClass:"pa-0 black--text"},[_vm._v("Invited users")]),_vm._l((_vm.companyinvitations),function(invitation,index){return [_c('v-list-item',{key:invitation.emailAddress,staticClass:"pa-0 align-center"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row flex-md-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pa-0 word-break",attrs:{"cols":"12","sm":"7","md":"7"}},[_c('v-checkbox',{staticClass:"flex-grow-1 word-break",attrs:{"label":invitation.emailAddress,"value":invitation.emailAddress},model:{value:(_vm.selectedEmails.emails[index]),callback:function ($$v) {_vm.$set(_vm.selectedEmails.emails, index, $$v)},expression:"selectedEmails.emails[index]"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-select',{staticClass:"user-type pl-4",staticStyle:{"max-width":"200px"},attrs:{"label":"User type","outlined":"","items":_vm.selectItems,"item-text":"name","item-value":"value","error-messages":_vm.assignEmailUserErrors[index] &&
                          (_vm.assignEmailUserErrors[index].id == true ||
                            _vm.assignEmailUserErrors[index].type == true)
                            ? ['Fill it']
                            : [],"attach":"","dense":""},model:{value:(_vm.selectedEmails.type[index]),callback:function ($$v) {_vm.$set(_vm.selectedEmails.type, index, $$v)},expression:"selectedEmails.type[index]"}})],1)],1)],1)]),(index + 1 < _vm.companyinvitations.length)?_c('v-divider',{key:index}):_vm._e()]})]:_vm._e()],2),_c('v-card-actions',{staticClass:"add-task-sticky sticky-bar elevation-4"},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"red","text":"","loading":_vm.workspacesavestatus,"disabled":_vm.workspacesavestatus},on:{"click":_vm.canceladdworkspace}},[_vm._v("Close")]),_c('v-btn',{staticClass:"gradient-button",class:{
              'white--text': !_vm.$vuetify.theme.dark,
              'black--text': _vm.$vuetify.theme.dark,
            },attrs:{"tile":"","contained":"","text":"","loading":_vm.workspacesavestatus,"disabled":_vm.workspacesavestatus},on:{"click":_vm.addWorkspace}},[_vm._v("Save")])],1)],1),_c('div',{staticClass:"ma-4s"},[_c('v-snackbar',{attrs:{"bottom":true,"color":_vm.snackbarType,"min-width":245},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.snackMessage)+" ")])],1)],1)],1),_c('role',{attrs:{"openinfo":_vm.roleinfostatus},on:{"coseinfo":_vm.modelclosing}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }