<template>
  <div
    :class="[
      canEditTask == false ? 'drag-disable' : '',
      subtask == true ? 'list-subtask' : '',
    ]"
  >
    <v-row
      class="d-none d-sm-none d-md-flex align-center elevation-1 rounded-lg mt-2 mb-2 pl-2 pr-2"
      :style="addPriorityColor(task)"
    >
      <v-col
        :cols="subtask == true ? '12' : '6'"
        class="pa-0 d-flex"
        :class="subtask == true ? 'flex-column' : 'flex-row'"
      >
        <div class="d-flex align-center title-group">
          <v-btn
            v-if="subtask == true && canEditTask == true"
            depressed
            icon
            x-small
            title="reorder task"
            class="reorder-drag"
          >
            <v-icon>drag_indicator</v-icon>
          </v-btn>
          <div
            v-if="
              subtask == false &&
              task.actionRequiredReadStatus &&
              task.actionRequiredBy == authuser.id
            "
            class="pb-2 pr-2"
          >
            <v-icon x-small color="primary" class="fiber-icon">
              fiber_manual_record
            </v-icon>
          </div>
          <userinfo
            :user="task.owner"
            :clickable="true"
            :userId="task.owner._id"
          ></userinfo>
          <v-card-title
            class="text-capitalize text-body-2 black--text pl-2 flex-grow-1 align-start flex-column"
          >
            <div
              v-if="subtask !== true"
              class="d-flex pl-0 text-caption text-uppercase word-break font-weight-bold"
              :class="{
                'primary--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <router-link
                :to="{
                  name: 'single-project',
                  params: { id: task.project },
                  query: {
                    companyId: $route.query.companyId,
                  },
                }"
                class="text-decoration-none"
              >
                <v-icon x-small color="primary" class="pb-1">work</v-icon>
                {{ getprojectdetail(task).name }}</router-link
              >
            </div>
            <div class="d-flex align-center">
              <v-btn
                v-if="task.IssubtaskParent"
                x-small
                class="transparent elevation-0 ml-0 pa-0 pr-1"
                style="min-width: 10px"
              >
                <v-icon small color="grey"> polyline </v-icon>
              </v-btn>
              <div
                class="text-capitalize week-title word-break pt-1"
                @click="gototask(task)"
              >
                <span class="titletext">{{ task.title }}</span>
              </div>
            </div>
            <div
              v-if="task.startDate && task.endDate"
              :class="
                $route.name == 'single-task'
                  ? 'd-none today-date-task'
                  : 'd-flex pt-1 align-center today-date-task'
              "
            >
              <v-icon small>event</v-icon>
              <span class="text-body-2 black--text text-caption pl-1">
                {{
                  moment(task.startDate).format("MMM Do") +
                  " - " +
                  moment(task.endDate).format("MMM Do")
                }}</span
              >
            </div>
            <div
              class="align-center flex-wrap mt-2"
              :class="[subtask == true ? 'd-flex' : 'd-none']"
              style="width: 100%"
            >
              <div
                :class="[
                  windowWidth > 480 ? 'col-4 d-flex' : 'col-12 d-none',
                  subtask == true ? 'col-4 d-flex' : '',
                ]"
                class="pa-0"
              >
                <div
                  class="pl-0 text-caption text-uppercase word-break font-weight-bold"
                  :class="{
                    'primary--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                >
                  <p class="text-truncate mb-0 text-caption chip-text-width">
                    <router-link
                      :to="{
                        name: 'single-project',
                        params: { id: task.project },
                        query: {
                          companyId: $route.query.companyId,
                        },
                      }"
                      class="text-decoration-none"
                    >
                      <v-icon x-small color="primary" class="pb-1">work</v-icon>
                      {{ getprojectdetail(task).name }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                :class="[
                  windowWidth > 480 ? 'col-4 d-flex ' : 'col-6 d-none',
                  subtask == true ? 'col-4 d-flex' : '',
                ]"
                class="pa-0"
              >
                <div
                  v-if="task.actionrequired"
                  class="d-flex align-center justify-start"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="text-caption mr-0 mb-0 font-weight-bold pr-1"
                      >
                        AR
                      </p>
                    </template>
                    <span>Action Required</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      task.actionrequired &&
                      task.actionrequired.firstName.length >= 6
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="text-capitalize text-body-2 text-truncate mb-0 tooltip-width-action"
                      >
                        {{ trucate(task.actionrequired.firstName) }}
                      </p>
                    </template>
                    <span class="text-capitalize">{{
                      task.actionrequired.firstName
                    }}</span>
                  </v-tooltip>
                  <p
                    v-else
                    class="text-body-2 mb-0"
                    :class="
                      $route.name == 'single-task'
                        ? 'width-chip'
                        : 'tooltip-width'
                    "
                  >
                    {{ task.actionrequired.firstName }}
                  </p>
                </div>
              </div>
              <div
                :class="[
                  windowWidth > 480 ? 'col-4 d-flex' : 'col-6 d-none',
                  subtask == true ? 'col-4 d-flex' : '',
                ]"
                class="pa-0"
              >
                <div
                  :class="subtask == true ? '' : 'pr-2'"
                  v-if="canEditDeleteTask(task)"
                >
                  <v-btn
                    depressed
                    class="transparent pa-0 text-body-2 text-capitalize"
                    small
                    @click="updatetaskascompleted(task)"
                    :class="
                      task.Iscompleted == true ? 'green--text' : 'grey--text'
                    "
                  >
                    <v-icon small>check_circle</v-icon>&nbsp;
                    <small class="successmessage">{{
                      task.Iscompleted == true
                        ? "Mark as Incomplete"
                        : "Mark as Complete"
                    }}</small>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-title>
        </div>
      </v-col>

      <v-col :cols="subtask == true ? '12' : '6'" class="pa-0">
        <v-row class="align-center today-list-task">
          <v-col
            :cols="[
              subtask == true
                ? '3'
                : authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ) &&
                  (authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ).isAdmin == true ||
                    authuser.allcompanies.find(
                      (data) => data.id == $route.query.companyId
                    ).isManager == true)
                ? '3'
                : '4',
            ]"
            class="pa-0"
          >
            <div>
              <v-select
                :value="task.priority"
                @input="$emit('input', $event)"
                :items="
                  getprojectdetail(task).priorities.filter(
                    (data) => data.activeStatus == true
                  )
                "
                item-text="name"
                item-value="id"
                solo
                dense
                flat
                label="Priority"
                hide-details
                :menu-props="{ closeOnContentClick: true }"
                class="list-select-dropdown today-chip-width"
                :class="subtask == true ? 'list-subtask' : ''"
              >
                <template v-slot:item="{ item }">
                  <v-list-item
                    :input-value="item.id == task.priority"
                    @click="changePriority(item)"
                  >
                    <p class="text-body-2 mb-0">
                      {{ item.name }}
                    </p>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip
                    bottom
                    :max-width="500"
                    :disabled="
                      windowWidth > 1028 &&
                      item.name.length > (subtask == true ? 13 : 7)
                        ? false
                        : true
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                      >
                        <v-chip
                          outlined
                          small
                          :style="{ color: item.color }"
                          v-bind="attrs"
                          v-on="on"
                          ><p
                            class="text-body-2 text-truncate mb-0"
                            :class="[
                              $route.name == 'single-task'
                                ? 'width-chip'
                                : 'tooltip-width',
                            ]"
                          >
                            {{ trucate(item.name) }}
                          </p></v-chip
                        >
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:append>
                  <span></span>
                </template>
              </v-select>
            </div>
          </v-col>
          <!--Start Status -->
          <v-col
            :cols="[
              subtask == true
                ? '3'
                : authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ) &&
                  (authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ).isAdmin == true ||
                    authuser.allcompanies.find(
                      (data) => data.id == $route.query.companyId
                    ).isManager == true)
                ? '3'
                : '4',
            ]"
            class="pa-0"
          >
            <div>
              <v-select
                :value="task.status"
                @input="$emit('input', $event)"
                :items="
                  getprojectdetail(task).statuses.filter(
                    (data) => data.activeStatus == true
                  )
                "
                item-text="name"
                item-value="id"
                solo
                dense
                flat
                label="status"
                hide-details
                :menu-props="{ closeOnContentClick: true }"
                class="list-select-dropdown today-chip-width"
                @click="disableTooltip()"
                :class="subtask == true ? 'list-subtask' : ''"
              >
                <template v-slot:item="{ item }">
                  <v-list-item
                    :input-value="item.id == task.status"
                    @click="changeStatus(item)"
                  >
                    <p class="text-body-2 mb-0">
                      {{ item.name }}
                    </p>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip
                    bottom
                    :max-width="500"
                    :disabled="
                      windowWidth > 1028 &&
                      item.name.length > (subtask == true ? 13 : 7)
                        ? false
                        : true
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                      >
                        <v-chip
                          outlined
                          small
                          :style="{ color: item.color }"
                          v-bind="attrs"
                          v-on="on"
                          ><p
                            class="text-body-2 text-truncate mb-0"
                            :class="
                              $route.name == 'single-task'
                                ? 'width-chip'
                                : 'tooltip-width'
                            "
                          >
                            {{ item.name }}
                          </p></v-chip
                        >
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:append>
                  <span></span>
                </template>
              </v-select>
            </div>
          </v-col>
          <!--End Status -->
          <v-col
            :cols="[
              subtask == true
                ? '3'
                : authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ) &&
                  (authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ).isAdmin == true ||
                    authuser.allcompanies.find(
                      (data) => data.id == $route.query.companyId
                    ).isManager == true)
                ? '3'
                : '4',
            ]"
            class="pa-0"
          >
            <v-select
              :value="task.type"
              @input="$emit('input', $event)"
              :items="
                getprojectdetail(task).task_types.filter(
                  (data) => data.activeStatus == true
                )
              "
              item-text="name"
              item-value="id"
              solo
              flat
              dense
              label="Type"
              hide-details
              :menu-props="{ closeOnContentClick: true }"
              class="list-select-dropdown word-break"
              @click="disableTooltip()"
            >
              <template v-slot:item="{ item }">
                <v-list-item
                  :input-value="item.id == task.type"
                  @click="changetasktype(item)"
                  class="pa-1"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon small>{{ item.icon }}</v-icon>
                    <p class="text-body-2 mb-0 pt-1 pl-1 text-caption">
                      {{ item.name }}
                    </p>
                  </div>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <v-tooltip
                  bottom
                  :max-width="500"
                  :disabled="
                    windowWidth > 1028 &&
                    item.name.length > (subtask == true ? 13 : 10)
                      ? false
                      : true
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center flex-grow-1 justify-center">
                      <v-icon small>{{ item.icon }}</v-icon>
                      <p
                        class="text-truncate mb-0 text-left width-of-chip text-caption pl-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.name }}
                      </p>
                    </div>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-select>
          </v-col>
          <v-col
            :cols="[
              subtask == true
                ? '3'
                : authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ) &&
                  (authuser.allcompanies.find(
                    (data) => data.id == $route.query.companyId
                  ).isAdmin == true ||
                    authuser.allcompanies.find(
                      (data) => data.id == $route.query.companyId
                    ).isManager == true)
                ? '4'
                : '3',
            ]"
            :class="[
              subtask == true ? 'pl-4 pa-3 text-left' : 'pa-0',
              $route.name == 'single-task'
                ? 'd-flex hide-data-todaymytask'
                : 'd-none hide-data-todaymytask',
            ]"
            v-if="task.startDate && task.endDate"
          >
            <v-icon small>event</v-icon>
            <span class="text-body-2 black--text text-caption">
              {{
                moment(task.startDate).format("MMM Do") +
                " - " +
                moment(task.endDate).format("MMM Do")
              }}</span
            >
          </v-col>
          <v-col
            v-if="
              task.actionrequired &&
              authuser.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ) &&
              (authuser.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ).isAdmin == true ||
                authuser.allcompanies.find(
                  (data) => data.id == $route.query.companyId
                ).isManager == true)
            "
            :cols="subtask == true ? '3' : '3'"
            class="pa-0 pl-3 align-center"
            :class="subtask == true ? 'd-none' : 'd-flex'"
          >
            <v-chip outlined small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    class="text-caption mr-0 mb-0 font-weight-bold pr-1"
                  >
                    AR
                  </p>
                </template>
                <span>Action Required</span>
              </v-tooltip>
              <v-tooltip
                bottom
                :disabled="
                  windowWidth > 959 &&
                  task.actionrequired.firstName.length >= 15
                    ? false
                    : true
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize text-body-2 text-truncate mb-0 tooltip-width-action"
                  >
                    {{ task.actionrequired.firstName }}
                  </p>
                </template>
                <span class="text-capitalize">
                  {{ task.actionrequired.firstName }}</span
                >
              </v-tooltip>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="d-flex d-sm-flex d-md-none align-center elevation-2 rounded-lg mt-0 mb-2"
      :class="subtask == true ? 'pa-2' : 'pa-2'"
      :style="addPriorityColor(task)"
    >
      <v-col cols="12" class="pa-0">
        <v-row class="align-center pa-0">
          <v-col cols="1" class="pa-0">
            <v-btn
              v-if="subtask == true"
              depressed
              icon
              x-small
              title="reorder task"
              class="reorder-drag"
            >
              <v-icon>drag_indicator</v-icon>
            </v-btn>
          </v-col>
          <v-col
            :cols="subtask == true ? '11' : '12'"
            class="pa-0 d-flex align-center"
            :class="windowWidth < 768 ? 'drag-disable' : ''"
          >
            <div
              class="d-flex align-center flex-grow-1"
              :class="subtask == true ? '' : 'pl-0'"
            >
              <div
                v-if="
                  task.actionRequiredReadStatus &&
                  task.actionRequiredBy == authuser.id
                "
                class="pb-2 pr-2"
              >
                <v-icon x-small color="primary" class="fiber-icon">
                  fiber_manual_record
                </v-icon>
              </div>
              <v-btn
                v-if="task.IssubtaskParent"
                x-small
                class="transparent elevation-0 ml-0 pa-0"
                style="min-width: 10px; position: relative; left: -5px"
              >
                <v-icon small color="grey"> polyline </v-icon>
              </v-btn>
              <div>
                <userinfo
                  :user="task.owner"
                  :clickable="true"
                  :userId="task.owner._id"
                ></userinfo>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div
                  class="pa-0 d-flex"
                  :class="[
                    windowWidth < 768 ? 'drag-disable' : '',
                    windowWidth > 480 ? 'flex-column' : 'flex-column',
                  ]"
                >
                  <v-card-title
                    class="text-capitalize text-body-2 black--text pa-0"
                  >
                    <div
                      class="titletext text-decoration-none week-title black--text text-md-truncate pl-0"
                      @click="gototask(task)"
                    >
                      <span class="titletext">{{ task.title }}</span>
                    </div>
                  </v-card-title>
                  <div
                    class="align-center"
                    :class="windowWidth < 480 ? 'd-flex' : 'd-none'"
                  >
                    <div
                      class="pl-0 text-caption text-uppercase word-break font-weight-bold flex-grow-1"
                      :class="[
                        {
                          'primary--text': !$vuetify.theme.dark,
                          'black--text': $vuetify.theme.dark,
                        },
                      ]"
                    >
                      <p
                        class="text-truncate mb-0 text-caption chip-text-width"
                      >
                        <router-link
                          :to="{
                            name: 'single-project',
                            params: { id: task.project },
                            query: {
                              companyId: $route.query.companyId,
                            },
                          }"
                          class="text-decoration-none"
                        >
                          <v-icon x-small color="primary" class="pb-1"
                            >work</v-icon
                          >
                          {{ getprojectdetail(task).name }}
                        </router-link>
                      </p>
                    </div>
                    <div
                      :class="subtask == true ? '' : 'pr-0'"
                      v-if="canEditDeleteTask(task)"
                    >
                      <v-btn
                        depressed
                        class="transparent pa-0 text-body-2 text-capitalize"
                        small
                        @click="updatetaskascompleted(task)"
                        :class="
                          task.Iscompleted == true
                            ? 'green--text'
                            : 'grey--text'
                        "
                      >
                        <v-icon small>check_circle</v-icon>&nbsp;
                        <small class="successmessage">{{
                          task.Iscompleted == true
                            ? "Mark as Incomplete"
                            : "Mark as Complete"
                        }}</small>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  class="align-center flex-wrap mt-2"
                  :class="[windowWidth > 480 ? 'd-flex' : 'd-none']"
                >
                  <div
                    :class="[
                      windowWidth > 480 ? 'col-4 d-flex' : 'col-12 d-none',
                      subtask == true ? 'col-4 d-flex' : '',
                    ]"
                    class="pa-0"
                  >
                    <div
                      class="pl-0 text-caption text-uppercase word-break font-weight-bold"
                      :class="{
                        'primary--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                    >
                      <p
                        class="text-truncate mb-0 text-caption chip-text-width"
                      >
                        <router-link
                          :to="{
                            name: 'single-project',
                            params: { id: task.project },
                            query: {
                              companyId: $route.query.companyId,
                            },
                          }"
                          class="text-decoration-none"
                        >
                          <v-icon x-small color="primary" class="pb-1"
                            >work</v-icon
                          >
                          {{ getprojectdetail(task).name }}
                        </router-link>
                      </p>
                    </div>
                  </div>
                  <div
                    :class="[
                      windowWidth > 480 ? 'col-4 d-flex ' : 'col-6 d-none',
                      subtask == true ? 'col-4 d-flex' : '',
                    ]"
                    class="pa-0"
                    v-if="task.actionrequired"
                  >
                    <div class="d-flex align-center justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <p
                            v-bind="attrs"
                            v-on="on"
                            class="text-caption mr-0 mb-0 font-weight-bold pr-1"
                          >
                            AR
                          </p>
                        </template>
                        <span>Action Required</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        v-if="task.actionrequired.firstName.length >= 15"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <p
                            v-bind="attrs"
                            v-on="on"
                            class="text-capitalize text-body-2 text-truncate mb-0 tooltip-width-action"
                          >
                            {{ trucate(task.actionrequired.firstName) }}
                          </p>
                        </template>
                        <span class="text-capitalize">{{
                          task.actionrequired.firstName
                        }}</span>
                      </v-tooltip>
                      <p
                        v-else
                        class="text-body-2 mb-0"
                        :class="
                          $route.name == 'single-task'
                            ? 'width-chip'
                            : 'tooltip-width'
                        "
                      >
                        {{ task.actionrequired.firstName }}
                      </p>
                    </div>
                  </div>
                  <div
                    :class="[
                      windowWidth > 480 ? 'col-4 d-flex' : 'col-6 d-none',
                      subtask == true ? 'col-4 d-flex' : '',
                    ]"
                    class="pa-0"
                  >
                    <div
                      :class="subtask == true ? '' : 'pr-2'"
                      v-if="canEditDeleteTask(task)"
                    >
                      <v-btn
                        depressed
                        class="transparent pa-0 text-body-2 text-capitalize"
                        small
                        @click="updatetaskascompleted(task)"
                        :class="
                          task.Iscompleted == true
                            ? 'green--text'
                            : 'grey--text'
                        "
                      >
                        <v-icon small>check_circle</v-icon>&nbsp;
                        <small class="successmessage">{{
                          task.Iscompleted == true
                            ? "Mark as Incomplete"
                            : "Mark as Complete"
                        }}</small>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row
          class="align-center"
          :class="windowWidth < 768 ? 'drag-disable' : ''"
        >
          <v-col
            :cols="windowWidth > 480 ? 3 : 4"
            class="pa-0 d-flex"
            :class="windowWidth < 768 ? 'drag-disable' : ''"
          >
            <div>
              <v-select
                :value="task.priority"
                @input="$emit('input', $event)"
                :items="
                  getprojectdetail(task).priorities.filter(
                    (data) => data.activeStatus == true
                  )
                "
                item-text="name"
                item-value="id"
                solo
                dense
                flat
                label="Priority"
                hide-details
                :menu-props="{ closeOnContentClick: true }"
                class="list-select-dropdown today-chip-width"
                :class="subtask == true ? 'list-subtask' : ''"
                @click="disableTooltip()"
              >
                <template v-slot:item="{ item }">
                  <v-list-item
                    :input-value="item.id == task.priority"
                    @click="changePriority(item)"
                  >
                    <p class="text-body-2 mb-0">
                      {{ item.name }}
                    </p>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip
                    bottom
                    :max-width="500"
                    v-if="item.name.length > 14"
                    :disabled="
                      item.name.length < 14 && windowWidth <= 1028
                        ? true
                        : false
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                      >
                        <v-chip
                          outlined
                          small
                          :style="{ color: item.color }"
                          v-bind="attrs"
                          v-on="on"
                          ><p
                            class="text-body-2 text-truncate mb-0"
                            :class="
                              $route.name == 'single-task'
                                ? 'width-chip'
                                : 'tooltip-width'
                            "
                          >
                            {{ item.name }}
                          </p></v-chip
                        >
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <div
                    v-else
                    class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                  >
                    <v-chip outlined small :style="{ color: item.color }"
                      ><p
                        class="text-body-2 text-truncate mb-0"
                        :class="
                          $route.name == 'single-task'
                            ? 'width-chip'
                            : 'tooltip-width'
                        "
                      >
                        {{ item.name }}
                      </p>
                    </v-chip>
                  </div>
                </template>
                <template v-slot:append>
                  <span></span>
                </template>
              </v-select>
            </div>
          </v-col>
          <!--Start Status -->
          <v-col
            :cols="windowWidth > 480 ? 3 : 4"
            class="pa-0 d-flex"
            :class="windowWidth < 768 ? 'drag-disable' : ''"
          >
            <div>
              <v-select
                :value="task.status"
                @input="$emit('input', $event)"
                :items="
                  getprojectdetail(task).statuses.filter(
                    (data) => data.activeStatus == true
                  )
                "
                item-text="name"
                item-value="id"
                solo
                dense
                flat
                label="status"
                hide-details
                :menu-props="{ closeOnContentClick: true }"
                class="list-select-dropdown today-chip-width"
                :class="subtask == true ? 'list-subtask' : ''"
                @click="disableTooltip()"
              >
                <template v-slot:item="{ item }">
                  <v-list-item
                    :input-value="item.id == task.status"
                    @click="changeStatus(item)"
                  >
                    <p class="text-body-2 mb-0">
                      {{ item.name }}
                    </p>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip
                    bottom
                    :max-width="500"
                    v-if="item.name.length > 14"
                    :disabled="
                      item.name.length < 14 && windowWidth <= 1028
                        ? true
                        : false
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                      >
                        <v-chip
                          outlined
                          small
                          :style="{ color: item.color }"
                          v-bind="attrs"
                          v-on="on"
                          ><p
                            class="text-body-2 text-truncate mb-0"
                            :class="
                              $route.name == 'single-task'
                                ? 'width-chip'
                                : 'tooltip-width'
                            "
                          >
                            {{ trucate(item.name) }}
                          </p></v-chip
                        >
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <div
                    v-else
                    class="d-flex align-center justify-center justify-sm-start justify-md-center flex-grow-1 text-center out-chip-width"
                  >
                    <v-chip outlined small :style="{ color: item.color }"
                      ><p
                        class="text-body-2 text-truncate mb-0"
                        :class="
                          $route.name == 'single-task'
                            ? 'width-chip'
                            : 'tooltip-width'
                        "
                      >
                        {{ item.name }}
                      </p></v-chip
                    >
                  </div>
                </template>
                <template v-slot:append>
                  <span></span>
                </template>
              </v-select>
            </div>
          </v-col>
          <!--End Status -->
          <v-col :cols="windowWidth > 480 ? 3 : 4">
            <v-select
              :value="task.type"
              @input="$emit('input', $event)"
              :items="
                getprojectdetail(task).task_types.filter(
                  (data) => data.activeStatus == true
                )
              "
              item-text="name"
              item-value="id"
              solo
              flat
              dense
              label="Type"
              hide-details
              :menu-props="{ closeOnContentClick: true }"
              class="list-select-dropdown word-break"
              @click="disableTooltip()"
            >
              <template v-slot:item="{ item }">
                <v-list-item
                  :input-value="item.id == task.type"
                  @click="changetasktype(item)"
                  class="pa-1"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon small>{{ item.icon }}</v-icon>
                    <p class="text-body-2 mb-0 pt-1 pl-1">
                      {{ item.name }}
                    </p>
                  </div>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center flex-grow-1 justify-start">
                  <v-icon small>{{ item.icon }}</v-icon>
                  <p
                    class="text-truncate mb-0 text-left width-of-chip text-body-2"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-select>
          </v-col>
          <v-col
            :cols="windowWidth > 480 ? 3 : 5"
            class="justify-start align-center"
            :class="windowWidth > 480 ? 'd-none' : 'd-flex'"
          >
            <div
              v-if="task.actionrequired"
              class="d-flex align-center justify-start"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    class="text-caption mr-0 mb-0 font-weight-bold pr-1"
                  >
                    AR
                  </p>
                </template>
                <span>Action Required</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="task.actionrequired.firstName.length >= 15"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize text-body-2 text-truncate mb-0 tooltip-width-action"
                  >
                    {{ trucate(task.actionrequired.firstName) }}
                  </p>
                </template>
                <span class="text-capitalize">{{
                  task.actionrequired.firstName
                }}</span>
              </v-tooltip>
              <p
                v-else
                class="text-body-2 mb-0"
                :class="
                  $route.name == 'single-task' ? 'width-chip' : 'tooltip-width'
                "
              >
                {{ task.actionrequired.firstName }}
              </p>
            </div>
          </v-col>
          <v-col
            :cols="windowWidth > 480 ? 3 : 7"
            class="d-flex justify-start align-center pl-4"
            v-if="task.startDate && task.endDate"
          >
            <v-icon small>event</v-icon>
            <span class="text-caption black--text pl-1">
              {{
                moment(task.startDate).format("MMM Do") +
                " - " +
                moment(task.endDate).format("MMM Do")
              }}</span
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <confirmation
      :propdialog="completeconfirm"
      dialogTitle="Are you sure you want to mark this task complete as you are not the action required user?"
      @onConfirmed="confirmupdate"
      @onCancel="closemethod"
      key="complete-confirmtoday"
    >
    </confirmation>
  </div>
</template>
<script>
import { bus } from "../../../main";
import confirmation from "src/components/ConfirmationDialog.vue";
import moment from "moment";
import userinfo from "./../dashboard/_userAvatar.vue";
export default {
  props: {
    task: {
      required: true,
      type: Object,
    },
    subtask: {
      required: true,
      type: Boolean,
    },
    canEditTask: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      completeconfirm: false,
      completetaskitem: "",
      moment: moment,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight - 230,
      windowMobileHeight: window.innerHeight - 230,
      titleTooltip: false,
    };
  },
  mounted() {},
  computed: {
    authuser() {
      return JSON.parse(this.$store.state.account.user);
    },
    projects() {
      return this.$store.state.mywork.userprojects;
    },
  },
  methods: {
    disableTooltip() {
      this.toolTip = true;
    },
    canEditDeleteTask(task) {
      var findProjectIndex = task.projectinfo.assignedToUsers.findIndex(
        (data) =>
          data.user == this.authuser.id &&
          data.isManager == true &&
          data.isActive == true
      );
      var taskUserIndex = task.assignedToUsers.findIndex(
        (data) => data.user == this.authuser.id
      );
      var returnstatus = false;
      if (
        this.authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin
      ) {
        returnstatus = true;
      } else {
        if (findProjectIndex != -1 || taskUserIndex != -1) {
          returnstatus = true;
        }
      }
      return returnstatus;
    },
    trucate(value, length = 15) {
      return value.length <= length
        ? value
        : value.substring(0, length) + "...";
    },
    gototask(task) {
      var self = this;
      this.$emit("opensubtask", "gototask");
      this.$nextTick(() => {
        self.$router.push({
          name: "single-task",
          params: {
            id: task.project,
            taskId: task.id,
          },
          query: {
            companyId: this.$route.query.companyId,
          },
        });
      });
    },
    changetasktype(item) {
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API + "task/update-tasktype/" + this.task.id,
          {
            type: item.id,
          }
        )
        .then((res) => {
          setTimeout(() => {
            bus.$emit("updatetasktype", res.data);
          }, 1000);
          self.$emit("updateattribute", {
            task: this.task,
            value: item.id,
            update: "type",
          });
          self.$store.commit("project/changesingletaskdata", {
            type: "type",
            task: self.task,
          });
        });
    },
    changePriority(item) {
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API + "task/change-priority/" + this.task.id,
          {
            priority: item.id,
          }
        )
        .then(() => {
          setTimeout(() => {
            bus.$emit("updatepriority", self.task);
          }, 1000);
          self.$emit("updateattribute", {
            task: self.task,
            value: item.id,
            update: "priority",
          });
          self.$store.commit("project/changesingletaskdata", {
            type: "priority",
            task: self.task,
          });
        });
    },
    changeStatus(item) {
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API + "task/change-status/" + this.task.id,
          {
            status: item.id,
          }
        )
        .then(() => {
          setTimeout(() => {
            bus.$emit("updatestatus", self.task);
          }, 1000);
          self.$emit("updateattribute", {
            task: self.task,
            value: item.id,
            update: "status",
          });
          self.$store.commit("project/changesingletaskdata", {
            type: "status",
            task: self.task,
          });
        });
    },
    getprojectdetail(task) {
      return this.projects.find((data) => data.id == task.project);
    },
    closemethod() {
      this.completetaskitem = "";
      this.completeconfirm = false;
    },
    updatetaskascompleted(item) {
      this.completetaskitem = item;
      var popupstatus = false;
      if (item.Iscompleted == false) {
        if (item.actionRequiredBy != "") {
          if (item.actionRequiredBy != this.authuser.id) {
            popupstatus = true;
          }
        }
      }
      if (popupstatus) {
        this.completeconfirm = true;
      } else {
        this.confirmupdate();
      }
    },
    confirmupdate() {
      var self = this;
      var updatestatus = false;
      if (this.subtask) {
        updatestatus = this.canEditTask == true ? true : false;
      } else {
        updatestatus = true;
      }
      if (updatestatus) {
        this.completetaskitem.Iscompleted = this.completetaskitem.Iscompleted
          ? false
          : true;
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API +
              "task/task-completed-status/" +
              this.completetaskitem.id,
            {
              status: this.completetaskitem.Iscompleted,
            }
          )
          .then((success) => {
            setTimeout(() => {
              bus.$emit("updatetaskascomplete", success.data);
            }, 1000);
            this.$emit("updateattribute", {
              task: this.task,
              value: this.completetaskitem.Iscompleted,
              update: "completed",
            });
            self.$store.commit("project/changesingletaskdata", {
              type: "Iscompleted",
              task: success.data,
            });
            self.completeconfirm = false;
            self.closemethod();
          });
      }
    },
    addPriorityColor(items) {
      var borderColor = items.projectinfo.priorities.find(
        (item) => item.id == items.priority
      );
      return {
        borderLeft: "4px solid " + borderColor.color,
      };
    },
  },
  components: {
    userinfo,
    confirmation,
  },
};
</script>
<style>
.myWork-infinite .today-date-task {
  display: flex !important;
}

.myWork-infinite .hide-data-todaymytask {
  display: none !important;
}
</style>
<style scoped>
.titletext {
  cursor: pointer;
}
.chip-class-wrap {
  white-space: break-spaces;
  height: auto !important;
  padding: 5px;
}
.hide {
  display: none;
}
.tooltip-width,
.width-of-chip {
  width: 50px;
}

.chip-text-width {
  max-width: 310px;
}

.width-chip {
  width: 100px;
}

.today-list-task span.v-chip {
  padding: 0 5px;
}

.tooltip-width-action {
  width: 100px;
}

@media (min-width: 600px) and (max-width: 991px) {
  .tooltip-width,
  .width-of-chip,
  .chip-text-width {
    width: 125px;
  }
}

@media (max-width: 599px) {
  .tooltip-width,
  .width-of-chip {
    width: 60px;
  }
  .width-chip {
    width: 50px;
  }
  .tooltip-width-action {
    width: 80px;
  }
  .chip-text-width {
    width: 80px;
    margin-right: 5px;
  }
}
</style>
