<template>
  <div>
    <header
      :class="{
        white: !$vuetify.theme.dark,
        '': $vuetify.theme.dark,
      }"
      class="ma-1 task-breadcrumb"
    >
      <v-breadcrumbs :items="breadCrumbs" class="pa-0">
        <template v-slot:divider>
          <v-icon size="20" class="pa-0">chevron_right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item>
            <div v-if="item.to !== undefined">
              <v-tooltip
                bottom
                :max-width="400"
                :disabled="item.text.length < 30 ? '' : disabled"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    :to="item.to"
                    v-bind="attrs"
                    v-on="on"
                    class="transparent breadCrumb-button px-0"
                  >
                    <div
                      :class="[
                        {
                          'primary--text': !$vuetify.theme.dark,
                          'black--text': $vuetify.theme.dark,
                        },
                        item.text.length < 30 ? '' : 'last-child-breadcrumb',
                      ]"
                      class="text-capitalize text-body-1"
                    >
                      {{ item.text }}
                    </div>
                  </v-btn>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </div>
            <div v-else class="last-child-breadcrumb">
              <v-tooltip
                bottom
                :max-width="400"
                :disabled="item.text.length < 30 ? '' : disabled"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="grey--text last-child-breadcrumb"
                  >
                    {{ item.text }}
                  </div>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </div>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </header>
    <div v-if="showmenu" class="task-group-section">
      <SingleProjectMenu />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import SingleProjectMenu from "./_SingleProjectMenu.vue";

Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new",
  },
  data: () => ({
    showmenu: true,
    dialog: false,
    loading: true,
  }),
  computed: {
    workspaceId() {
      return this.$store.getters["workspace/selectedWorkspaceId"];
    },
    workspaceName() {
      return this.$store.getters["workspace/selectedWorkspaceName"];
    },
    breadCrumbs() {
      return this.$store.getters.projectBreadCrumbs;
    },
  },
  watch: {},
  mounted() {
    if (this.$route.name == "single-task") {
      this.showmenu = false;
    }
  },
  methods: {},
  components: {
    SingleProjectMenu,
  },
};
</script>

<style type="text/css">
.theme--light .task-group-section {
  background-color: #f5f5f5;
}
.theme--dark .task-group-section {
  background-color: #272727;
}
.task-group-section {
  position: relative;
}
.breadCrumb-button.v-btn.v-btn--active,
.breadCrumb-button.v-btn.v-btn--active:hover,
.breadCrumb-button.v-btn.v-btn--active::before,
.breadCrumb-button.v-btn::before {
  background-color: transparent !important;
  background: transparent !important;
}
.last-child-breadcrumb {
  width: 235px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00000061;
}
@media (max-width: 959px) {
  .last-child-breadcrumb {
    width: 80px;
  }
}
</style>
