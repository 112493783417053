var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{staticClass:"header-app-bar primary",attrs:{"app":"","clipped-right":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.theme.dark),expression:"$vuetify.theme.dark"}],staticClass:"logo-header-dark",staticStyle:{"border":"0"},attrs:{"src":require("@/assets/dark-mode-logo.png"),"alt":"Focts Logo"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.theme.dark),expression:"!$vuetify.theme.dark"}],staticClass:"logo-header-light",staticStyle:{"border":"0"},attrs:{"src":require("@/assets/foctus-logo-whitebg-newbg.png"),"alt":"Focts Logo"}})]),_c('v-col',{staticClass:"d-flex align-center justify-end"},[_c('companychange',{attrs:{"place":"unsubscribeduser"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.user.Profileimage != '')?_c('v-avatar',_vm._g({staticClass:"assigned-user secondary",attrs:{"size":30}},on),[_c('v-img',{attrs:{"aspect-ratio":"1","src":_vm.user.Profileimage}})],1):_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:[_vm.switch1 == true ? 'active' : 'inactive']},[_vm._v("account_circle")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[(_vm.user.Profileimage != '')?_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.user.Profileimage,"aspect-ratio":"1"}})],1):_c('v-list-item-avatar',{staticClass:"text-uppercase",class:{
                    'primary white--text ': !_vm.$vuetify.theme.dark,
                    'primary black--text': _vm.$vuetify.theme.dark,
                  }},[_vm._v(" "+_vm._s(_vm.getShortName(_vm.user.firstName, _vm.user.lastName))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium text-capitalize"},[_vm._v(" "+_vm._s(_vm.firstletterupercase(_vm.user.firstName) + " " + _vm.firstletterupercase(_vm.user.lastName))+" ")]),_c('v-list-item-subtitle',[(
                        _vm.user.allcompanies.find(
                          (data) => data.id == _vm.$route.query.companyId
                        ).isAdmin == true ||
                        _vm.user.allcompanies.find(
                          (data) => data.id == _vm.$route.query.companyId
                        ).isManager == true
                      )?_c('span',[(
                          _vm.user.allcompanies.find(
                            (data) => data.id == _vm.$route.query.companyId
                          ).isAdmin
                        )?_c('p',[_vm._v(" Admin ")]):(
                          _vm.user.allcompanies.find(
                            (data) => data.id == _vm.$route.query.companyId
                          ).isManager
                        )?_c('p',[_vm._v(" Manager ")]):_vm._e()]):_c('span',[_c('p',[_vm._v("User")])])])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.fav ? 'red--text' : '',attrs:{"icon":""},on:{"click":function($event){_vm.fav = !_vm.fav}}},[_c('v-icon',[_vm._v("mdi-heart")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{on:{"click":_vm.logout}},[_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",class:{
                  'gradient-button white--text': !_vm.$vuetify.theme.dark,
                  'white black--text': _vm.$vuetify.theme.dark,
                },attrs:{"block":"","type":"submit","tile":"","color":"#9e9e9ed1"}},[_vm._v("log out ")])],1)],1)],1)],1)],1)],1),_c('v-main',[_c('v-container',[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }