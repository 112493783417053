<template>
  <v-app-bar fixed class="landing-page" elevation="0" height="100">
    <v-row class="align-center pt-4 pb-4 header-nav-bar">
      <v-col cols="12" class="align-center d-flex d-sm-flex d-md-none">
        <v-row class="align-center">
          <v-col cols="8">
            <a href="/" class="text-decoration-none">
              <img
                src="@/assets/logo-new-1-bg.png"
                alt="Focts Logo"
                width="150"
                style="border: 0"
                class="black-logo-header"
              />
              <img
                src="@/assets/foctus-logo-whitebg-newbg.png"
                alt="Focts Logo"
                width="150"
                style="border: 0"
                class="white-logo-header"
              /> </a
          ></v-col>
          <v-col cols="4" class="d-flex justify-end text-right">
            <div @click="toggleDrawer()">
              <v-icon large class="mobile-menu">menu</v-icon>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="2" sm="2" class="d-none d-sm-none d-md-flex">
        <a href="/" class="text-decoration-none">
          <!-- <h2 class="ma-0 text-subtitle font-weight-bold ">CRM</h2> -->
          <img
            src="@/assets/logo-new-1-bg.png"
            alt="Focts Logo"
            width="150"
            style="border: 0"
            class="black-logo-header"
          />
          <img
            src="@/assets/foctus-logo-whitebg-newbg.png"
            alt="Focts Logo"
            width="150"
            style="border: 0"
            class="white-logo-header"
          />
        </a>
      </v-col>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="pl-2 pr-2"
        style="height: 100vh !important; background-color: white"
      >
        <div
          class="text-left menu-without-scroll"
          :style="[
            windowWidth > 959
              ? { height: windowHeight + 'px' }
              : { height: windowMobileHeight + 'px' },
          ]"
        >
          <v-row>
            <v-col cols="12">
              <v-list class="static-menubar">
                <v-list-group
                  no-action
                  sub-group
                  class="pl-0 focts-header"
                  append-icon="$expand"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Product</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-list-item-icon>
                  </template>
                  <v-list-item
                    v-for="(item, index) in product"
                    :key="index"
                    :href="item.routerName"
                    class="pl-3 text-left"
                  >
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group
                  no-action
                  sub-group
                  append-icon="$expand"
                  class="static-menubar"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Features</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-list-item-icon>
                  </template>
                  <v-list-item
                    v-for="(item, index) in feature"
                    :key="index"
                    :href="item.routerName"
                    class="pl-3 text-left"
                  >
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      <a
                        href="/pricing"
                        class="text-decoration-none black--text"
                        >Pricing</a
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      <a href="/faq" class="text-decoration-none black--text"
                        >FAQ</a
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pa-0" v-if="user_login == null">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>
                      <v-btn
                        outlined
                        dense
                        class="pa-0 white text-uppercase login-button"
                        :to="{ name: 'login' }"
                        >Login</v-btn
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pa-0" v-if="user_login == null">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn
                        class="static-primary login-button"
                        :to="{ name: 'signup' }"
                        >Sign up</v-btn
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" v-if="user_login != null">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn
                        outlined
                        dense
                        class="mr-6 static-primary"
                        @click="dashboardRedirect()"
                        >Dashboard</v-btn
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-navigation-drawer>

      <v-col cols="8" class="d-none d-sm-none d-md-flex" md="7" sm="7">
        <v-row class="align-center">
          <v-col class="pa-0" cols="3">
            <template>
              <div class="text-center">
                <v-menu open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" class="top-menu">
                      Products
                      <v-icon>keyboard_arrow_down</v-icon>
                    </a>
                  </template>

                  <v-list class="static-menubar">
                    <v-list-item
                      v-for="(item, index) in product"
                      :key="index"
                      :href="item.routerName"
                    >
                      <v-list-item-content>{{
                        item.title
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-col>
          <v-col class="pa-0" cols="3">
            <template>
              <div class="text-center">
                <v-menu open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" class="top-menu">
                      Feature
                      <v-icon>keyboard_arrow_down</v-icon>
                    </a>
                  </template>

                  <v-list class="static-menubar">
                    <v-list-item
                      v-for="(item, index) in feature"
                      :key="index"
                      :href="item.routerName"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-col>
          <v-col class="pa-0" cols="3">
            <div class="text-center">
              <a href="/pricing" class="text-decoration-none top-menu"
                >Pricing</a
              >
            </div>
          </v-col>
          <v-col class="pa-0" cols="3">
            <div class="text-center">
              <a href="/faq" class="text-decoration-none top-menu">FAQ</a>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="4"
        sm="3"
        md="3"
        class="d-none d-sm-none d-md-flex justify-center pa-0"
        v-if="user_login == null"
      >
        <v-btn
          outlined
          dense
          class="mr-6 white header-button-text px-6"
          :to="{ name: 'login' }"
          >Login</v-btn
        >
        <v-btn
          class="sign-up-button header-button-text px-6"
          :to="{ name: 'signup' }"
          >Sign up</v-btn
        >
      </v-col>
      <v-col
        v-else
        cols="4"
        sm="3"
        md="3"
        class="d-none d-sm-none d-md-flex justify-center"
      >
        <v-btn
          outlined
          dense
          class="mr-6 static-primary"
          @click="dashboardRedirect()"
          >Dashboard</v-btn
        >
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    user_login: "",
    product: [
      { title: "Workspaces", routerName: "/workspaces" },
      { title: "Views", routerName: "/views" },
      { title: "Customization", routerName: "/customization" },
      { title: "Team Collaboration", routerName: "/team-collaboration" },
      { title: "User Interface", routerName: "/user-interface" },
    ],
    feature: [
      { title: "Project Management", routerName: "/project-management" },
      { title: "Customizing My Work", routerName: "/customizing-my-work" },
      { title: "Task Management", routerName: "/task-management" },
      { title: "Time Management", routerName: "/time-management" },
      { title: "Holiday Management", routerName: "/holiday-management" },
      { title: "Roles Management", routerName: "/roles-management" },
    ],
    windowHeight: window.innerHeight,
    windowMobileHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  }),

  mounted() {
    this.user_login = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    dashboardRedirect() {
      var authuser = localStorage.getItem("user");
      var companyid = "";
      if (authuser) {
        companyid = JSON.parse(localStorage.getItem("user")).companyId;
      }
      var routename =
        this.user_login && this.user_login.isAdmin == false
          ? "/mywork?name=from_landing&" + "companyId=" + companyid
          : "subscriptionflows";
      window.location.replace(routename);
    },
  },
};
</script>

<style>
.focts-header .v-list-group--sub-group .v-list-group__header {
  padding: 0;
}
.v-list.static-menubar .v-list-item--active {
  background-color: #2f71de !important;
  border-color: #2f71de !important;
}
.menu-lists .v-list-group__items {
  position: absolute;
  background-color: white;
}
.header-nav-bar
  .v-list-group__header.v-list-item.v-list-item--link.theme--light {
  padding: 0px;
  padding-left: 0px;
}

.header-nav-bar .v-list-group__header.v-list-item.v-list-item--link {
  padding: 0 !important;
}

.header-nav-bar .v-list-item--active.v-list-item.v-list-item--link {
  padding-left: 15px !important;
}

.header-nav-bar .v-list-item__icon.v-list-group__header__prepend-icon {
  display: none;
}

.login-button.v-btn {
  min-width: auto !important;
  font-size: 15px;
}
.menu-without-scroll {
  overflow-y: auto;
}
</style>
