<template>
  <div>
    <v-container fluid>
      <div class="d-flex justify-space-between align-center pa-2">
        <div class="horizontal-dividing-header">
          <h6
            class="header font-weight-medium"
            :class="{
              'primary--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            YOUR WORKSPACES
          </h6>
        </div>
        <v-btn
          v-if="
            user.allcompanies &&
            $route.query.companyId &&
            (user.allcompanies.find((data) => data.id == $route.query.companyId)
              .isManager ||
              user.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ).isAdmin)
          "
          x-small
          fab
          depressed
          class="primary"
          @click="
            showSnackBar = false;
            selectedUsers = {
              ids: [],
              type: [],
            };
            addWorkspaceDialog = true;
            workspacesavestatus = false;
          "
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col class="pt-0">
          <WorkspaceSideBar />
        </v-col>
      </v-row>
      <v-dialog
        v-model="addWorkspaceDialog"
        max-width="500"
        persistent
      >
        <v-card>
          <v-card-title>Create workspace</v-card-title>
          <v-card-text>
            <v-text-field
              label="Workspace Name"
              v-model.trim="workspaceName"
              name="workspaceName"
              v-validate="'required|max:255'"
              :error-messages="
                errors.collect('create-workspace.workspace name')
              "
              data-vv-name="workspace name"
              data-vv-as="workspace name"
              data-vv-scope="create-workspace"
            ></v-text-field>
            <div
              class="text-body-2 pb-3"
              v-if="users.length > 0 || companyinvitations.length > 0"
            >
              To know more about user types
              <v-icon
                class="mr-0 primary--text pa-0"
                medium
                @click="roleinfostatus = true"
                >info_outline</v-icon
              >
            </div>
            <template v-for="(user, index) in users">
              <v-list-item
                :key="user.id"
                class="pa-0 align-center"
              >
                <div
                  class="d-flex flex-column flex-sm-row flex-md-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"
                >
                  <v-row class="align-center">
                    <v-col
                      cols="12"
                      sm="7"
                      md="7"
                      class="pa-0"
                    >
                      <v-checkbox
                        v-model="selectedUsers.ids[index]"
                        :label="
                          firstletterupercase(user.firstName) +
                          ' ' +
                          firstletterupercase(user.lastName)
                        "
                        :value="user.id"
                        class="flex-grow-1"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      md="5"
                      class="pa-0"
                    >
                      <v-select
                        class="user-type pl-4"
                        label="User type"
                        outlined
                        v-model="selectedUsers.type[index]"
                        :items="selectItems"
                        item-text="name"
                        item-value="value"
                        :error-messages="
                          assignUserErrors[index] &&
                          (assignUserErrors[index].id == true ||
                            assignUserErrors[index].type == true)
                            ? ['Fill it']
                            : []
                        "
                        attach
                        dense
                        style="max-width: 200px"
                      ></v-select
                    ></v-col>
                  </v-row>
                </div>
              </v-list-item>
              <v-divider
                v-if="index + 1 < users.length"
                :key="index"
              >
              </v-divider>
            </template>

            <template v-if="companyinvitations.length > 0">
              <v-card-title class="pa-0 black--text"
                >Invited users</v-card-title
              >
              <template v-for="(invitation, index) in companyinvitations">
                <v-list-item
                  :key="invitation.emailAddress"
                  class="pa-0 align-center"
                >
                  <div
                    class="d-flex flex-column flex-sm-row flex-md-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"
                  >
                    <v-row class="align-center">
                      <v-col
                        cols="12"
                        sm="7"
                        md="7"
                        class="pa-0 word-break"
                      >
                        <v-checkbox
                          v-model="selectedEmails.emails[index]"
                          :label="invitation.emailAddress"
                          :value="invitation.emailAddress"
                          class="flex-grow-1 word-break"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="5"
                        md="5"
                        class="pa-0"
                      >
                        <v-select
                          class="user-type pl-4"
                          label="User type"
                          outlined
                          v-model="selectedEmails.type[index]"
                          :items="selectItems"
                          item-text="name"
                          item-value="value"
                          :error-messages="
                            assignEmailUserErrors[index] &&
                            (assignEmailUserErrors[index].id == true ||
                              assignEmailUserErrors[index].type == true)
                              ? ['Fill it']
                              : []
                          "
                          attach
                          dense
                          style="max-width: 200px"
                        ></v-select
                      ></v-col>
                    </v-row>
                  </div>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < companyinvitations.length"
                  :key="index"
                >
                </v-divider>
              </template>
            </template>
          </v-card-text>
          <v-card-actions class="add-task-sticky sticky-bar elevation-4">
            <div class="flex-grow-1"></div>
            <v-btn
              color="red"
              text
              :loading="workspacesavestatus"
              :disabled="workspacesavestatus"
              @click="canceladdworkspace"
              >Close</v-btn
            >
            <v-btn
              class="gradient-button"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
              tile
              contained
              text
              :loading="workspacesavestatus"
              :disabled="workspacesavestatus"
              @click="addWorkspace"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
        <div class="ma-4s">
          <v-snackbar
            v-model="showSnackBar"
            :bottom="true"
            :color="snackbarType"
            :min-width="245"
          >
            {{ snackMessage }}
          </v-snackbar>
        </div>
      </v-dialog>
    </v-container>
    <role
      :openinfo="roleinfostatus"
      @coseinfo="modelclosing"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { bus } from './../../../main';
import WorkspaceSideBar from './_workspace-sidebar';
import role from './role.vue';
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    meta: {
      required: false,
    },
  },
  data: () => ({
    roleinfostatus: false,
    workspaceName: '',
    btnLoading: false,
    showSnackBar: false,
    addWorkspaceDialog: false,
    snackbarType: 'error',
    snackMessage: '',
    // users: [],
    selectedUsers: {
      ids: [],
      type: [],
    },
    selectItems: [
      {
        name: 'Manager',
        value: 'manager',
      },
      {
        name: 'User',
        value: 'user',
      },
    ],
    selectedEmails: {
      emails: [],
      type: [],
    },
    assignEmailUserErrors: [],
    assignUserErrors: [],
    workspacesavestatus: false,
  }),
  created() {},
  mounted() {
    var self = this;
    // this.getcompanyworkspace();
    bus.$on('update-workspace-addusers', (userid) => {
      var userindex = this.users.findIndex((data) => data.id == userid);
      if (userindex == -1) {
        self.$store.dispatch('mywork/getUserTasks').then(() => {
          var getpushuser = self.$store.state.mywork.myusers.findIndex(
            (data) => data.id == userid
          );
          if (getpushuser != -1) {
            var pushuser = {
              Profileimage:
                self.$store.state.mywork.myusers[getpushuser].Profileimage,
              companies:
                self.$store.state.mywork.myusers[getpushuser].companies,
              createdAt:
                self.$store.state.mywork.myusers[getpushuser].createdAt,
              firstName:
                self.$store.state.mywork.myusers[getpushuser].firstName,
              id: self.$store.state.mywork.myusers[getpushuser].id,
              lastName: self.$store.state.mywork.myusers[getpushuser].lastName,
            };
            this.users.push(pushuser);
          }
        });
      } else {
        self.users.splice(userindex, 1);
      }
    });
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
    users() {
      return this.$store.state.account.companyusers;
    },
    companyinvitations() {
      return this.$store.state.account.companyinvitedusers
        ? this.$store.state.account.companyinvitedusers
        : [];
    },
  },
  methods: {
    modelclosing(data) {
      this.roleinfostatus = data;
    },
    firstletterupercase(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    },
    getcompanyworkspace() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + 'account/get-company-users', {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          this.users = response.data.companyUsers;
        });
    },
    createWorkspaceCallback(users, emails) {
      if (this.workspacesavestatus) {
        return;
      }
      this.workspacesavestatus = true;
      var self = this;
      this.$http
        .post(process.env.VUE_APP_ROOT_API + 'workspace/create-workspace', {
          name: this.workspaceName,
          users: users,
          emails: emails,
        })
        .then((response) => {
          this.addWorkspaceDialog = false;
          this.$store
            .dispatch('workspace/addWorkspace', response.data)
            .then(() => {
              self.$store.commit('workspace/assignselectedindex');
            });
          this.$store.dispatch('mywork/getUserTasks');
          this.btnLoading = false;
          this.snackMessage = 'Workspace has been successfully added';
          this.snackbarType = 'primary';
          this.showSnackBar = true;
          this.canceladdworkspace();
          this.workspacesavestatus = false;
        })
        .catch((error) => {
          error;
          this.workspacesavestatus = false;
        });
    },
    canceladdworkspace() {
      this.addWorkspaceDialog = false;
      this.workspaceName = '';
      this.snackMessage = '';
      this.btnLoading = false;
      this.assignUserErrors = [];
      this.selectedUsers.ids = [];
      this.selectedUsers.type = [];
      this.selectedEmails.type = [];
      this.selectedEmails.emails = [];
      this.assignEmailUserErrors = [];
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    addWorkspace() {
      var self = this;
      var finalSetOfUsers = [];
      self.assignUserErrors = [];
      var finalSetOfEmails = [];
      this.assignEmailUserErrors = [];
      self.$validator.validateAll('create-workspace').then((res) => {
        if (res) {
          if (
            self.selectedUsers.ids.filter((user) => user != null).length > 0
          ) {
            self.selectedUsers.ids.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.selectedUsers.type[index] !== undefined
              ) {
                finalSetOfUsers.push({
                  id: element,
                  type: self.selectedUsers.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.assignUserErrors[index] = {
                    id:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.selectedUsers.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }

          if (
            self.selectedEmails.emails.filter((user) => user != null).length > 0
          ) {
            self.selectedEmails.emails.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.selectedEmails.type[index] !== undefined
              ) {
                finalSetOfEmails.push({
                  email: element,
                  type: self.selectedEmails.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.assignEmailUserErrors[index] = {
                    email:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.selectedEmails.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }
          var savestatus = false;

          if (
            self.assignUserErrors.length == 0 &&
            finalSetOfUsers.length > 0 &&
            self.assignEmailUserErrors.length == 0 &&
            finalSetOfEmails.length > 0
          ) {
            savestatus = true;
          } else {
            if (
              (self.assignUserErrors.length == 0 && finalSetOfUsers.length) >
                0 ||
              (self.assignEmailUserErrors.length == 0 &&
                finalSetOfEmails.length > 0)
            ) {
              savestatus = true;
            } else {
              if (
                self.selectedUsers.ids.filter((user) => user != null).length >
                  0 ||
                self.selectedEmails.emails.filter((email) => email != null)
                  .length > 0
              ) {
                self.snackbarType = 'red';
                self.snackMessage =
                  'There are some input fields which are not filled';
                self.showSnackBar = true;
              } else {
                savestatus = true;
              }
            }
          }
          if (savestatus) {
            self.createWorkspaceCallback(finalSetOfUsers, finalSetOfEmails);
          }
        }
      });
    },
  },
  components: {
    WorkspaceSideBar,
    role,
  },
};
</script>

<style scoped>
.v-snack__content {
  margin: 0 1em;
}

.horizontal-dividing-header::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.horizontal-dividing-header::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.horizontal-dividing-header::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
</style>
