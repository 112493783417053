var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"ma-1 task-breadcrumb",class:{
      white: !_vm.$vuetify.theme.dark,
      '': _vm.$vuetify.theme.dark,
    }},[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadCrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{staticClass:"pa-0",attrs:{"size":"20"}},[_vm._v("chevron_right")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',[(item.to !== undefined)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":400,"disabled":item.text.length < 30 ? '' : _vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent breadCrumb-button px-0",attrs:{"text":"","to":item.to}},'v-btn',attrs,false),on),[_c('div',{staticClass:"text-capitalize text-body-1",class:[
                      {
                        'primary--text': !_vm.$vuetify.theme.dark,
                        'black--text': _vm.$vuetify.theme.dark,
                      },
                      item.text.length < 30 ? '' : 'last-child-breadcrumb',
                    ]},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])],1):_c('div',{staticClass:"last-child-breadcrumb"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":400,"disabled":item.text.length < 30 ? '' : _vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"grey--text last-child-breadcrumb"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])],1)])]}}])})],1),(_vm.showmenu)?_c('div',{staticClass:"task-group-section"},[_c('SingleProjectMenu')],1):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }