import axios from "axios";
const state = {
  companyUsers: [],
  usersChatrooms: [],
  chatmessages: [],
  deactivatedUsers: [],
  chatroomMessages: [],
  chatroomauthstatus: true,
};
const getters = {
  Authstatus: (state) => {
    return state.chatroomauthstatus;
  },
};
const mutations = {
  assignCompanyUsers(state, payload) {
    state.companyUsers = payload;
  },
  assignDeactivatedUsers(state, payload) {
    state.deactivatedUsers = payload;
  },
  usersChatrooms(state, payload) {
    state.usersChatrooms = payload;
  },
  chatroomMessages(state, payload) {
    state.chatroomMessages = payload;
  },
  addNewChatroom(state, newchatroom) {
    state.usersChatrooms.push(newchatroom);
  },
  removeGroupChatroom(state, chatroom) {
    var index = state.usersChatrooms.findIndex(
      (elem) => elem.id == chatroom.id
    );
    state.usersChatrooms.splice(index, 1);
  },
  updatedUsers(state, updatedusers) {
    state.companyUsers = updatedusers;
  },
  updatedchatrooms(state, updatedchatrooms) {
    state.usersChatrooms = updatedchatrooms;
  },
  updateSinglechatroomOnUser(state, userinfo) {
    var index = state.companyUsers.findIndex(
      (elem) => elem.id == userinfo.user.id
    );
    state.companyUsers[index].chatroominfo = userinfo.resChatroom;
  },
  assignAuthstaus(state, status) {
    state.chatroomauthstatus = status;
  },
};
const actions = {
  getCompanyUsers({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "chat/get-company-user", {
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        commit("assignCompanyUsers", response.data);
      });
  },
  getDeactivatedUsers({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "chat/get-deavtivatedcompany-users")
      .then((response) => {
        commit("assignDeactivatedUsers", response.data);
      });
  },
  getUsersGroupChatRoom({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "chat/get-users-chatroom", {
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        commit("usersChatrooms", response.data);
      });
  },
  authorizedChatStatus({ commit }, payload) {
    if (payload.chatroomid) {
      return axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "chat/is-auth-chatroom/" +
            payload.chatroomid,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          commit("assignAuthstaus", response.data.status);
        });
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
