var getparam = document.location.href
  .substring(document.location.href.indexOf("?") + 1)
  .split("&");
for (var i = 0, urlqueryparam = {}; i < getparam.length; i++) {
  getparam[i] = getparam[i].split("=");
  urlqueryparam[getparam[i][0]] = decodeURIComponent(getparam[i][1]);
}
import axios from "axios";
import store from "./../store";
const state = {
  activeUsers: [],
  invitations: [],
  inactiveUsers: [],
};
const getters = {};
const actions = {
  getActiveInactiveUsers({ commit }) {
    return axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "account/invitations/get-invited-active-users",
        {
          params: {
            timestamp: new Date().getTime(),
          },
        }
      )
      .then((response) => {
        commit("getActiveInactiveUsers", response.data);
      });
  },
  deleteActiveUser({ commit }, { activeUserIndex, activeUserId }) {
    return axios
      .delete(
        process.env.VUE_APP_ROOT_API +
          "account/delete-active-user-from-company",
        { data: { id: activeUserId } }
      )
      .then(() => {
        commit("deleteActiveUser", activeUserIndex);
      });
  },
  deleteInvitedUser({ commit }, invitedUserIndex) {
    return axios
      .delete(
        process.env.VUE_APP_ROOT_API +
          "account/invitations/delete-invited-user",
        { data: { invitedEmailIndex: invitedUserIndex } }
      )
      .then((res) => {
        commit("account/addtoinvitaiton", res.data.company);
        store.commit("account/removeinvitation", res.data.invitedEmails);
        commit("deleteInvitedUser", invitedUserIndex);
      });
  },
  updateInvitations({ commit }, invitations) {
    commit("updateInvitations", invitations);
  },
};
const mutations = {
  assignShifthours(state, user) {
    var activeindex = state.activeUsers.findIndex((data) => data.id == user.id);
    var inactiveindex = state.inactiveUsers.findIndex(
      (data) => data.id == user.id
    );
    if (activeindex != -1) {
      state.activeUsers[activeindex].companies = user.companies;
    }
    if (inactiveindex != -1) {
      state.inactiveUsers[inactiveindex].companies = user.companies;
    }
  },
  deleteInvitedUser(state, invitedUserIndex) {
    state.invitations.splice(invitedUserIndex, 1);
  },
  deleteActiveUser(state, activeUserIndex) {
    state.activeUsers.splice(activeUserIndex, 1);
  },
  updateInvitations(state, invitations) {
    invitations.forEach((element) => {
      state.invitations.unshift({ emailAddress: element.email });
    });
  },
  getActiveInactiveUsers(state, payload) {
    state.activeUsers = payload.activeUsers;
    state.invitations = payload.invitedUsers;
    state.inactiveUsers = payload.inactiveUsers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
