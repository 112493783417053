<template>
  <div>
    <v-list-item>
      <v-tooltip
        bottom
        v-if="user.isAdmin == false"
        :disabled="$route.name === 'allplans' ? true : false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar class="mr-1">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                large
                >store</v-icon
              ></v-btn
            >
          </v-list-item-avatar>

          <v-btn icon @click="opencompanypopup = true">
            <v-icon
              small
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
              >edit</v-icon
            >
          </v-btn>
          <v-list-item-title class="white--text py-2">
            <div
              v-if="place == 'unsubscribeduser' && isMobile"
              class="text-uppercase py-2 comapny-name-height"
            >
              {{
                companyName.length < 8
                  ? companyName
                  : companyName.substring(0, 8) + ".."
              }}
            </div>
            <v-tooltip
              v-else
              bottom
              :max-width="230"
              :disabled="
                $route.name === 'allplans' ||
                $route.name === 'trial-expired' ||
                companyName.length <= 17
                  ? true
                  : false
              "
            >
              <template v-slot:activator="{ on }">
                <div
                  class="text-uppercase py-2 comapny-name-height text-truncate"
                  v-on="on"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                >
                  {{ companyName }}
                </div>
              </template>
              <span class="text-capitalize">{{ companyName }}</span>
            </v-tooltip>
            <div
              class="d-flex align-center cursor-pointer"
              v-if="showstatus != 0"
              @click="callbackopenleaveusers"
            >
              <v-icon
                small
                class="pr-1"
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                >no_accounts</v-icon
              >
              <div
                class="text-decoration-underline"
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
              >
                Members Absent
              </div>
            </div>
          </v-list-item-title>
        </template>
        <span>Your Companies</span>
      </v-tooltip>
      <v-dialog
        v-model="opencompanypopup"
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-if="user.isAdmin == false">
          <v-card>
            <v-card-title class="px-6 mb-0">
              <div class="d-flex align-center flex-grow-1">
                <div class="d-flex flex-column">
                  <h6 class="font-weight-bold">All companies</h6>
                  <p class="mb-0 text-caption">
                    Companies to which you have been added
                  </p>
                </div>
                <div class="d-flex justify-end flex-grow-1">
                  <v-icon color="red" @click="opencompanypopup = false"
                    >close</v-icon
                  >
                </div>
              </div>
            </v-card-title>
            <v-card-text class="pa-4">
              <div
                v-for="(singlecompany, index) in user.allcompanies"
                :key="singlecompany.id"
              >
                <div
                  :class="[
                    singlecompany.id == $route.query.companyId ? 'primary' : '',
                  ]"
                  class="rounded-sm pa-2"
                >
                  <div class="d-flex align-center flex-grow-1">
                    <div @click="changecompany(singlecompany)">
                      <div
                        class="ma-0 text-body-1 font-weight-bold text-uppercase"
                        :class="[
                          singlecompany.id == $route.query.companyId &&
                          !$vuetify.theme.dark
                            ? 'white--text'
                            : 'black--text',
                        ]"
                      >
                        <span style="cursor: pointer">
                          {{ singlecompany.name }}
                        </span>
                      </div>
                      <span
                        class="text-body-2"
                        :class="[
                          singlecompany.id == $route.query.companyId &&
                          !$vuetify.theme.dark
                            ? 'white--text'
                            : 'black--text',
                        ]"
                        >{{ getrole(singlecompany) }}</span
                      >
                    </div>
                    <div
                      class="d-flex flex-grow-1 justify-end"
                      v-if="singlecompany.id == $route.query.companyId"
                    >
                      <v-icon
                        :class="[
                          singlecompany.id == $route.query.companyId &&
                          !$vuetify.theme.dark
                            ? 'white--text'
                            : 'black--text',
                        ]"
                        >done</v-icon
                      >
                    </div>
                  </div>
                </div>
                <v-divider
                  v-if="index < user.allcompanies.length - 1"
                  class="py-2"
                ></v-divider>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-list-item>
  </div>
</template>

<script>
import { bus } from "../../main";
import axios from "axios";

export default {
  props: {
    apsanticonshowstatus: Number,
    place: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    opencompanypopup: false,
    changeroutenames: [
      "singleworkspace",
      "single-workspace",
      "single-workspace.template",
      "single-task",
      "single-project",
      "single-project-tasks",
      "single-project-tasks.board",
      "single-project-tasks.calendar",
      "single-project-tasks.ganttChart",
      "single-project-tasks.table",
      "single-project-files",
      "project-users",
      "project-settings",
      "project-value",
      "timer.project_timer",
      "timer.project_report",
      "trial-expired",
      "singleuser-info",
      "profile.subscriptions",
      "profile.payments",
      "allplans",
      "cardDetail",
      "singleuser-info.useractivity",
    ],
    showstatus: 0,
  }),
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
    companyName() {
      const selectedCompany = this.user.allcompanies.find(
        (company) => company.id === this.$route.query.companyId
      );
      return selectedCompany.name;
    },
  },
  watch: {
    apsanticonshowstatus(data) {
      this.showstatus = data;
    },
  },
  methods: {
    callbackopenleaveusers() {
      this.$emit("openleaveuser", true);
    },
    getrole(company) {
      var returnrole = "";
      if (company.isAdmin || company.isManager) {
        if (company.isAdmin) {
          returnrole = "Admin";
        } else {
          returnrole = "Manager";
        }
      } else if (!company.isAdmin && !company.isManager) {
        returnrole = "User";
      }
      return returnrole;
    },
    changecompany(comp) {
      var self = this;
      if (comp.id != this.$route.query.companyId) {
        this.$http
          .post(process.env.VUE_APP_ROOT_API + "account/change-company", {
            companyId: comp.id,
          })
          .then((res) => {
            var user = JSON.parse(localStorage.getItem("user"));
            var findcompany = res.data.user.companies.find(
              (data) =>
                data.id === res.data.company && data.ActiveStatus === true
            );
            user.projectTaskviewHeadrs = res.data.user.projectTaskviewHeadrs;
            user.allcompanies = res.data.user.allcompanies;
            user.companyId = findcompany.id;
            user.isCompanyAdmin = findcompany.isAdmin;
            user.isCompanyManager = findcompany.isManager;
            self.$store.commit("account/commituser", { user });
            localStorage.setItem(
              "dontShowAgain",
              res.data.user.tooltipTimer === null ||
                res.data.user.tooltipTimer === undefined
                ? true
                : res.data.user.tooltipTimer
            );
            axios.defaults.headers.common["companyId"] = findcompany.id;

            if (this.changeroutenames.includes(this.$route.name)) {
              window.location = "/mywork?companyId=" + res.data.company;
            } else {
              if (
                this.$route.name == "invite-user" &&
                user.isCompanyAdmin == false &&
                user.isCompanyManager == false
              ) {
                window.location = "/mywork?companyId=" + res.data.company;
              } else {
                var url = document.location.href;
                var companyidsplitedarray = url.split("companyId");
                window.location =
                  companyidsplitedarray[0] + "companyId=" + res.data.company;
              }
            }
            var getauthcompany = res.data.user.allcompanies.find(
              (data) => data.id == findcompany.id
            );
            this.$store.commit("mywork/assignFilterValues", {
              filterworkspace: "",
              filterproject: "",
              filteruser:
                getauthcompany.isAdmin || getauthcompany.isManager
                  ? "all_users"
                  : "",
            });
            bus.$emit("updateuserinfo", user);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
