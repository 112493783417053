var getparam = document.location.href
  .substring(document.location.href.indexOf("?") + 1)
  .split("&");
for (var i = 0, urlqueryparam = {}; i < getparam.length; i++) {
  getparam[i] = getparam[i].split("=");
  urlqueryparam[getparam[i][0]] = decodeURIComponent(getparam[i][1]);
}
var comanyid = urlqueryparam.companyId;
if (!comanyid) {
  comanyid = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).companyId
    : "";
}
import axios from "axios";
// import moment from "moment";
const state = {
  userprojects: [],
  userworkspaces: [],
  myusers: [],
  filterworkspace: "",
  filterproject: "",
  filteruser: "",
  skip: 0,
  limit: 5,
  piechart: [0, 0, 0, 0],
  monthlyBarchart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  yearlyBarchart: [0, 0, 0, 0, 0, 0, 0, 0],
  filterdata: {
    workspace: "",
    project: "",
    user: "",
    companyid: comanyid,
  },
  load: false,
  today_tasks: [],
  inbox_tasks: [],
  week_tasks: [],
  companyusers: [],
};
const getters = {
  myworkfilters: (state) => {
    var myworkfilters = localStorage.getItem("myworkfilterdata");
    var authuser = localStorage.getItem("user");
    var currcompany = JSON.parse(authuser).allcompanies.find(
      (data) => data.id == comanyid
    );
    var storemyworkfilters = [];
    var pushnewitem = false;
    var getcompnyindex = -1;
    if (myworkfilters == null || myworkfilters == undefined) {
      pushnewitem = true;
    } else {
      storemyworkfilters = JSON.parse(localStorage.getItem("myworkfilterdata"));
      getcompnyindex = storemyworkfilters.findIndex(
        (data) => data.companyid == comanyid
      );
      if (getcompnyindex == -1) {
        pushnewitem = true;
      }
    }
    if (pushnewitem) {
      storemyworkfilters.push({
        companyid: comanyid,
        workspace: "",
        project: "",
        user:
          (currcompany.isAdmin == true || currcompany.isManager == true) &&
          state.filterdata.user
            ? state.filterdata.user
            : (currcompany.isAdmin == true || currcompany.isManager == true) &&
              !state.filterdata.user
            ? "all_users"
            : "",
      });
    }
    getcompnyindex = storemyworkfilters.findIndex(
      (data) => data.companyid == comanyid
    );
    if (getcompnyindex != -1) {
      state.filterdata = storemyworkfilters[getcompnyindex];
      state.filterworkspace = state.filterdata.workspace;
      state.filterproject = state.filterdata.project;
      state.filteruser = state.filterdata.user;
    }
    localStorage.setItem(
      "myworkfilterdata",
      JSON.stringify(storemyworkfilters)
    );
    return state.filterdata;
  },
};
const mutations = {
  assignPtojectsTasks(state, payload) {
    var myusers = [];
    var companyusers = [];
    var firstletterupercase = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    for (var singleuser of payload.users) {
      singleuser.name =
        firstletterupercase(singleuser.firstName) +
        " " +
        firstletterupercase(singleuser.lastName);
      singleuser.firstName = firstletterupercase(singleuser.firstName);
      singleuser.lastName = firstletterupercase(singleuser.lastName);
      myusers.push(singleuser);
    }
    for (var singlealuser of payload.allusers) {
      singlealuser.name =
        firstletterupercase(singlealuser.firstName) +
        " " +
        firstletterupercase(singlealuser.lastName);
      singlealuser.firstName = firstletterupercase(singlealuser.firstName);
      singlealuser.lastName = firstletterupercase(singlealuser.lastName);
      companyusers.push(singlealuser);
    }
    state.userprojects = payload.projects;
    state.userworkspaces = payload.workspaces;
    state.myusers = myusers;
    state.companyusers = companyusers;
  },
  assignloader(state, loadervalue) {
    state.load = loadervalue;
  },
  chartdtas(state, payload) {
    state.piechart = payload.piechartarray;
    state.monthlyBarchart = payload.yearlychartarray;
    state.yearlyBarchart = payload.monthlychartarray;
  },
  assignFilterValues(state, payload) {
    var authuser = localStorage.getItem("user");
    var myworkfilters = JSON.parse(localStorage.getItem("myworkfilterdata"));
    var getcompnyindex = myworkfilters.findIndex(
      (data) => data.companyid == comanyid
    );
    var currcompany = JSON.parse(authuser).allcompanies.find(
      (data) => data.id == comanyid
    );
    if (getcompnyindex != -1) {
      myworkfilters[getcompnyindex].workspace = payload.filterworkspace;
      myworkfilters[getcompnyindex].project = payload.filterproject;
      (myworkfilters[getcompnyindex].user =
        (currcompany.isAdmin == true || currcompany.isManager == true) &&
        payload.filteruser
          ? payload.filteruser
          : (currcompany.isAdmin == true || currcompany.isManager == true) &&
            !payload.filteruser
          ? "all_users"
          : ""),
        (state.filterworkspace = payload.filterworkspace);
      state.filterproject = payload.filterproject;
      (state.filteruser =
        (currcompany.isAdmin == true || currcompany.isManager == true) &&
        payload.filteruser
          ? payload.filteruser
          : (currcompany.isAdmin == true || currcompany.isManager == true) &&
            !payload.filteruser
          ? "all_users"
          : ""),
        (state.filterdata = myworkfilters[getcompnyindex]);
      localStorage.setItem("myworkfilterdata", JSON.stringify(myworkfilters));
    }
  },
  removefilteruser(state, payload) {
    var authuser = localStorage.getItem("user");
    var currcompany = JSON.parse(authuser).allcompanies.find(
      (data) => data.id == comanyid
    );
    var myworkfilters = JSON.parse(localStorage.getItem("myworkfilterdata"));
    for (var i = 0; i < myworkfilters.length; i++) {
      myworkfilters[i].user =
        payload.filteruser == myworkfilters[i].user
          ? ""
          : myworkfilters[i].user;
    }
    if (state.filteruser == payload.filteruser) {
      state.filteruser =
        currcompany.isAdmin == true || currcompany.isManager == true
          ? "all_users"
          : "";
    }
    if (state.filterdata.user == payload.filteruser) {
      state.filterdata.user =
        currcompany.isAdmin == true || currcompany.isManager == true
          ? "all_users"
          : "";
    }
    localStorage.setItem("myworkfilterdata", JSON.stringify(myworkfilters));
  },
  removefilterproject(state, payload) {
    var myworkfilters = JSON.parse(localStorage.getItem("myworkfilterdata"));
    for (var i = 0; i < myworkfilters.length; i++) {
      myworkfilters[i].project =
        payload.filterproject == myworkfilters[i].project
          ? ""
          : myworkfilters[i].project;
    }
    if (state.filterproject == payload.filterproject) {
      state.filterproject = "";
    }
    if (state.filterdata.project == payload.filterproject) {
      state.filterdata.project = "";
    }
    localStorage.setItem("myworkfilterdata", JSON.stringify(myworkfilters));
  },
  removefilterworkspace(state, payload) {
    var myworkfilters = JSON.parse(localStorage.getItem("myworkfilterdata"));
    for (var i = 0; i < myworkfilters.length; i++) {
      myworkfilters[i].workspace =
        payload.filterworkspace == myworkfilters[i].workspace
          ? ""
          : myworkfilters[i].workspace;
    }
    if (state.filterworkspace == payload.filterworkspace) {
      state.filterworkspace = "";
    }
    if (state.filterdata.workspace == payload.filterworkspace) {
      state.filterdata.workspace = "";
    }
    localStorage.setItem("myworkfilterdata", JSON.stringify(myworkfilters));
  },
  deleteWorkspace(state, workspaceId) {
    var workspaecIndex = state.userworkspaces.findIndex(
      (elem) => elem.id == workspaceId
    );
    if (workspaecIndex != -1) {
      state.userworkspaces.splice(workspaecIndex, 1);
    }
  },
  assignProjectUsers(state, payload) {
    var users = payload.projectusers;
    var projectId = payload.task.project;
    var firstindex = state.userprojects.findIndex(
      (elem) => elem.id == projectId
    );
    if (firstindex != -1) {
      state.userprojects[firstindex].assignedToUsers = users;
    }
  },
};
const actions = {
  getUserTasks({ commit }) {
    commit("assignloader", true);
    return axios
      .get(process.env.VUE_APP_ROOT_API + "task/get-user-based-works", {
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        commit("assignPtojectsTasks", response.data);
        commit("assignloader", false);
      });
  },
  getchartData({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "company/get-chartdata")
      .then((response) => {
        commit("chartdtas", response.data);
      });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
