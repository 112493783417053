var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list-item',[(_vm.user.isAdmin == false)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$route.name === 'allplans' ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-avatar',{staticClass:"mr-1"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
                'white--text': !_vm.$vuetify.theme.dark,
                'black--text': _vm.$vuetify.theme.dark,
              },attrs:{"large":""}},[_vm._v("store")])],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.opencompanypopup = true}}},[_c('v-icon',{class:{
              'white--text': !_vm.$vuetify.theme.dark,
              'black--text': _vm.$vuetify.theme.dark,
            },attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-list-item-title',{staticClass:"white--text py-2"},[(_vm.place == 'unsubscribeduser' && _vm.isMobile)?_c('div',{staticClass:"text-uppercase py-2 comapny-name-height"},[_vm._v(" "+_vm._s(_vm.companyName.length < 8 ? _vm.companyName : _vm.companyName.substring(0, 8) + "..")+" ")]):_c('v-tooltip',{attrs:{"bottom":"","max-width":230,"disabled":_vm.$route.name === 'allplans' ||
              _vm.$route.name === 'trial-expired' ||
              _vm.companyName.length <= 17
                ? true
                : false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"text-uppercase py-2 comapny-name-height text-truncate",class:{
                  'white--text': !_vm.$vuetify.theme.dark,
                  'black--text': _vm.$vuetify.theme.dark,
                }},on),[_vm._v(" "+_vm._s(_vm.companyName)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.companyName))])]),(_vm.showstatus != 0)?_c('div',{staticClass:"d-flex align-center cursor-pointer",on:{"click":_vm.callbackopenleaveusers}},[_c('v-icon',{staticClass:"pr-1",class:{
                'white--text': !_vm.$vuetify.theme.dark,
                'black--text': _vm.$vuetify.theme.dark,
              },attrs:{"small":""}},[_vm._v("no_accounts")]),_c('div',{staticClass:"text-decoration-underline",class:{
                'white--text': !_vm.$vuetify.theme.dark,
                'black--text': _vm.$vuetify.theme.dark,
              }},[_vm._v(" Members Absent ")])],1):_vm._e()],1)]}}],null,false,999250687)},[_c('span',[_vm._v("Your Companies")])]):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},model:{value:(_vm.opencompanypopup),callback:function ($$v) {_vm.opencompanypopup=$$v},expression:"opencompanypopup"}},[(_vm.user.isAdmin == false)?[_c('v-card',[_c('v-card-title',{staticClass:"px-6 mb-0"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("All companies")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(" Companies to which you have been added ")])]),_c('div',{staticClass:"d-flex justify-end flex-grow-1"},[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.opencompanypopup = false}}},[_vm._v("close")])],1)])]),_c('v-card-text',{staticClass:"pa-4"},_vm._l((_vm.user.allcompanies),function(singlecompany,index){return _c('div',{key:singlecompany.id},[_c('div',{staticClass:"rounded-sm pa-2",class:[
                  singlecompany.id == _vm.$route.query.companyId ? 'primary' : '',
                ]},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('div',{on:{"click":function($event){return _vm.changecompany(singlecompany)}}},[_c('div',{staticClass:"ma-0 text-body-1 font-weight-bold text-uppercase",class:[
                        singlecompany.id == _vm.$route.query.companyId &&
                        !_vm.$vuetify.theme.dark
                          ? 'white--text'
                          : 'black--text',
                      ]},[_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(singlecompany.name)+" ")])]),_c('span',{staticClass:"text-body-2",class:[
                        singlecompany.id == _vm.$route.query.companyId &&
                        !_vm.$vuetify.theme.dark
                          ? 'white--text'
                          : 'black--text',
                      ]},[_vm._v(_vm._s(_vm.getrole(singlecompany)))])]),(singlecompany.id == _vm.$route.query.companyId)?_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[_c('v-icon',{class:[
                        singlecompany.id == _vm.$route.query.companyId &&
                        !_vm.$vuetify.theme.dark
                          ? 'white--text'
                          : 'black--text',
                      ]},[_vm._v("done")])],1):_vm._e()])]),(index < _vm.user.allcompanies.length - 1)?_c('v-divider',{staticClass:"py-2"}):_vm._e()],1)}),0)],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }