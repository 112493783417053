<template>
  <div>
    <router-link
      v-if="clickable"
      :to="{
        name: 'singleuser-info',
        params: { userId: userId },
        query: {
          companyId: $route.query.companyId,
        },
      }"
      class="text-decoration-none subtitle-2 black--text inbox-title"
    >
      <div
        class="pl-0"
        v-if="
          $route.name == 'leave-control' ||
          $route.name == 'my-time' ||
          $route.name == 'my-time.my-time-report' ||
          $route.name == 'timer.project_timer' ||
          $route.name == 'timer.project_report'
        "
      >
        <div
          class="avatar primary list-avatar tooltip"
          :class="[
            {
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            },
            $route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
          ]"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                v-if="user.Profileimage != ''"
                :src="user.Profileimage"
                aspect-ratio="1"
                class="rounded-pill"
              >
              </v-img>
              <div v-bind="attrs" v-on="on" v-else class="text-uppercase">
                {{ getShortName(user.firstName, user.lastName) }}
              </div>
            </template>
            <span class="text-capitalize">{{
              user.firstName + " " + user.lastName
            }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-else class="pl-0">
        <div
          class="avatar primary list-avatar tooltip"
          :class="[
            {
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            },
            $route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
          ]"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                v-if="user.Profileimage != ''"
                :src="user.Profileimage"
                aspect-ratio="1"
                class="rounded-pill"
              >
              </v-img>
              <div v-bind="attrs" v-on="on" v-else class="text-uppercase">
                {{ getShortName(user.firstName, user.lastName) }}
              </div>
            </template>
            <span class="text-capitalize">{{
              user.firstName + " " + user.lastName
            }}</span>
          </v-tooltip>
        </div>
      </div>
    </router-link>
    <div v-else class="text-decoration-none subtitle-2 black--text inbox-title">
      <div
        class="pl-0"
        v-if="
          $route.name == 'leave-control' ||
          $route.name == 'my-time' ||
          $route.name == 'my-time.my-time-report' ||
          $route.name == 'timer.project_timer' ||
          $route.name == 'timer.project_report'
        "
      >
        <div
          class="avatar primary list-avatar tooltip"
          :class="[
            {
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            },
            $route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
          ]"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                v-if="user.Profileimage != ''"
                :src="user.Profileimage"
                aspect-ratio="1"
                class="rounded-pill"
              >
              </v-img>
              <div v-bind="attrs" v-on="on" v-else class="text-uppercase">
                {{ getShortName(user.firstName, user.lastName) }}
              </div>
            </template>
            <span class="text-capitalize">{{
              user.firstName + " " + user.lastName
            }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-else class="pl-0">
        <div
          class="avatar primary list-avatar tooltip"
          :class="[
            {
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            },
            $route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
          ]"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                v-if="user.Profileimage != ''"
                :src="user.Profileimage"
                aspect-ratio="1"
                class="rounded-pill"
              >
              </v-img>
              <div v-bind="attrs" v-on="on" v-else class="text-uppercase">
                {{ getShortName(user.firstName, user.lastName) }}
              </div>
            </template>
            <span class="text-capitalize">{{
              user.firstName + " " + user.lastName
            }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    user: {
      required: true,
      type: Object,
    },
    userId: {
      required: true,
      type: String,
    },
    clickable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    firstletterupercase(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    },
    getShortName(firstName, lastName) {
      return firstName.substr(0, 1) + "" + lastName.substr(0, 1);
    },
    gotosingleuser(user) {
      if (this.clickable) {
        this.$router.push({
          name: "singleuser-info",
          params: {
            userId: user,
          },
          query: {
            companyId: this.$route.query.companyId,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.single-task .v-avatar.mr-2 {
  margin-right: 0 !important;
}
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
</style>
<style>
td.pa-0.table-list-column.owner {
  position: relative;
}
</style>
