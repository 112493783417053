<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      borderRadius="0"
      class="quick-start-popup"
    >
      <div class="pop-up-start">
        <v-card class="quick-start-header">
          <v-toolbar flat>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              icons-and-text
              v-if="windowWidth > 767"
            >
              <v-tab v-for="item in items" :key="item.name" grow>
                {{ item.name }}
                <v-icon
                  regular
                  class="mr-2 rounded-circle pa-1"
                  :class="{
                    'primary white--text': !$vuetify.theme.dark,
                    'black white--text': $vuetify.theme.dark,
                  }"
                  >{{ item.icon }}</v-icon
                >
              </v-tab>
              <v-btn
                icon
                outlined
                small
                class="close-quick ma-1"
                @click="closeModal()"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-tabs>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              show-arrows
              v-if="windowWidth <= 767"
            >
              <v-tab v-for="item in items" :key="item.name">
                <v-icon
                  small
                  class="mr-2 rounded-circle pa-1"
                  :class="{
                    'primary white--text': !$vuetify.theme.dark,
                    'black white--text': $vuetify.theme.dark,
                  }"
                  >{{ item.icon }}</v-icon
                >
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="'Invite users'">
              <v-card flat class="card-quick-start">
                <v-row>
                  <v-col class="d-flex" cols="12" md="6">
                    <v-select
                      :items="usertype"
                      attach
                      item-text="name"
                      item-value="value"
                      data-vv-as="user type"
                      :error-messages="errors.collect('invite-users.user type')"
                      v-model="emailToBeInvited.usertype"
                      data-vv-scope="invite-users"
                      label="User Type"
                      prepend-icon="info_outline"
                      @click:prepend="roleinfostatus = true"
                      v-validate="'required'"
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" md="6">
                    <v-text-field
                      label="Email Address"
                      :error-messages="errors.collect('invite-users.email')"
                      data-vv-scope="invite-users"
                      data-vv-as="email"
                      v-model="emailToBeInvited.email"
                      name="email"
                      v-validate="'required|email|uniqueEmail|email_exists'"
                      :loading="emailAddLoading"
                      :disabled="addstatus"
                      @keydown.enter.prevent="addEmail"
                    >
                      <template v-slot:append-outer>
                        <v-btn icon :disabled="addstatus" @click="addEmail">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-list
                  class="mb-2 mt-2"
                  :class="{
                    white: !$vuetify.theme.dark,
                    blue: $vuetify.theme.dark,
                  }"
                  v-if="emailsToBeInvited.length > 0"
                >
                  <template v-for="(invite, index) in emailsToBeInvited">
                    <v-list-item :key="invite.email">
                      <v-list-item-title
                        class="invite-field-filled text-body-1 pr-2"
                      >
                        <v-text-field
                          v-model="invite.usertype"
                          readonly
                        ></v-text-field>
                      </v-list-item-title>
                      <v-list-item-title
                        class="invite-field-filled text-body-1 pl-2"
                      >
                        <v-text-field
                          v-model="invite.email"
                          readonly
                        ></v-text-field>
                      </v-list-item-title>
                      <v-list-item-icon link @click="removeEmail(index)">
                        <v-icon color="red">delete</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list>

                <v-btn
                  block
                  tile
                  class="primary text-uppercase"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  :loading="btnLoading"
                  @click="inviteUsers()"
                  :disabled="invitebuttondisabledstatus"
                  >Invite users</v-btn
                >
              </v-card>
            </v-tab-item>
            <v-tab-item :key="'Create workspace'">
              <v-card flat class="card-quick-start">
                <v-card-title class="px-0">Create workspace</v-card-title>
                <v-card-text class="px-0">
                  <v-text-field
                    label="Workspace Name"
                    v-model.trim="workspaceName"
                    name="workspaceName"
                    v-validate="'required|max:255'"
                    :error-messages="
                      errors.collect('create-workspace.workspace name')
                    "
                    data-vv-name="workspace name"
                    data-vv-as="workspace name"
                    data-vv-scope="create-workspace"
                  ></v-text-field>

                  <div
                    class="text-body-2 pb-3"
                    v-if="
                      companyusers.length > 0 || companyinvitedusers.length > 0
                    "
                  >
                    To know more about user types
                    <v-icon
                      class="mr-0 primary--text pa-0"
                      medium
                      @click="roleinfostatus = true"
                      >info_outline</v-icon
                    >
                  </div>

                  <template v-if="companyusers.length > 0">
                    <template v-for="(singleuser, index) in companyusers">
                      <v-list-item
                        :key="singleuser.id"
                        class="pa-0 align-center"
                      >
                        <div
                          class="d-flex flex-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"
                        >
                          <v-row
                            class="align-center checkbox-inside-input py-0"
                          >
                            <v-col
                              cols="12"
                              sm="7"
                              md="7"
                              class="d-flex align-center"
                            >
                              <v-checkbox
                                v-model="workspaceselectedUsers.ids[index]"
                                :label="
                                  firstletterupercase(singleuser.firstName) +
                                  ' ' +
                                  firstletterupercase(singleuser.lastName)
                                "
                                :value="singleuser.id"
                                class="flex-grow-1"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="5" md="5" class="px-0">
                              <v-select
                                class="user-type"
                                label="User type"
                                outlined
                                v-model="workspaceselectedUsers.type[index]"
                                :items="selectItems"
                                item-text="name"
                                item-value="value"
                                :error-messages="
                                  workspaceassignUserErrors[index] &&
                                  (workspaceassignUserErrors[index].id ==
                                    true ||
                                    workspaceassignUserErrors[index].type ==
                                      true)
                                    ? ['Fill it']
                                    : []
                                "
                                attach
                                dense
                              ></v-select
                            ></v-col>
                          </v-row>
                        </div>
                      </v-list-item>

                      <v-divider
                        v-if="index < companyusers.length"
                        :key="index"
                      >
                      </v-divider>
                    </template>
                  </template>
                  <template v-if="companyinvitedusers.length > 0">
                    <v-card-title class="pa-0 black--text pb-3"
                      >Invited users</v-card-title
                    >
                    <template
                      v-for="(
                        singleinvitation, inviteindex
                      ) in companyinvitedusers"
                    >
                      <v-list-item
                        :key="singleinvitation.emailAddress"
                        class="pa-0 align-center"
                      >
                        <div
                          class="d-flex flex-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"
                        >
                          <v-row
                            class="align-center checkbox-inside-input py-0"
                          >
                            <v-col
                              cols="12"
                              sm="7"
                              md="7"
                              class="d-flex align-center word-break"
                            >
                              <v-checkbox
                                v-model="
                                  workspaceselectedemails.emails[inviteindex]
                                "
                                :label="singleinvitation.emailAddress"
                                :value="singleinvitation.emailAddress"
                                class="flex-grow-1"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="5" md="5" class="pa-0">
                              <v-select
                                class="user-type"
                                label="User type"
                                outlined
                                v-model="
                                  workspaceselectedemails.type[inviteindex]
                                "
                                :items="selectItems"
                                item-text="name"
                                item-value="value"
                                :error-messages="
                                  workspaceassignInvitedUserErrors[
                                    inviteindex
                                  ] &&
                                  (workspaceassignInvitedUserErrors[inviteindex]
                                    .emailAddress == true ||
                                    workspaceassignInvitedUserErrors[
                                      inviteindex
                                    ].type == true)
                                    ? ['Fill it']
                                    : []
                                "
                                attach
                                dense
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </v-list-item>
                      <v-divider
                        v-if="inviteindex + 1 < companyinvitedusers.length"
                        :key="inviteindex"
                      >
                      </v-divider>
                    </template>
                  </template>
                </v-card-text>

                <v-card-actions
                  class="add-task-sticky sticky-bar elevation-0 mt-2"
                >
                  <div class="flex-grow-1"></div>
                  <v-btn
                    class="gradient-button"
                    :class="{
                      'white--text': !$vuetify.theme.dark,
                      'black--text': $vuetify.theme.dark,
                    }"
                    tile
                    contained
                    text
                    :loading="workspacesavestatus"
                    :disabled="workspacesavestatus"
                    @click="addWorkspace"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="'Create project'">
              <v-card flat class="card-quick-start">
                <v-card-title class="py-0">
                  <div class="horizontal-dividing-header mb-0">
                    <h5 class="header text-uppercase text-h6 mt-0">
                      Add Project
                    </h5>
                  </div>
                </v-card-title>
                <v-card-text class="pa-0 pa-md-2">
                  <v-row>
                    <v-col col="6">
                      <v-select
                        v-model="workspaceId"
                        :items="workspaces"
                        item-text="name"
                        item-value="id"
                        label="Workspace"
                        data-vv-name="workspace"
                        data-vv-as="workspace"
                        data-vv-scope="create-project"
                        :menu-props="{ closeOnContentClick: true }"
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item
                            :input-value="item.id == workspaceId"
                            @click="selectworkspace(item)"
                          >
                            <span class="text-body-2 pt-1 pb-1">{{
                              item.name
                            }}</span>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span class="text-body-2 pt-1 pb-1">{{
                            item.name
                          }}</span>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="Title*"
                        :error-messages="
                          errors.collect('create-project.project name')
                        "
                        data-vv-name="project name"
                        data-vv-scope="create-project"
                        v-model="projectName"
                        v-validate="'required|max:255'"
                        outlined
                        dense
                      ></v-text-field>
                      <span style="color: red">{{
                        errors.collect("create-project.project name")[0]
                      }}</span>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        label="Description"
                        data-vv-scope="create-project"
                        v-model="projectDescription"
                        data-vv-name="description"
                        data-vv-as="description"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-dialog
                        v-if="$vuetify.breakpoint.xsOnly"
                        ref="startdatemenu"
                        v-model="startdatemenu"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Start Date"
                            append-icon="event"
                            readonly
                            v-on="on"
                            :value="computedStartDate"
                            v-validate="'date_format:dd/MM/yyyy'"
                            :error-messages="
                              errors.collect('create-project.start date')
                            "
                            data-vv-name="start date"
                            data-vv-scope="create-project"
                            outlined
                            dense
                            class="dateinput"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" no-title>
                          <div class="flex-grow-1"></div>
                          <v-btn
                            text
                            @click="startdatemenu = false"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            @click="$refs.startdatemenu.save(startDate)"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                      <v-menu
                        v-else
                        ref="startdatemenu"
                        v-model="startdatemenu"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Start Date"
                            append-icon="event"
                            readonly
                            v-on="on"
                            :value="computedStartDate"
                            v-validate="'date_format:dd/MM/yyyy'"
                            :error-messages="
                              errors.collect('create-project.start date')
                            "
                            data-vv-name="start date"
                            data-vv-scope="create-project"
                            outlined
                            class="dateinput"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" no-title>
                          <div class="flex-grow-1"></div>
                          <v-btn
                            text
                            @click="startdatemenu = false"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            @click="$refs.startdatemenu.save(startDate)"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-dialog
                        v-if="$vuetify.breakpoint.xsOnly"
                        ref="enddatemenu"
                        v-model="enddatemenu"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="End Date"
                            append-icon="event"
                            readonly
                            v-on="on"
                            sages="errors.collect('create-project.end date')"
                            :value="computedEndDate"
                            :error-messages="
                              errors.collect('create-project.end date')
                            "
                            v-validate="'date_format:dd/MM/yyyy'"
                            data-vv-name="end date"
                            data-vv-scope="create-project"
                            outlined
                            class="dateinput"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          :min="minEndDate"
                          no-title
                          scrollable
                        >
                          <div class="flex-grow-1"></div>
                          <v-btn
                            text
                            @click="enddatemenu = false"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            @click="$refs.enddatemenu.save(endDate)"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                      <v-menu
                        v-else
                        ref="enddatemenu"
                        v-model="enddatemenu"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="End Date"
                            append-icon="event"
                            readonly
                            v-on="on"
                            sages="errors.collect('create-project.end date')"
                            :value="computedEndDate"
                            :error-messages="
                              errors.collect('create-project.end date')
                            "
                            v-validate="'date_format:dd/MM/yyyy'"
                            data-vv-name="end date"
                            data-vv-scope="create-project"
                            outlined
                            dense
                            class="dateinput"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          :min="minEndDate"
                          no-title
                          scrollable
                        >
                          <div class="flex-grow-1"></div>
                          <v-btn
                            text
                            @click="enddatemenu = false"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            @click="$refs.enddatemenu.save(endDate)"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col col="6">
                      <v-select
                        v-model="template"
                        :items="templates"
                        item-text="title"
                        :error-messages="
                          errors.collect('create-project.template')
                        "
                        v-validate="'required'"
                        item-value="id"
                        data-vv-name="template"
                        data-vv-as="template"
                        data-vv-scope="create-project"
                        label="Templates*"
                        hide-details
                        :menu-props="{ closeOnContentClick: true }"
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item
                            :input-value="item.name"
                            @click="changetemplate(item)"
                            class="chip-content d-flex flex-wrap"
                          >
                            <div>{{ item.title }}</div>
                            <br />
                            <div
                              v-for="status in item.statuses"
                              :key="status.id"
                            >
                              <v-chip
                                x-small
                                class="mb-0 template-names-wrap ml-2"
                                :class="{
                                  'grey white--text': !$vuetify.theme.dark,
                                  'black--text': $vuetify.theme.dark,
                                }"
                              >
                                <p class="mb-0 word-break pa-0">
                                  {{ status.name }}
                                </p>
                              </v-chip>
                            </div>
                          </v-list-item>
                        </template>
                      </v-select>

                      <v-list-item
                        v-if="
                          templates.length == 0 && workspaceId && showTemplate
                        "
                        class="pl-0 pt-2 pr-2"
                      >
                        <v-btn
                          outlined
                          small
                          color="primary"
                          class="px-3"
                          @click="gototemplate"
                        >
                          <v-icon
                            small
                            class="rounded-circle"
                            :class="{
                              'primary--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            >add</v-icon
                          >Add Template
                        </v-btn>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <div
                    class="text-body-2 pb-3"
                    v-if="
                      workspaceuser.length > 0 || assignedToEmails.length > 0
                    "
                  >
                    To know more about user types
                    <v-icon
                      class="mr-0 primary--text pa-0"
                      medium
                      @click="roleinfostatus = true"
                      >info_outline</v-icon
                    >
                  </div>
                  <v-card-text>
                    <v-list>
                      <template v-if="workspaceuser.length > 0">
                        <template v-for="(user, index) in workspaceuser">
                          <v-list-item
                            class="pa-0 flex-column flex-sm-row flex-md-row"
                            :key="user._id"
                          >
                            <v-list-item-content
                              class="pa-0 checkbox-new col-12 col-sm-7 col-md-7"
                            >
                              <v-checkbox
                                v-model="projectselectedUsers.ids[index]"
                                :label="
                                  firstletterupercase(user.firstName) +
                                  ' ' +
                                  firstletterupercase(user.lastName)
                                "
                                :value="user._id"
                              >
                              </v-checkbox>
                            </v-list-item-content>
                            <v-list-item-action
                              class="col-12 col-sm-5 col-md-5"
                            >
                              <v-select
                                class="user-type"
                                label="User type"
                                outlined
                                dense
                                attach
                                v-model="projectselectedUsers.type[index]"
                                :items="selectItems"
                                item-text="name"
                                item-value="value"
                                :error-messages="
                                  projectassignUserErrors[index] &&
                                  (projectassignUserErrors[index].id == true ||
                                    projectassignUserErrors[index].type == true)
                                    ? ['Fill it']
                                    : []
                                "
                                style="width: 200px"
                              ></v-select>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="index + 1 < workspaceuser.length"
                            :key="index"
                          >
                          </v-divider>
                        </template>
                      </template>
                      <template v-if="assignedToEmails.length > 0">
                        <v-card-title class="px-0 pb-3"
                          >Invited users</v-card-title
                        >
                        <template
                          v-for="(
                            singleinvitation, inviteindex
                          ) in assignedToEmails"
                        >
                          <v-list-item
                            :key="singleinvitation.email"
                            class="pa-0 align-center"
                          >
                            <div
                              class="d-flex flex-row align-start align-sm-center align-md-center flex-grow-1 pt-2 pb-2"
                            >
                              <v-row
                                class="align-center checkbox-inside-input py-0"
                              >
                                <v-col
                                  cols="12"
                                  sm="7"
                                  md="7"
                                  class="d-flex align-center word-break"
                                >
                                  <v-checkbox
                                    v-model="
                                      projectselectedemails.emails[inviteindex]
                                    "
                                    :label="singleinvitation.email"
                                    :value="singleinvitation.email"
                                    class="flex-grow-1"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="5" md="5">
                                  <v-select
                                    class="user-type"
                                    label="User type"
                                    outlined
                                    v-model="
                                      projectselectedemails.type[inviteindex]
                                    "
                                    :items="selectItems"
                                    item-text="name"
                                    item-value="value"
                                    :error-messages="
                                      projectassignInvitedUserErrors[
                                        inviteindex
                                      ] &&
                                      (projectassignInvitedUserErrors[
                                        inviteindex
                                      ].email == true ||
                                        projectassignInvitedUserErrors[
                                          inviteindex
                                        ].type == true)
                                        ? ['Fill it']
                                        : []
                                    "
                                    attach
                                    dense
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </div>
                          </v-list-item>
                          <v-divider
                            v-if="inviteindex + 1 < assignedToEmails.length"
                            :key="inviteindex"
                          >
                          </v-divider>
                        </template>
                      </template>
                    </v-list>
                  </v-card-text>

                  <div class="px-2 black--text text-body-2">
                    * Indicates required field
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="mt-2">
                  <div class="flex-grow-1"></div>

                  <v-btn
                    class="gradient-button"
                    :class="{
                      'white--text': !$vuetify.theme.dark,
                      'black--text': $vuetify.theme.dark,
                    }"
                    tile
                    contained
                    text
                    :loading="projectaddstatus"
                    :disabled="projectaddstatus"
                    @click="addProject"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <div
            v-if="windowWidth <= 767"
            class="mobile-pop-cancel elevation-2 white"
          >
            <v-btn
              block
              tile
              class="primary text-uppercase"
              @click="closeModal()"
              >Cancel</v-btn
            >
          </div>
          <div v-show="step === 2">Create workspace</div>
        </v-card>
      </div>
      <div class="ma-4s">
        <v-snackbar
          v-model="showSnackBar"
          :bottom="true"
          :color="snackbarType"
          :timeout="timeout"
          :min-width="245"
        >
          <div class="text-body-1 pb-2">{{ snackMessage }}</div>
          <div class="d-flex justify-start">
            <v-btn
              v-if="snackredirect"
              :class="{
                'white black--text': !$vuetify.theme.dark,
                'black white--text': $vuetify.theme.dark,
              }"
              @click="gotopage(snackredirect)"
              small
            >
              {{ snackSubMessage }}</v-btn
            >
          </div>
        </v-snackbar>
      </div>
    </v-dialog>
    <role :openinfo="roleinfostatus" @coseinfo="modelclosing" />
  </div>
</template>
<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
import moment from "moment";
import role from "@/views/pages/dashboard/role.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "create-shortcut",
  props: {
    dialogProp: Boolean,
  },
  data: () => ({
    roleinfostatus: false,
    snackSubMessage: "",
    timeout: 3000,
    snackredirect: "",
    moment: moment,
    tab: null,
    snackErrorSubscription: false,
    items: [
      { name: "Invite users", icon: "group" },
      { name: "Create workspace", icon: "workspaces" },
      { name: "Create project", icon: "task" },
    ],
    invitebuttondisabledstatus: false,
    dialog: false,
    step: 1,
    usertype: [
      {
        name: "User",
        value: "user",
      },
      {
        name: "Manager",
        value: "manager",
      },
    ],
    emailToBeInvited: {
      usertype: "",
      email: "",
    },
    addstatus: false,
    emailsToBeInvited: [],
    emailAddLoading: false,
    btnLoading: false,
    showSnackBar: false,
    snackbarType: "error",
    snackMessage: "",
    workspaceName: "",
    projectselectedUsers: {
      ids: [],
      type: [],
    },
    projectselectedemails: {
      emails: [],
      type: [],
    },
    selectItems: [
      {
        name: "Manager",
        value: "manager",
      },
      {
        name: "User",
        value: "user",
      },
    ],
    workspaceselectedUsers: {
      ids: [],
      type: [],
    },
    workspaceselectedemails: {
      emails: [],
      type: [],
    },
    workspaceassignUserErrors: [],
    workspaceassignInvitedUserErrors: [],
    projectassignUserErrors: [],
    projectassignInvitedUserErrors: [],
    companyusers: [],
    assignedToEmails: [],
    workspacesavestatus: false,
    template: "",
    projectName: "",
    projectDescription: "",
    startdatemenu: false,
    enddatemenu: false,
    workspaceId: "",
    loading: true,
    startDate: "",
    endDate: "",
    templates: [],
    minEndDate: "",
    projectaddstatus: false,
    workspaceuser: [],
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    showTemplate: false,
  }),
  created() {
    this.$validator.extend("uniqueEmail", {
      getMessage: (field) => "This " + field + " already exists.",
      validate: (value) => {
        var onlyemail = [];
        this.emailsToBeInvited.forEach((elem) => {
          onlyemail.push(elem.email);
        });
        return !onlyemail.includes(value);
      },
    });
    this.$validator.extend("email_exists", {
      getMessage: (field) => "The " + field + " is already in the system.",
      validate: (value) => {
        var postdata = {
          emailAddress: value,
        };
        return this.$http
          .get(
            process.env.VUE_APP_ROOT_API + "account/checklogin-email-exist",
            {
              params: postdata,
            }
          )
          .then((response) => {
            return {
              valid: response.data.status,
            };
          });
      },
    });
  },
  mounted() {
    var self = this;
    this.dialog = this.dialogProp;
    self.$nextTick(() => {
      if (self.authuser.isAdmin == false) {
        self.$store.dispatch("account/getcompanyandinvitedusers").then(() => {
          self.companyusers = self.$store.state.account.companyusers;
        });
      }
    });
  },
  computed: {
    computedStartDate() {
      return this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
    },
    computedEndDate() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
    authuser() {
      return JSON.parse(this.$store.state.account.user);
    },
    workspaces() {
      return this.$store.state.workspace.workspaces;
    },
    companyinvitedusers() {
      return this.$store.state.account.companyinvitedusers;
    },
  },
  watch: {
    dialogProp(data) {
      this.dialog = data;
    },
  },
  methods: {
    modelclosing(data) {
      this.roleinfostatus = data;
    },
    users() {
      return this.$store.state.workspace.workspaceUsers;
    },
    selectworkspace(item) {
      this.workspaceId = item.id;
      this.workspace = item.id;
      this.getTemplates(item);
    },
    getTemplates(item) {
      this.assignedToEmails = item.assignedToEmails;
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "template/get-templates/" + item.id)
        .then((res) => {
          this.template =
            res.data.template.length > 0 ? res.data.template[0].id : "";
          this.templates = res.data.template;
          this.workspaceuser = res.data.wsallusers;
          this.showTemplate = true;
        })
        .catch(() => {});
    },
    gototemplate() {
      var self = this;
      if (self.workspaceId) {
        self.$router.push({
          name: "single-workspace.template",
          params: {
            id: self.workspaceId,
          },
          query: {
            companyId: self.$route.query.companyId,
          },
        });
        this.closeModal();
      }
    },
    subscriptionpopup() {
      this.$router.push({
        name: "profile.subscriptions",
        query: {
          companyId: this.$route.query.companyId,
        },
      });
      this.dialog = false;
    },
    closeModal() {
      var self = this;
      self.dialog = false;
      this.showTemplate = false;
      this.templates = [];
      self.clearWorkspace();
      self.closeProjectDialog();
      self.emailsToBeInvited = [];
      self.emailToBeInvited = {};
      self.$emit("close-modal", false);
    },
    firstletterupercase(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    },
    gotopage(routeinfo) {
      if (routeinfo.name !== this.$route.name) {
        this.$router.replace(routeinfo);
      }
      this.closeModal();
    },
    addWorkspace() {
      var self = this;
      var finalSetOfUsers = [];
      var finalSetOfEmails = [];
      self.workspaceassignUserErrors = [];
      self.workspaceassignInvitedUserErrors = [];
      self.$validator.validateAll("create-workspace").then((res) => {
        if (res) {
          if (
            self.workspaceselectedUsers.ids.filter((user) => user != null)
              .length > 0
          ) {
            self.workspaceselectedUsers.ids.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.workspaceselectedUsers.type[index] !== undefined
              ) {
                finalSetOfUsers.push({
                  id: element,
                  type: self.workspaceselectedUsers.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.workspaceassignUserErrors[index] = {
                    id:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.workspaceselectedUsers.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }
          if (
            self.workspaceselectedemails.emails.filter((user) => user != null)
              .length > 0
          ) {
            self.workspaceselectedemails.emails.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.workspaceselectedemails.type[index] !== undefined
              ) {
                finalSetOfEmails.push({
                  email: element,
                  type: self.workspaceselectedemails.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.workspaceassignInvitedUserErrors[index] = {
                    emails:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.workspaceselectedemails.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }

          if (
            self.workspaceassignUserErrors.length == 0 &&
            finalSetOfUsers.length == 0 &&
            self.workspaceassignInvitedUserErrors.length == 0 &&
            finalSetOfEmails.length == 0
          ) {
            self.createWorkspaceCallback(finalSetOfUsers, finalSetOfEmails);
          } else {
            if (
              self.workspaceassignInvitedUserErrors.length > 0 ||
              self.workspaceassignUserErrors.length > 0
            ) {
              this.snackredirect = "";
              this.snackSubMessage = "";
              this.snackbarType = "error";
              self.snackMessage =
                "There are some input fields which are not filled";
              self.showSnackBar = true;
            } else {
              self.createWorkspaceCallback(finalSetOfUsers, finalSetOfEmails);
            }
          }
        }
      });
    },
    createWorkspaceCallback(users, emails) {
      if (this.workspacesavestatus) {
        return;
      }
      this.workspacesavestatus = true;
      var self = this;
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "workspace/create-workspace", {
          name: this.workspaceName,
          users: users,
          emails: emails,
        })
        .then((response) => {
          self.showSnackBar = true;
          self.snackMessage = "Workspace has been successfully added";
          self.snackbarType = "primary";
          self.snackSubMessage = "Go to Workspace";
          self.snackredirect = {
            name: "single-workspace",
            params: {
              id: response.data.id,
            },
            query: {
              companyId: self.$route.query.companyId,
            },
          };
          self.clearWorkspace();
          self.tab = 2;
          self.$store.dispatch("mywork/getUserTasks");
          self.$store
            .dispatch("workspace/addWorkspace", response.data)
            .then(() => {
              self.$store.commit("workspace/assignselectedindex");
            });
        })
        .catch((error) => {
          error;
          this.workspacesavestatus = false;
          this.showSnackBar = true;
          this.snackMessage = error.response.data.message;
          this.snackbarType = "error";
        });
    },
    clearWorkspace() {
      var self = this;
      self.workspaceName = "";
      self.workspacesavestatus = false;
      self.workspaceselectedUsers = {
        ids: [],
        type: [],
      };
      self.workspaceselectedemails = {
        emails: [],
        type: [],
      };
      self.workspaceassignUserErrors = [];
      self.workspaceassignInvitedUserErrors = [];
      self.$validator.reset();
    },
    addProject() {
      var self = this;
      var finalSetOfUsers = [];
      var finalSetOfEmails = [];
      self.projectassignUserErrors = [];
      self.projectassignInvitedUserErrors = [];
      self.$validator.validateAll("create-project").then((res) => {
        if (res) {
          if (
            self.projectselectedUsers.ids.filter((user) => user != null)
              .length > 0
          ) {
            self.projectselectedUsers.ids.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.projectselectedUsers.type[index] !== undefined
              ) {
                finalSetOfUsers.push({
                  id: element,
                  type: self.projectselectedUsers.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.projectfinalSetOfEmails[index] = {
                    id:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.projectselectedUsers.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }
          if (
            self.projectselectedemails.emails.filter((user) => user != null)
              .length > 0
          ) {
            self.projectselectedemails.emails.forEach((element, index) => {
              if (
                element !== undefined &&
                element &&
                self.projectselectedemails.type[index] !== undefined
              ) {
                finalSetOfEmails.push({
                  email: element,
                  type: self.projectselectedemails.type[index],
                });
              } else {
                if (
                  (element == undefined || element == null || !element) == false
                ) {
                  self.projectassignInvitedUserErrors[index] = {
                    emails:
                      element === undefined || element === null || !element
                        ? true
                        : false,
                    type:
                      self.projectselectedemails.type[index] === undefined
                        ? true
                        : false,
                  };
                }
              }
            });
          }
          if (
            self.projectassignUserErrors.length == 0 &&
            finalSetOfUsers.length == 0 &&
            self.projectassignInvitedUserErrors.length == 0 &&
            finalSetOfEmails.length == 0
          ) {
            self.addprojectcallback(finalSetOfUsers, finalSetOfEmails);
          } else {
            if (
              (self.projectassignUserErrors.length == 0 &&
                finalSetOfUsers.length > 0) ||
              (self.projectassignInvitedUserErrors.length == 0 &&
                finalSetOfEmails.length > 0)
            ) {
              self.addprojectcallback(finalSetOfUsers, finalSetOfEmails);
            } else {
              if (
                self.projectselectedUsers.ids.filter((user) => user != null)
                  .length > 0 ||
                self.projectselectedemails.emails.filter(
                  (email) => email != null
                ).length > 0
              ) {
                this.snackredirect = "";
                this.snackSubMessage = "";
                this.snackbarType = "error";
                self.snackMessage =
                  "There are some input fields which are not filled";
                self.showSnackBar = true;
              } else {
                self.addprojectcallback(finalSetOfUsers, finalSetOfEmails);
              }
            }
          }
        }
      });
    },
    changetemplate(item) {
      this.template = item.id;
    },
    addprojectcallback(users, emails) {
      if (this.projectaddstatus) {
        return;
      }
      this.projectaddstatus = true;
      var self = this;
      this.$validator.validateAll("create-project").then((res) => {
        if (res) {
          this.btnLoading = true;
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API +
                "project/create-project/" +
                self.workspaceId,
              {
                name: this.projectName,
                description: this.projectDescription,
                startDate: this.startDate
                  ? new Date(this.startDate).getTime()
                  : "",
                endDate: this.endDate ? new Date(this.endDate).getTime() : "",
                template: this.template,
                users: users,
                emails: emails,
              }
            )
            .then((response) => {
              this.snackredirect = {
                name: "single-project",
                params: {
                  id: response.data.id,
                },
                query: {
                  companyId: this.$route.query.companyId,
                },
              };
              this.snackbarType = "primary";
              this.snackSubMessage = "Go to project";
              this.showSnackBar = true;
              this.snackMessage = "Project has been successfully added";

              this.$nextTick(() => {
                this.btnLoading = false;
                this.closeProjectDialog();
                self.$store.commit(
                  "workspace/updateprojectcount",
                  response.data
                );
              });
              this.projectaddstatus = false;
            })
            .catch((err) => {
              this.snackredirect = "";
              this.snackSubMessage = "";
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                this.snackbarType = "error";
                this.snackMessage = err.response.data.message;
                this.showSnackBar = true;
              }
              this.btnLoading = false;
              this.projectaddstatus = false;
            });
        } else {
          this.projectaddstatus = false;
        }
      });
    },
    childerror(message) {
      this.snackbarType = "error";
      this.snackMessage = message;
      this.showSnackBar = true;
    },
    addEmail() {
      var self = this;
      self.addstatus = true;
      this.$validator.validateAll("invite-users").then((res) => {
        if (res) {
          this.emailAddLoading = true;
          if (
            self.emailToBeInvited &&
            self.emailToBeInvited.usertype != "" &&
            self.emailToBeInvited.email != ""
          ) {
            self.emailsToBeInvited.push(self.emailToBeInvited);
            self.emailToBeInvited = {};
            self.$validator.reset();
            self.emailAddLoading = false;
            self.addstatus = false;
          }
        } else {
          self.addstatus = false;
        }
      });
    },
    removeEmail: function (index) {
      this.emailsToBeInvited.splice(index, 1);
    },
    inviteUsers() {
      var self = this;
      if (this.emailsToBeInvited.length > 0) {
        this.invitebuttondisabledstatus = true;
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API + "account/invitations/invite-user",
            { useremailsandusertypeToBeInvited: self.emailsToBeInvited }
          )
          .then((response) => {
            self.$store.dispatch(
              "invitation/updateInvitations",
              response.data.invitations
            );
            self.$store.commit(
              "account/addtoinvitaiton",
              response.data.invitedEmails
            );
            self.emailsToBeInvited = [];
            this.$store
              .dispatch("invitation/getActiveInactiveUsers")
              .then(() => {
                this.loading = false;
              });
            self.snackbarType = "primary";
            self.snackMessage = "Invitations Sent";
            self.snackSubMessage = "Go to all users";
            self.snackredirect = {
              name: "invite-user",
              query: {
                companyId: this.$route.query.companyId,
              },
            };
            self.showSnackBar = true;
            self.invitebuttondisabledstatus = false;
            self.$validator.reset();
            self.tab = 1;
          })
          .catch((err) => {
            this.snackredirect = "";
            this.snackSubMessage = "";
            self.invitebuttondisabledstatus = false;
            if (err.response.data) {
              if (err.response.data.errorType === "maximum_user") {
                if (this.authuser.isCompanyAdmin) {
                  self.snackredirect = {
                    name: "profile.subscriptions",
                    query: {
                      companyId: this.$route.query.companyId,
                    },
                  };
                  self.snackSubMessage = "Click here";
                } else {
                  self.snackredirect = "";
                }
                self.showSnackBar = true;
                self.snackMessage = err.response.data.message;
                self.snackbarType = "error";
              } else {
                if (err.response.data.emailsAssociated) {
                  self.snackbarType = "error";
                  self.snackMessage =
                    err.response.data.emailsAlreadyAssociatedWithCompany.join() +
                    " is already associated with the company ";
                  self.showSnackBar = true;
                } else {
                  self.snackbarType = "error";
                  self.snackMessage = err.response.data.message;
                  self.showSnackBar = true;
                }
              }
            } else {
              this.snackbarType = "error";
              this.snackMessage =
                err.response.data.join() + " have already been invited";
              this.showSnackBar = true;
            }
          });
      } else {
        this.invitebuttondisabledstatus = false;
        this.snackredirect = "";
        this.snackSubMessage = "";
        this.snackbarType = "error";
        this.snackMessage = "You have not added any email addresses";
        this.showSnackBar = true;
      }
    },
    closeProjectDialog() {
      // this.dialog = false;
      this.$validator.reset();
      this.projectName = "";
      this.showTemplate = false;
      this.projectDescription = "";
      this.template = "";
      this.startDate = "";
      this.endDate = "";
      this.projectaddstatus = false;
      this.workspaceuser = [];
      this.assignedToEmails = [];
      this.projectassignUserErrors = [];
      this.projectassignInvitedUserErrors = [];
      this.workspaceId = "";
      this.projectselectedUsers = {
        ids: [],
        type: [],
      };
      this.projectselectedemails = {
        emails: [],
        type: [],
      };
    },
  },
  components: {
    role,
  },
};
</script>

<style>
.quick-start-header.v-sheet.v-card {
  border-radius: 0 !important;
  height: 100%;
}
.card-quick-start {
  width: 100%;
  max-width: 80%;
  margin: 2em auto;
  padding: 2em 0;
}
.pop-up-start {
  height: 100%;
}
.popup-task-dialog .v-dialog {
  top: 0 !important;
}
/* .pop-up-start .v-tabs-slider-wrapper {
  display: none;
} */
.pop-up-start .card-quick-start.v-card.v-card--flat {
  border: 1px solid #ccc;
  padding: 2em 2em 3em 2em;
}
.pop-up-start button.close-quick {
  position: relative;
  top: 15px;
  right: 0px;
}
.pop-up-start .v-input--selection-controls {
  margin-top: 0px;
}
.checkbox-inside-input .v-messages {
  display: none;
}

@media (max-width: 767px) {
  .card-quick-start {
    max-width: 90%;
  }
  .pop-up-start .card-quick-start.v-card.v-card--flat {
    padding: 1em;
    margin-bottom: 6em;
  }
  .mobile-pop-cancel {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px;
  }
}
</style>
