<template>
  <v-app>
    <component
      :is="layout || 'div'"
      :metasubscription="metasubscription"
    ></component>
  </v-app>
</template>

<script>
import Langindpage from "./views/layouts/Langindpage.vue";
import DashboardLayout from "./views/layouts/DashboardLayout.vue";
import AuthLayout from "./views/layouts/AuthLayout.vue";
import unsubscribed from "./views/layouts/UnsubscribeLayout.vue";
import $ from "jquery";
import { mapActions } from "vuex";
import { bus } from "./main";
import axios from "axios";
export default {
  data: () => ({
    isRefreshing: false,
    failedQueue: [],
    defaultlayout: {
      primary: "#2F71DE",
      secondary: "#548DEC",
      accent: "#2F71DE",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      blue: "#2F71DE",
      indigo: "#2F71DE",
      white: "#ffffff",
      black: "#000000",
      "gradient-button": "#2F71DE",
      "v-btn--active": "#2F71DE",
      "v-tab--active": "#2F71DE",
      "v-list-item--active": "#2F71DE",
    },
  }),
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    metasubscription() {
      return this.$route.meta.subscription;
    },
  },
  watch: {
    $route(to) {
      axios.defaults.headers.common["companyId"] = to.query.companyId;
      var authuser = localStorage.getItem("user");
      if (!authuser && to.name == "login") {
        this.assigndefaultcolor();
      }
      if (to.meta && to.meta.section == "landing") {
        this.assigndefaultcolor();
      } else {
        if (to.query && to.query.name == "from_landing") {
          this.assigndefaultcolor();
        }
      }
    },
  },
  created() {
    var self = this;
    var authuser = localStorage.getItem("user");
    if (authuser && authuser != "undefined") {
      self.$http.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          const originalRequest = error.config;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message == "accesstoken-expired" &&
            !originalRequest._retry
          ) {
            if (self.isRefreshing) {
              return new Promise(function (resolve, reject) {
                self.failedQueue.push({ resolve, reject });
              })
                .then((token) => {
                  originalRequest.headers["Authorization"] = "Bearer " + token;
                  originalRequest.headers["companyId"] =
                    self.$route.query.companyId;
                  return axios(originalRequest);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }
            originalRequest._retry = true;
            self.isRefreshing = true;
            return new Promise(function (resolve, reject) {
              axios
                .post(process.env.VUE_APP_ROOT_API + "entrance/refresh-token")
                .then(({ data }) => {
                  window.localStorage.setItem("token", data.token);
                  window.localStorage.setItem(
                    "refreshToken",
                    data.refreshToken
                  );
                  axios.defaults.headers.common["Authorization"] =
                    "Bearer " + data.token;
                  originalRequest.headers["Authorization"] =
                    "Bearer " + data.token;
                  axios.defaults.headers.common["companyId"] =
                    self.$route.query.companyId;
                  originalRequest.headers["companyId"] =
                    self.$route.query.companyId;
                  self.processQueue(null, data.token);
                  resolve(axios(originalRequest));
                })
                .catch((err) => {
                  self.processQueue(err, null);
                  reject(err);
                })
                .finally(() => {
                  self.isRefreshing = false;
                });
            });
          } else if (
            error.response.data &&
            error.response.data.message == "refreshtoken-expired"
          ) {
            self.$store.dispatch("account/logout").then(() => {
              self.$router.push({
                name: "home",
              });
              self.$router.go();
            });
          } else if (
            error.response.data &&
            error.response.data.message == "un subscribed users"
          ) {
            window.location.replace("/trial-expired");
          } else if (401 === error.response.status) {
            if (!this.$store.getters["account/authorized"]) {
              this.$store.dispatch("account/refreshtoken");
            } else {
              return Promise.reject(error);
            }
          } else {
            if (error.response.status == 404) {
              if (error.response.status == 404) {
                window.location.replace(
                  "/error?statuscode=404&message=" + error.response.data
                );
              }
            }
            if (
              error.response.data &&
              error.response.data.message == "invalid token"
            ) {
              return new Promise((resolve) => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                self.$store.dispatch("account/logout").then(() => {
                  delete this.$http.defaults.headers.common["Authorization"];
                  delete this.$http.defaults.headers.common["companyId"];
                  resolve();
                  window.location.replace(
                    "/login?to=" + window.location.pathname
                  );
                });
              });
            } else {
              return Promise.reject(error);
            }
          }
        }
      );
    }
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      self.assigndefaultcolor();
    });
    bus.$on("change-layout", () => {
      self.assignlayouts();
    });
    bus.$on("logout", () => {
      self.assignlayouts();
    });
  },
  methods: {
    ...mapActions("account", ["getCsrfToken"]),
    assigndefaultcolor() {
      var self = this;
      var isloggedIn = localStorage.getItem("user");
      if (this.$route.meta && this.$route.meta.section == "landing") {
        this.$vuetify.theme.themes.light = this.defaultlayout;
        this.$vuetify.theme.dark = false;
      } else {
        if (isloggedIn) {
          if (
            (this.$route.meta && this.$route.meta.section == "landing") == false
          ) {
            self.assignlayouts();
            this.$http
              .get(
                process.env.VUE_APP_ROOT_API + "subscription/get-user-layout",
                {
                  params: {
                    timestamp: new Date().getTime(),
                  },
                }
              )
              .then((response) => {
                var userlayout = JSON.parse(localStorage.getItem("user"));
                userlayout.layout = response.data.user.layout;
                userlayout.layoutDarkstatus =
                  response.data.user.layoutDarkstatus;
                userlayout.firstName = response.data.user.firstName;
                userlayout.lastName = response.data.user.lastName;
                userlayout.Profileimage = response.data.user.Profileimage;

                (userlayout.projectTaskviewHeadrs = response.data.user
                  .projectTaskviewHeadrs
                  ? response.data.user.projectTaskviewHeadrs
                  : []),
                  (userlayout.tooltipDescription =
                    response.data.user.tooltipDescription);
                userlayout.tooltipTimer = response.data.user.tooltipTimer;
                userlayout.allcompanies = response.data.user.allcompanies;
                userlayout.projectTaskviewHeadrs =
                  response.data.user.projectTaskviewHeadrs;
                userlayout.isCompanyAdmin =
                  response.data.user.isAdmin === false
                    ? response.data.user.allcompanies.find(
                        (data) => data.id == userlayout.companyId
                      ).isAdmin
                    : "";

                userlayout.isCompanyManager =
                  response.data.user.isAdmin === false
                    ? response.data.user.allcompanies.find(
                        (data) => data.id == userlayout.companyId
                      ).isManager
                    : "";
                userlayout.companyId =
                  response.data.user.isAdmin === false
                    ? response.data.user.companyId
                    : "";

                localStorage.setItem(
                  "tooltipInitial",
                  response.data.user.tooltipDescription
                    ? response.data.user.tooltipDescription
                    : true
                );
                localStorage.setItem(
                  "dontShowAgain",
                  response.data.user.tooltipTimer
                    ? response.data.user.tooltipTimer
                    : true
                );

                localStorage.setItem("user", JSON.stringify(userlayout));
                var user = JSON.parse(localStorage.getItem("user"));
                self.$store.commit("account/commituser", { user });
                self.assignlayouts();
              })
              .catch((error) => {
                error;
              });
          }
        } else {
          $(".brightness_icon").show();
          this.$vuetify.theme.themes.light = this.defaultlayout;
        }
      }
    },
    assignlayouts() {
      var self = this;
      var authuser = localStorage.getItem("user");
      if (authuser == "undefined") {
        return new Promise((resolve) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          self.$store.dispatch("account/logout").then(() => {
            delete this.$http.defaults.headers.common["Authorization"];
            delete this.$http.defaults.headers.common["companyId"];
            resolve();
            window.location.replace("/login?to=" + window.location.pathname);
          });
        });
      } else if (authuser) {
        var layout = JSON.parse(authuser);
        if (layout.layout.name == "Dark") {
          this.$vuetify.theme.dark = true;
          this.$vuetify.theme.themes.dark = layout.layout.dark[0];
          $(".brightness_icon").hide();
          localStorage.setItem("dark", this.$vuetify.theme.themes.dark);
        } else if (
          layout.layout.name != "Dark" &&
          layout.layoutDarkstatus == true
        ) {
          this.$vuetify.theme.dark = true;
          this.$vuetify.theme.themes.dark = layout.layout.dark[0];
          localStorage.setItem("dark", this.$vuetify.theme.themes.dark);
        } else {
          this.$vuetify.theme.themes.light = layout.layout.light[0];
          this.$vuetify.theme.dark = false;
          $(".brightness_icon").show();
          localStorage.setItem("light", this.$vuetify.theme.themes.light);
        }
      }
    },
  },
  components: {
    "dashboard-layout": DashboardLayout,
    "auth-layout": AuthLayout,
    "unsubscribe-layout": unsubscribed,
    landingpage: Langindpage,
  },
};
</script>

<style>
.v-input.dateinput .v-text-field__slot input {
  cursor: pointer;
}
</style>
