var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('div',[_c('v-text-field',{staticClass:"pb-3",attrs:{"outlined":"","dense":"","label":"Search Workspace","append-icon":"search"},model:{value:(_vm.searchWorkspsace),callback:function ($$v) {_vm.searchWorkspsace=$$v},expression:"searchWorkspsace"}}),(_vm.workspaces.length > 0)?_c('div',_vm._l((_vm.workspaces),function(workspace,index){return _c('div',{key:workspace.id},[_c('v-card',{staticClass:"mb-2",class:[
            _vm.selectedworkspace == index
              ? _vm.$vuetify.theme.dark
                ? 'primary lighten-2 black--text'
                : 'primary lighten-2 white--text'
              : '',
          ]},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"},on:{"click":function($event){return _vm.setlocalstore(workspace)}}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"text-decoration-none"},[_c('h6',{staticClass:"text-uppercase v-link font-weight-medium text-body-2 ma-0 workspace-name"},[_vm._v(" "+_vm._s(workspace.name)+" ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"pr-0 d-flex"},on),[_c('v-icon',{staticClass:"pr-1",class:[
                            _vm.selectedworkspace == index
                              ? _vm.$vuetify.theme.dark
                                ? 'black--text'
                                : 'white--text'
                              : 'primary--text',
                          ],attrs:{"small":""}},[_vm._v("work")]),_c('span',{staticClass:"v-middle"},[_vm._v(_vm._s(workspace.projectlength))])],1)]}}],null,true)},[_c('span',[_vm._v("Project")])])],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"pr-0 d-flex"},on),[_c('v-icon',{staticClass:"pr-1",class:[
                            _vm.selectedworkspace == index
                              ? _vm.$vuetify.theme.dark
                                ? 'black--text'
                                : 'white--text'
                              : 'primary--text',
                          ],attrs:{"small":""}},[_vm._v("group_work")]),_c('span',{staticClass:"v-middle"},[_vm._v(_vm._s(workspace.taskcount))])],1)]}}],null,true)},[_c('span',[_vm._v("Tasks")])])],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"pr-0 d-flex"},on),[_c('v-icon',{staticClass:"pr-0",class:[
                            _vm.selectedworkspace == index
                              ? _vm.$vuetify.theme.dark
                                ? 'black--text'
                                : 'white--text'
                              : 'primary--text',
                          ],attrs:{"small":""}},[_vm._v("assignment_ind")]),_c('span',{staticClass:"v-middle pr-1"},[_vm._v(" ("+_vm._s(workspace.workapcemanagers.length)+") ")])],1)]}}],null,true)},[_c('span',[_vm._v("Project Managers")])])],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"pr-0 d-flex"},on),[_c('v-icon',{staticClass:"pr-1",class:[
                            _vm.selectedworkspace == index
                              ? _vm.$vuetify.theme.dark
                                ? 'black--text'
                                : 'white--text'
                              : 'primary--text',
                          ],attrs:{"small":""}},[_vm._v("people_outline")]),_c('span',{staticClass:"v-middle"},[_vm._v(_vm._s(workspace.userscount))])],1)]}}],null,true)},[_c('span',[_vm._v("Users")])])],1)],1)],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"2"}},[_c('span',[(_vm.checkownerworkspace(workspace) == true)?_c('div',{staticClass:"delete-workspace-button",on:{"click":function($event){return _vm.deleteWorkspaceConfirm(workspace.id)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{class:[
                        _vm.selectedworkspace == index
                          ? _vm.$vuetify.theme.dark
                            ? 'black--text'
                            : 'white--text'
                          : 'primary--text',
                      ],attrs:{"small":""}},[_vm._v("delete")])],1)],1):_vm._e()])])],1),_c('v-divider')],1)],1)}),0):_c('div',[_c('h6',{staticClass:"text-uppercase text-center"},[_vm._v("No workspaces found")])])],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Confirmation")]),_c('v-card-text',{staticClass:"first-character"},[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{staticClass:"red--text",attrs:{"text":"","loading":_vm.deletebuttondisable,"disabled":_vm.deletebuttondisable},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"primary",class:{
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },attrs:{"outlined":"","tile":"","loading":_vm.deletebuttondisable,"disabled":_vm.deletebuttondisable},on:{"click":function($event){return _vm.deleteWorkspace()}}},[_vm._v("Yes")])],1)],1)],1),_c('v-snackbar',{attrs:{"bottom":true,"color":_vm.messagecolor},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(_vm._s(_vm.snackMessage))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }