<template>
  <div class="fill-height">
    <div>
      <v-text-field
        outlined
        dense
        v-model="searchWorkspsace"
        label="Search Workspace"
        append-icon="search"
        class="pb-3"
      >
      </v-text-field>
      <div v-if="workspaces.length > 0">
        <div v-for="(workspace, index) in workspaces" :key="workspace.id">
          <v-card
            class="mb-2"
            :class="[
              selectedworkspace == index
                ? $vuetify.theme.dark
                  ? 'primary lighten-2 black--text'
                  : 'primary lighten-2 white--text'
                : '',
            ]"
          >
            <v-row class="pa-2">
              <v-col class="pa-0" cols="10" @click="setlocalstore(workspace)">
                <v-row>
                  <v-col class="pa-0">
                    <div class="text-decoration-none">
                      <h6
                        class="text-uppercase v-link font-weight-medium text-body-2 ma-0 workspace-name"
                      >
                        {{ workspace.name }}
                      </h6>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="pl-0 pr-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div class="pr-0 d-flex" v-on="on">
                          <v-icon
                            class="pr-1"
                            small
                            :class="[
                              selectedworkspace == index
                                ? $vuetify.theme.dark
                                  ? 'black--text'
                                  : 'white--text'
                                : 'primary--text',
                            ]"
                            >work</v-icon
                          >
                          <span class="v-middle">{{
                            workspace.projectlength
                          }}</span>
                        </div>
                      </template>
                      <span>Project</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3" class="pl-0 pr-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div class="pr-0 d-flex" v-on="on">
                          <v-icon
                            class="pr-1"
                            small
                            :class="[
                              selectedworkspace == index
                                ? $vuetify.theme.dark
                                  ? 'black--text'
                                  : 'white--text'
                                : 'primary--text',
                            ]"
                            >group_work</v-icon
                          >
                          <span class="v-middle">{{
                            workspace.taskcount
                          }}</span>
                        </div>
                      </template>
                      <span>Tasks</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3" class="pl-0 pr-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="pr-0 d-flex">
                          <v-icon
                            class="pr-0"
                            small
                            :class="[
                              selectedworkspace == index
                                ? $vuetify.theme.dark
                                  ? 'black--text'
                                  : 'white--text'
                                : 'primary--text',
                            ]"
                            >assignment_ind</v-icon
                          >
                          <span class="v-middle pr-1">
                            ({{ workspace.workapcemanagers.length }})
                          </span>
                        </div>
                      </template>
                      <span>Project Managers</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3" class="pl-0 pr-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div class="pr-0 d-flex" v-on="on">
                          <v-icon
                            class="pr-1"
                            small
                            :class="[
                              selectedworkspace == index
                                ? $vuetify.theme.dark
                                  ? 'black--text'
                                  : 'white--text'
                                : 'primary--text',
                            ]"
                            >people_outline</v-icon
                          >
                          <span class="v-middle">{{
                            workspace.userscount
                          }}</span>
                        </div>
                      </template>
                      <span>Users</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="pl-0 pr-0">
                <span>
                  <div
                    v-if="checkownerworkspace(workspace) == true"
                    @click="deleteWorkspaceConfirm(workspace.id)"
                    class="delete-workspace-button"
                  >
                    <v-btn icon>
                      <v-icon
                        small
                        :class="[
                          selectedworkspace == index
                            ? $vuetify.theme.dark
                              ? 'black--text'
                              : 'white--text'
                            : 'primary--text',
                        ]"
                        >delete</v-icon
                      >
                    </v-btn>
                  </div>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </div>
      </div>
      <div v-else>
        <h6 class="text-uppercase text-center">No workspaces found</h6>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>Delete Confirmation</v-card-title>
        <v-card-text class="first-character">{{ dialogTitle }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            @click="dialog = false"
            :loading="deletebuttondisable"
            :disabled="deletebuttondisable"
            class="red--text"
            >Cancel</v-btn
          >
          <v-btn
            class="primary"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
            outlined
            tile
            :loading="deletebuttondisable"
            :disabled="deletebuttondisable"
            @click="deleteWorkspace()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :bottom="true" :color="messagecolor">{{
      snackMessage
    }}</v-snackbar>
  </div>
</template>

<script>
import { bus } from "../../../main";
/* eslint-disable vue/no-side-effects-in-computed-properties */
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    meta: {
      required: false,
    },
  },
  data: () => ({
    deletebuttondisable: false,
    showSnackBar: false,
    snackMessage: "",
    messagecolor: "red",
    searchWorkspsace: "",
    loading: true,
    searchLoading: false,
    dialog: false,
    dialogTitle: "",
    deleteWorkSpaceId: "",
  }),
  mounted() {
    var self = this;
    bus.$on("workspacesidebarevent", () => {
      self.searchWorkspsace = "";
    });
  },
  computed: {
    selectedworkspace: {
      get() {
        var self = this;
        var index = -1;
        if (this.searchWorkspsace) {
          var selectedworsace = localStorage.getItem("selectedWorkspaceId");
          var returnworksaces = this.$store.state.workspace.workspaces.filter(
            (item) =>
              item.name
                .toLowerCase()
                .includes(this.searchWorkspsace.toLowerCase())
          );
          index = returnworksaces.findIndex(
            (value) => value.id == selectedworsace
          );
        } else {
          if (
            self.$route.meta.section &&
            (self.$route.meta.section == "project" ||
              self.$route.meta.section == "workspace")
          ) {
            index = self.$store.getters["workspace/selectedWorkspaceIndex"];
          }
        }
        return index;
      },
      set(newValue) {
        this.$store.commit("workspace/assignindex", newValue);
      },
    },
    workspaces() {
      var returndata = this.$store.state.workspace.workspaces;
      if (this.searchWorkspsace) {
        this.searchLoading = true;
        returndata = returndata.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(this.searchWorkspsace.toLowerCase());
        });
        this.searchLoading = false;
        return returndata;
      } else {
        return returndata;
      }
    },
    authuser() {
      return JSON.parse(this.$store.state.account.user);
    },
    workspaceId() {
      return this.$store.getters["workspace/selectedWorkspaceId"];
    },
  },
  methods: {
    setlocalstore(ws) {
      var self = this;
      this.$nextTick(() => {
        localStorage.setItem("selectedWorkspaceId", ws.id);
        localStorage.setItem("selectedWorkspaceName", ws.name);
        self.$router.push({
          name: "single-workspace",
          params: {
            id: ws.id,
          },
          query: {
            companyId: self.$route.query.companyId,
          },
        });
      });
    },
    deleteWorkspaceConfirm(id) {
      bus.$emit("workspaceId", id);
      this.dialogTitle = "you want to delete this workspace?";
      this.dialog = true;
      this.deleteWorkSpaceId = id;
    },
    checkownerworkspace(workspace) {
      if (
        this.authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin ||
        this.authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager
      ) {
        if (
          this.authuser.allcompanies.find(
            (data) => data.id == this.$route.query.companyId
          ).isAdmin
        ) {
          return true;
        } else {
          if (
            workspace.owner == this.authuser.id &&
            workspace.workapcemanagers.includes(this.authuser.id)
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    deleteWorkspace() {
      if (this.deletebuttondisable) {
        return;
      }
      this.deletebuttondisable = true;
      var self = this;
      this.loading = true;
      this.$http
        .delete(
          process.env.VUE_APP_ROOT_API +
            "workspace/delete-workspace/" +
            this.deleteWorkSpaceId
        )
        .then((res) => {
          self.$store.commit(
            "workspace/deleteWorkspace",
            self.deleteWorkSpaceId
          );
          self.$nextTick(() => {
            self.$store.commit("workspace/assignselectedindex");
            var busdata = {
              tasks: res.data,
              workspaceid: self.deleteWorkSpaceId,
            };
            var worksapceid = localStorage.getItem("selectedWorkspaceId");
            if (worksapceid == self.deleteWorkSpaceId) {
              self.$router.push({
                name: "my-work.inbox",
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
            }
            bus.$emit("delete-workspace", busdata);
          });
          this.dialog = false;
          this.loading = false;
          this.deletebuttondisable = false;
          bus.$emit("getinboxnotification", res.data);
        })
        .catch((error) => {
          this.deletebuttondisable = false;
          if (error.response && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            var worksapceid = localStorage.getItem("selectedWorkspaceId");
            if (
              worksapceid == this.deleteWorkSpaceId &&
              this.$route.name != "my-work.inbox"
            ) {
              this.$router.push({
                name: "my-work.inbox",
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
            }
          }
          this.dialog = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: unset !important;
}

.workspace-link-active.v-list-item--active .v-icon,
.workspace-link-active.v-list-item--active .v-middle {
  color: #fff !important;
}

a.workspace-link-active.v-list-item--active.theme--dark {
  background-color: #333 !important;
}

.workspace-name {
  word-break: break-word;
}

.delete-workspace-button {
  z-index: 999;
}
</style>
