<template>
  <div>
    <Header />
    <router-view />
    <div class="grey lighten-3">
      <Footer />
    </div>
  </div>
</template>
<script>
import Header from "../pages/common/header.vue";
import Footer from "../pages/common/footer.vue";
export default {
  data: () => ({
    fav: true,
  }),
  mounted() {},
  computed: {},
  created() {
    this.$vuetify.theme.dark = false;
  },
  methods: {},
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
@import "./../../../src/assets/scss/landing-page.scss";
.slider-height {
  height: 400px;
}
@media (max-width: 991px) {
  .slider-height {
    height: auto;
  }
}
</style>
