import Vue from 'vue';
// import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import 'vuetify/dist/vuetify.min.css';
import 'tiptap-vuetify/dist/main.css';
import Vuetify from 'vuetify/lib';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: localStorage.getItem('light'),
      dark: localStorage.getItem('dark'),
    },
  },
});
Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md',
});
Vue.config.productionTip = false;

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#2F71DE',
        secondary: '#548DEC',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        white: '#ffffff',
        'gradient-button': '#2F71DE',
        'v-btn--active': '#2F71DE',
        'v-tab--active': '#2F71DE',
        'v-list-item--active': '#2F71DE',
      },
      dark: {
        background: '#000',
        primary: '#333',
        secondary: '#fff',
        white: '#333',
        black: '#fff',
        accent: '#fff',
        // error: '#fff',
        info: '#fff',
        success: '#000',
        warning: '#fff',
        indigo: '#000',
        'v-btn--active': '#000',
        'v-tab--active': '#000',
        'v-list-item--active': '#000',
        blue: '#333',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});