import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
export const bus = new Vue();
import store from "./store/store";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
var socketIOClient = require("socket.io-client");
var sailsIOClient = require("sails.io.js");
var io = sailsIOClient(socketIOClient);
var authuser = localStorage.getItem("user");
const token = localStorage.getItem("token");
io.sails.url = process.env.VUE_APP_SOCKET_URL;
var url = document.location.href;
var qs = url.substring(url.indexOf("?") + 1).split("&");
for (var i = 0, urlqueryparam = {}; i < qs.length; i++) {
  qs[i] = qs[i].split("=");
  urlqueryparam[qs[i][0]] = decodeURIComponent(qs[i][1]);
}
var comanyid = urlqueryparam.companyId;
if (!urlqueryparam.companyId) {
  comanyid = authuser ? JSON.parse(authuser).companyId : "";
}
io.sails.headers = {
  Authorization: "Bearer " + localStorage.getItem("token"),
  companyId: comanyid,
};
var isRefreshing = false;
var failedQueue = [];
if (authuser) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response.data &&
        error.response.data.message == "accesstoken-expired" &&
        !originalRequest._retry
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              originalRequest.headers["companyId"] = authuser
                ? JSON.parse(authuser).companyId
                : "";
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise(function (resolve, reject) {
          axios
            .post(process.env.VUE_APP_ROOT_API + "entrance/refresh-token")
            .then(({ data }) => {
              window.localStorage.setItem("token", data.token);
              window.localStorage.setItem("refreshToken", data.refreshToken);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.token;
              axios.defaults.headers.common["companyId"] = comanyid;
              originalRequest.headers["Authorization"] = "Bearer " + data.token;
              processQueue(null, data.token);
              resolve(axios(originalRequest));
              store.dispatch("account/setRefreshToken", data);
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      } else if (
        error.response.data &&
        error.response.data.message == "refreshtoken-expired"
      ) {
        return new Promise((resolve) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          store.dispatch("account/logout").then(() => {
            delete axios.defaults.headers.common["companyId"];
            delete axios.defaults.headers.common["Authorization"];
            resolve();
            window.location.replace("/login?to=" + window.location.pathname);
          });
        });
      } else if (
        error.response.data &&
        error.response.data.message == "un subscribed users"
      ) {
        window.location.replace("/trial-expired");
      }else if (
        error.response.data &&
        error.response.data.status ==403
      ) {
        window.location.replace(
          "/error?statuscode=403&message=" + error.response.data.message
        );
      } else {
        if (
          error.response.status == 404 ||
          (error.response.status == 400 && error.response.data.company_unauth)
        ) {
          if (error.response.status == 404) {
            window.location.replace(
              "/error?statuscode=404&message=" + error.response.data
            );
          } else {
            window.location.replace(
              "/error?statuscode=403&message=" + error.response.data.message
            );
          }
        }
        if (
          (error.response.data &&
            error.response.data.message == "invalid token") ||
          (error.response.data && error.response.status == 401)
        ) {
          var url =
            error.response.data.message == "invalid token"
              ? "/login?to=" + window.location.pathname
              : "/login";
          return new Promise((resolve) => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            store.dispatch("account/logout").then(() => {
              delete axios.defaults.headers.common["companyId"];
              delete axios.defaults.headers.common["Authorization"];
              resolve();
              window.location.replace(url);
            });
          });
        } else {
          return Promise.reject(error);
        }
      }
    }
  );
}
function processQueue(error, token = null) {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
}
Vue.prototype.$io = io;
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
axios.defaults.headers.common["companyId"] = comanyid;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
