<template>
  <div>
    <v-dialog v-model="roleinformationshow" persistent>
      <div class="pop-up-start">
        <v-card>
          <v-btn
            icon
            outlined
            small
            class="close-quick-alluser"
            @click="closepopup()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title> Role Informations </v-card-title>
          <v-card-text class="black--text text-body-1 pt-5 pb-3">
            <template>
              <v-data-table
                :headers="headers"
                :items="valuearray"
                :items-per-page="35"
                item-value="name"
                hide-default-header
                :mobile-breakpoint="0"
                :hide-default-footer="true"
              >
                <template #header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                    <tr>
                      <th v-for="head in headers" :key="head.text">
                        <span>{{ head.text }}</span>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.Features }}</td>

                    <td color="primary">
                      <v-icon :color="item.comp_admin.color">{{
                        item.comp_admin.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.comp_manager.color">{{
                        item.comp_manager.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.comp_user.color">{{
                        item.comp_user.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.ws_manager.color">{{
                        item.ws_manager.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.ws_user.color">{{
                        item.ws_user.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.p_manager.color">{{
                        item.p_manager.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-icon :color="item.p_user.color">{{
                        item.p_user.icon
                      }}</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-card-text>
          <div>
            <role></role>
          </div>
          <v-card-actions class="justify-center pt-0"> </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    openinfo: Boolean,
  },
  watch: {
    openinfo(data) {
      this.roleinformationshow = data;
    },
  },
  data: () => ({
    roleinformationshow: false,
    headers: [
      {
        text: "Features",
        value: "Features",
        align: "left",
        sortable: false,
      },
      {
        text: "Company admin",
        value: "comp_admin",
        sortable: false,
      },
      {
        text: "Company manager",
        value: "comp_manager",
        sortable: false,
      },
      {
        text: "Company user",
        value: "comp_user",
        sortable: false,
      },
      {
        text: "Workspace manager",
        value: "ws_manager",
        sortable: false,
      },
      {
        text: "Workspace user",
        value: "ws_user",
        sortable: false,
      },
      {
        text: "Project manager",
        value: "p_manager",
        sortable: false,
      },
      {
        text: "Project user",
        value: "p_user",
        sortable: false,
      },
    ],
    valuearray: [
      {
        id: 1,
        Features: "Create and Delete workspace",
        comp_admin: {
          icon: "check",
          color: "primary",
        },
        comp_manager: {
          icon: "check",
          color: "primary",
        },
        comp_user: {
          icon: "close",
          color: "red",
        },
        ws_manager: {
          icon: "close",
          color: "red",
        },
        ws_user: {
          icon: "close",
          color: "red",
        },
        p_manager: {
          icon: "close",
          color: "red",
        },
        p_user: {
          icon: "close",
          color: "red",
        },
      },
      {
        id: 2,
        Features: "Add and delete user in workspace",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 3,
        Features: "Edit workspace name",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 4,
        Features: "Promote workspace user to manager",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 5,
        Features: "Add, edit and delete template",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 6,
        Features: "Create and delete project",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 7,
        Features: "Edit project details",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 8,
        Features: "Project files page",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 9,
        Features: "Add and delete user in project user page",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 10,
        Features: "Promote project user to manager ",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 11,
        Features: "Add, edit and delete project settings",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 12,
        Features: "Add project value to the project",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 13,
        Features: "Update time in project timer",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 14,
        Features: "Create task",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 15,
        Features: "Delete task",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 16,
        Features:
          "Edit task title, description, create to-do list, delete to-do list, change priority, status, task type, update date, action required, time estimate, assigned user, complete task, labels, custom fields, edit comments, pinned the task, drag and drop the task",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 17,
        Features: "Start/end timer, update timer and edit/delete timer",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 18,
        Features: "Create subtask and convert to normal task",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 19,
        Features: "Shift task to other project",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 20,
        Features: "Invite user or manager",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 21,
        Features: "Deactivate company user from the company",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 22,
        Features: "Deactivate company manager from the company",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "close", color: "red" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 23,
        Features: "Activate user or manager from the company",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 24,
        Features: "Change shift time for company user",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 25,
        Features: "Change shift time for other manager in a company",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "close", color: "red" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 26,
        Features: "Promote company user to manager",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 27,
        Features:
          "Update and delete time in the my time for same user or manager",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 28,
        Features:
          "Update and delete time in the my time for other user or manager",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 29,
        Features: "Create and delete group chat and add user to group",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 30,
        Features: "Apply leave request",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },

      {
        id: 31,
        Features: "Approve/Disapprove and delete leave request",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },

      {
        id: 32,
        Features: "Apply leave for other user or manager in a company",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "close", color: "red" },
        ws_manager: { icon: "close", color: "red" },
        ws_user: { icon: "close", color: "red" },
        p_manager: { icon: "close", color: "red" },
        p_user: { icon: "close", color: "red" },
      },
      {
        id: 33,
        Features: "Feature modules",
        comp_admin: { icon: "check", color: "primary" },
        comp_manager: { icon: "check", color: "primary" },
        comp_user: { icon: "check", color: "primary" },
        ws_manager: { icon: "check", color: "primary" },
        ws_user: { icon: "check", color: "primary" },
        p_manager: { icon: "check", color: "primary" },
        p_user: { icon: "check", color: "primary" },
      },
    ],
  }),
  created() {},
  methods: {
    closepopup() {
      this.roleinformationshow = false;
      this.$emit("coseinfo", false);
    },
  },
};
</script>
<style>
.pop-up-start button.close-quick {
  position: relative;
  top: 15px;
  right: 0px;
}
.pop-up-start {
  height: 100%;
}
button.close-quick-alluser {
  position: absolute;
  right: 1em;
  top: 15px;
}
</style>
