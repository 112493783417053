var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Upload Files")]),_c('div',{staticClass:"post-comment my-3 py-3 post-comment-border"},[_c('div',{staticClass:"d-flex flex-column align-center justify-center pt-2"},[(_vm.previewFiles.length > 0)?[_c('h6',{staticClass:"flex-grow-1 pl-3",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.attachementFiles.length)+" "+_vm._s(_vm.previewFiles.length > 1 ? "files" : "file")+" added ")])]:_vm._e(),_c('v-row',{staticClass:"flex-grow-1",staticStyle:{"width":"100%"}},_vm._l((_vm.previewFiles),function(singlepreviewfile,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex justify-center align-center rounded-lg"},[(singlepreviewfile.type == 'image')?[_c('v-img',{staticClass:"comment-file-icon rounded-lg",attrs:{"src":singlepreviewfile.url,"aspect-ratio":"1"}})]:_vm._e(),(singlepreviewfile.type == 'file')?[_c('v-icon',{staticClass:"comment-file-icon"},[_vm._v("file_present")])]:_vm._e(),_c('v-btn',{staticClass:"delete-preview-button right top error",class:{
                'white--text': !_vm.$vuetify.theme.dark,
                'black--text': _vm.$vuetify.theme.dark,
              },attrs:{"absolute":"","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.removePreview(index)}}},[_c('v-icon',[_vm._v("delete_outline")])],1)],2)],1)}),1),(_vm.canEditTask)?_c('div',{staticClass:"d-flex align-center flex-column upload-fileSection"},[(_vm.attachementFiles.length <= 0)?_c('v-file-input',{directives:[{name:"validate",rawName:"v-validate",value:(
            'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.ppt.txt,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,video/3gpp2,.ppt,audio/ogg,.asf,application/vnd.ms-asf,  application/vnd.ms-powerpoint'
          ),expression:"\n            'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.ppt.txt,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,video/3gpp2,.ppt,audio/ogg,.asf,application/vnd.ms-asf,  application/vnd.ms-powerpoint'\n          "}],staticClass:"pt-0",attrs:{"data-vv-scope":"commentscope","accept":"application/msword,.asf,,.ppt, application/vnd.ms-asf,application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*,.xlsx,.doc,.docx,.ods,text/plain, application/vnd.ozpenxmlformats-officedocument.wordprocessingml.document, application/msword,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv","name":"commentfile","data-vv-as":"file","error-messages":_vm.errors.collect('commentscope.commentfile'),"multiple":"","small":"","title":"Add file","hide-input":true},on:{"change":_vm.attachFile},model:{value:(_vm.taskfiles),callback:function ($$v) {_vm.taskfiles=$$v},expression:"taskfiles"}}):_vm._e(),(_vm.attachementFiles.length <= 0)?_c('p',{staticClass:"text-caption font-weight-bold"},[_vm._v(" Select Files ")]):_vm._e()],1):_vm._e()],2),_c('div',{staticClass:"d-flex justify-end pr-2"},[(_vm.attachementFiles.length > 0)?_c('v-btn',{attrs:{"disabled":_vm.commentLoader,"card-props":{
          loading: _vm.commentLoader,
        },"color":"primary","small":""},on:{"click":_vm.postComment}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("upload")]),_vm._v(" Upload Files ")],1):_vm._e()],1)]),(_vm.task.attachments != null)?_c('div',{staticClass:"attachments"},[(_vm.task.attachments.length > 0)?_c('div',{staticClass:"d-flex flex-wrap justify-start"},[_c('h6',{staticClass:"flex-grow-1 pl-3",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.task.attachments.length)+" "+_vm._s(_vm.task.attachments.length > 1 ? "files" : "file")+" attached ")]),_vm._l((_vm.sortbycomment(_vm.task.attachments)),function(singleattachement){return _c('v-card',{key:singleattachement.id,staticClass:"d-flex justify-center align-center mx-2 pa-2 mt-4 rounded-lg elevation-2",attrs:{"width":130,"height":130,"outlined":""}},[(singleattachement.type.split('/')[0] == 'image')?[_c('a',{attrs:{"href":singleattachement.path,"target":"_blank"}},[_c('v-img',{staticClass:"rounded",attrs:{"lazy-src":require('src/assets/comment-image-placeholder.png'),"src":singleattachement.path,"aspect-ratio":"1","absolute":""}})],1)]:[_c('v-icon',{attrs:{"large":"","title":singleattachement.path}},[_vm._v("insert_link")])],(singleattachement.filename)?_c('div',{staticClass:"attachment-filename pa-1 text-caption text-truncate"},[_vm._v(" "+_vm._s(singleattachement.filename)+" ")]):_vm._e(),_c('v-btn',{staticClass:"delete-preview-button error",class:{
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },attrs:{"absolute":"","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.openRemoveFileDialog(singleattachement)}}},[_c('v-icon',[_vm._v("delete_outline")])],1),_c('v-btn',{attrs:{"icon":"","href":singleattachement.path,"target":"_blank","absolute":singleattachement.type.split('/')[0] !== 'image' ? false : true}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("launch")])],1)],2)})],2):_vm._e()]):_vm._e(),_c('confirmation',{key:"delete-comment-attachment",attrs:{"propdialog":_vm.deletetaskattachementDialog,"dialogTitle":"Are you sure you want to delete this attachment"},on:{"onConfirmed":_vm.removetaskfileFile,"onCancel":_vm.closeTaskDeleteFileDialog}}),_c('v-snackbar',{attrs:{"bottom":true,"color":_vm.messagecolor},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(_vm._s(_vm.snackMessage))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }