<template>
  <div>
    <div class="join-banner" v-if="$route.name !== 'home'">
      <div class="page-center">
        <v-row class="pa-5 align-center">
          <v-col cols="12" sm="8" md="8"
            ><p class="join-content mb-0 white--text">
              Try our Focts for free and enjoy the numerous benefits it brings
              to your organization.
            </p></v-col
          >
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="d-flex align-center justify-center"
          >
            <v-btn large class="primary rounded-xl px-8" href="/signup">
              Join Now
            </v-btn></v-col
          >
        </v-row>
      </div>
    </div>
    <div class="footer">
      <div class="page-center">
        <v-row class="footer-menu">
          <v-col cols="12" md="3" class="d-flex align-center">
            <a @click="scrollToTopHome()">
              <img
                src="@/assets/foctus-logo-whitebg-newbg.png"
                alt="Focts Logo"
                width="200"
                style="border: 0"
              />
            </a>
          </v-col>
          <v-col cols="12" md="2">
            <v-list-item href="/workspaces"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size transparent"
                text
                >Workspaces</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/views"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Views</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/customization"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Customization</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/team-collaboration"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Team Collaboration</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/user-interface"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >User Interface</v-list-item-content
              ></v-list-item
            >
          </v-col>
          <v-col cols="12" md="3">
            <v-list-item href="/project-management"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Project Management</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/customizing-my-work"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Customizing My Work</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/task-management"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Task Management</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/time-management"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Time Management</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/holiday-management"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Holiday Management</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/roles-management"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Roles Management</v-list-item-content
              ></v-list-item
            >
          </v-col>
          <v-col cols="12" md="2">
            <v-list-item href="/pricing"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Pricing</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/faq"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >FAQ</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/terms-conditions"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Terms and Conditions</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/return-policy"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Return policy</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/privacy-policy"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Privacy policy</v-list-item-content
              ></v-list-item
            >
          </v-col>
          <v-col cols="12" md="2">
            <v-list-item href="/contact-us"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Contact us</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/login"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Login</v-list-item-content
              ></v-list-item
            >
            <v-list-item href="/signup"
              ><v-list-item-content
                class="white--text text-capitalize font-weight-light footer-menu-text-size"
                text
                >Sign Up</v-list-item-content
              ></v-list-item
            >
          </v-col>
        </v-row>
        <p class="copyright-text text-center pt-5 mb-0 white--text">
          © {{ new Date().getFullYear() }} <b><i>focts.com</i></b> | All Rights
          Reserved.
        </p>
        <p class="copyright-text text-center pt-1 mb-0 white--text">
          Product of
          <a
            target="_blank"
            class="white--text"
            href="https://mindsetechnologies.com/"
            ><i>Mindset Software Technologies Pvt. Ltd.</i></a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    scrollToTopHome() {
      window.scrollTo(0, 0);
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
p.join-content {
  font-size: 15px;
  line-height: 1.6;
}
.join-banner {
  background-color: #333333;
}
</style>
