import axios from "axios";
import moment from "moment";
const state = {
  project: "",
  moment: moment,
  projectUsers: [],
  tasks: [],
  priorityItems: [],
  statuses: [],
  projectTags: [],
  labels: [],
  project_users: [],
  taskTypes: [],
  projectOwner: "",
  projectAssignedToUsers: [],
  projectData: "",
  caneditDeleteproject: "",
  selectedtaskids: [],
};
const getters = {
  project: (state) => state.projectData,
};
const actions = {
  async getProjectUsers({ commit }, projectId) {
    await axios
      .get(
        process.env.VUE_APP_ROOT_API + "project/get-project-users/" + projectId
      )
      .then((response) => {
        commit("assignProjectUsers", {
          users: response.data.projectusers,
        });
      })
      .catch(() => {});
  },
  async settingsDispatches({ commit }, projectId) {
    return await axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "project/get-project-settingsproperties/" +
          projectId
      )
      .then((response) => {
        commit("assignProjectLabels", response.data.lables);
        commit("assignPriorities", response.data.priorities);
        commit("assignStatuses", response.data.statuses);
        commit("assignTasktypes", response.data.typse);
      })
      .catch(() => {});
  },
  async getProjectLabels({ commit }, projectId) {
    return await axios
      .get(
        process.env.VUE_APP_ROOT_API + "project/get-project-labels/" + projectId
      )
      .then((response) => {
        commit("assignProjectLabels", response.data);
      })
      .catch(() => {});
  },
  async getPriorities({ commit }, projectId) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-priorities/" + projectId)
      .then((response) => {
        commit("assignPriorities", response.data);
      })
      .catch(() => {});
  },
  async Iscaneditproject({ commit }, projectId) {
    await axios
      .get(
        process.env.VUE_APP_ROOT_API + "project/is-caneditproject/" + projectId
      )
      .then((response) => {
        commit("assignIscaneditproject", response.data);
      })
      .catch(() => {});
  },
  async getTaskTypes({ commit }, projectId) {
    return await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-task-types/" + projectId)
      .then((response) => {
        commit("assignTasktypes", response.data);
      })
      .catch(() => {});
  },
  async getSingleproject({ commit }, projectId) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-project/" + projectId)
      .then((response) => {
        commit("assingProjectdata", response.data);
      })
      .catch(() => {});
  },
  async Singleproject({ commit }, projectId) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-project/" + projectId)
      .then((response) => {
        commit("assingProjectwithouttaskids", response.data);
      })
      .catch(() => {});
  },
  async getStatuses({ commit }, projectId) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-statuses/" + projectId)
      .then((response) => {
        commit("assignStatuses", response.data);
      })
      .catch(() => {});
  },
  async getTasks({ commit }, projectId) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "project/get-tasks/" + projectId, {
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((res) => {
        commit("assignTasks", res.data);
      })
      .catch(() => {
      });
  },
  addTask({ commit }, task) {
    commit("addTask", task);
  },
  deleteTask({ commit }, task) {
    commit("deleteTask", task);
  },
};
const mutations = {
  assignselectedtaskids(state, payload) {
    var checktaskindexpresented = state.selectedtaskids.findIndex(
      (data) => data == payload
    );
    if (checktaskindexpresented == -1) {
      state.selectedtaskids.push(payload);
    } else {
      state.selectedtaskids.splice(checktaskindexpresented, 1);
    }
  },
  removetaskidfromselectedids(state, payload) {
    var taskidindex = state.selectedtaskids.findIndex(
      (data) => data == payload.taskid
    );
    if (taskidindex != -1) {
      state.selectedtaskids.splice(taskidindex, 1);
    }
  },
  makeemptysectedtask(state, payload) {
    payload;
    state.selectedtaskids = [];
  },
  assignProjectLabels(state, payload) {
    state.projectTags = payload;
  },
  assingProjectdata(state, payload) {
    state.selectedtaskids = [];
    state.projectData = payload;
    var user = JSON.parse(localStorage.getItem("user"));
    user.projectTaskviewHeadrs = payload.projectTaskviewHeadrs;
    localStorage.setItem("user", JSON.stringify(user));
  },
  assingProjectwithouttaskids(state, payload) {
    state.projectData = payload;
    var user = JSON.parse(localStorage.getItem("user"));
    user.projectTaskviewHeadrs = payload.projectTaskviewHeadrs;
    localStorage.setItem("user", JSON.stringify(user));
  },
  assignProjectUsers(state, payload) {
    state.projectUsers = payload.users;
  },
  assignPriorities(state, payload) {
    state.priorityItems = payload;
  },
  assignIscaneditproject(state, payload) {
    state.caneditDeleteproject = payload;
  },
  assignTasktypes(state, payload) {
    state.projectOwner = payload.owner;
    state.taskTypes = payload.task_types;
    state.projectAssignedToUsers = payload.assignedToUsers;
  },
  assignStatuses(state, payload) {
    state.statuses = payload;
  },
  assignTasks(state, payload) {
    state.project = payload.project;
    state.tasks = payload.tasks;
  },
  assignprojectname(state, name) {
    state.project.name = name;
  },
  assignParentToNormalTasks(state, payload) {
    var index = state.tasks.findIndex((data) => data.id == payload.taskid);
    if (index != -1) {
      state.tasks[index].IssubtaskParent = false;
    }
  },
  addTask(state, task) {
    state.tasks.unshift(task);
  },
  changeStatusOfTask(state, payload) {
    var taskId = payload.taskId;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    // IF THE TASK IS DRAGGED WITHIN STATUS
    if (payload.changeOrderOnly) {
      if (payload.oldOrderIndex > payload.newOrderIndex) {
        state.tasks.forEach(function (task, key) {
          if (
            task.status == payload.oldStatus &&
            task.id != taskId &&
            task.IssubtaskChild == false
          ) {
            if (
              task.order >= payload.newOrderIndex &&
              task.order < payload.oldOrderIndex
            ) {
              state.tasks[key].order = state.tasks[key].order + 1;
            }
          }
        });
      }
      if (payload.oldOrderIndex < payload.newOrderIndex) {
        state.tasks.forEach(function (task, key) {
          if (
            task.status == payload.oldStatus &&
            task.id != taskId &&
            task.IssubtaskChild == false
          ) {
            if (
              task.order <= payload.newOrderIndex &&
              task.order > payload.oldOrderIndex
            ) {
              state.tasks[key].order = state.tasks[key].order - 1;
            }
          }
        });
      }
    } else {
      // IF THE TASK IS DRAGGED TO DIFFERENT STATUS
      state.tasks.forEach(function (task, key) {
        if (
          task.status == payload.oldStatus &&
          task.id != taskId &&
          task.IssubtaskChild == false
        ) {
          if (task.order > payload.oldOrderIndex) {
            state.tasks[key].order = state.tasks[key].order - 1;
          }
        }
      });
      state.tasks.forEach(function (task, key) {
        if (
          task.status == payload.status &&
          task.id != taskId &&
          task.IssubtaskChild == false
        ) {
          if (task.order >= payload.newOrderIndex) {
            state.tasks[key].order = state.tasks[key].order + 1;
          }
        }
      });
    }
    state.tasks[index].order = payload.newOrderIndex;
    state.tasks[index].status = payload.status;
  },
  subtaskOrderChange(state, payload) {
    var parentid = payload.parentTaskid;
    var taskid = payload.taskId;
    var curr_taskindex = state.tasks.findIndex((data) => data.id == parentid);
    var taskindex = state.tasks.findIndex((data) => data.id == taskid);
    if (curr_taskindex != -1) {
      if (state.tasks[curr_taskindex].subtaskIds.length > 0) {
        if (payload.fromparent == payload.toparent) {
          if (payload.oldOrderIndex > payload.newOrderIndex) {
            state.tasks[curr_taskindex].subtaskIds.forEach(function (task) {
              var subtaskindex = state.tasks.findIndex(
                (data) => data.id == task
              );
              if (subtaskindex != -1) {
                if (
                  state.tasks[subtaskindex].order >= payload.newOrderIndex &&
                  state.tasks[subtaskindex].order < payload.oldOrderIndex
                ) {
                  state.tasks[subtaskindex].order =
                    state.tasks[subtaskindex].order + 1;
                }
              }
            });
          }
          if (payload.oldOrderIndex < payload.newOrderIndex) {
            state.tasks[curr_taskindex].subtaskIds.forEach(function (task) {
              var currsubtaskindex = state.tasks.findIndex(
                (data) => data.id == task
              );
              if (currsubtaskindex != -1) {
                if (
                  state.tasks[currsubtaskindex].order <=
                    payload.newOrderIndex &&
                  state.tasks[currsubtaskindex].order > payload.oldOrderIndex
                ) {
                  state.tasks[currsubtaskindex].order =
                    state.tasks[currsubtaskindex].order - 1;
                }
              }
            });
          }
        } else {
          var fromparenttaskindex = state.tasks.findIndex(
            (data) => data.id == payload.fromparent
          );
          var toparenttaskindex = state.tasks.findIndex(
            (data) => data.id == payload.toparent
          );
          if (state.tasks[fromparenttaskindex].subtaskIds.length == 1) {
            state.tasks[fromparenttaskindex].IssubtaskParent = false;
            state.tasks[fromparenttaskindex].subtaskParentId = "";
          }
          if (fromparenttaskindex != -1 && toparenttaskindex != -1) {
            state.tasks[fromparenttaskindex].subtaskIds.forEach(function (
              task
            ) {
              var subtaskindex = state.tasks.findIndex(
                (data) => data.id == task && data.IssubtaskChild == true
              );
              if (subtaskindex != -1) {
                if (state.tasks[subtaskindex].order >= payload.oldOrderIndex) {
                  state.tasks[subtaskindex].order =
                    state.tasks[subtaskindex].order - 1;
                }
              }
            });
            state.tasks[toparenttaskindex].subtaskIds.forEach(function (task) {
              var subtaskindex = state.tasks.findIndex(
                (data) => data.id == task && data.IssubtaskChild == true
              );
              if (subtaskindex != -1) {
                if (state.tasks[subtaskindex].order >= payload.newOrderIndex) {
                  state.tasks[subtaskindex].order =
                    state.tasks[subtaskindex].order + 1;
                }
              }
            });
            state.tasks[toparenttaskindex].subtaskIds.push(payload.taskId);
            var fromtaskindex = state.tasks[
              fromparenttaskindex
            ].subtaskIds.findIndex((data) => data == payload.taskId);
            state.tasks[fromparenttaskindex].subtaskIds.splice(
              fromtaskindex,
              1
            );
            state.tasks[taskindex].subtaskParentId = payload.toparent;
          }
        }
        if (taskindex != -1) {
          state.tasks[taskindex].order = payload.newOrderIndex;
        }
      }
    }
  },
  subtaskparenttaskOrderChange(state, payload) {
    var taskid = payload.taskId;
    var fromtaskid = payload.fromparent;
    var totaskid = payload.toparent;
    var taskindex = state.tasks.findIndex((data) => data.id == taskid);
    if (fromtaskid) {
      var fromparenttaskindex = state.tasks.findIndex(
        (data) => data.id == fromtaskid
      );
    }
    if (totaskid) {
      var toparenttaskindex = state.tasks.findIndex(
        (data) => data.id == totaskid
      );
    }
    if (payload.normaltochild) {
      if (toparenttaskindex != -1) {
        state.tasks[taskindex].IssubtaskChild = true;
        state.tasks[taskindex].IssubtaskParent = false;
        state.tasks[taskindex].subtaskParentId =
          state.tasks[toparenttaskindex].id;
        state.tasks[toparenttaskindex].subtaskIds.push(taskid);
        state.tasks.forEach(function (task, key) {
          if (
            task.status == payload.oldStatus &&
            task.id != taskid &&
            task.IssubtaskChild == false
          ) {
            if (task.order >= payload.oldOrderIndex) {
              state.tasks[key].order = state.tasks[key].order - 1;
            }
          }
        });
        state.tasks[toparenttaskindex].subtaskIds.forEach(function (task) {
          var subtaskindex = state.tasks.findIndex(
            (data) => data.id == task && data.IssubtaskChild == true
          );
          if (subtaskindex != -1) {
            if (state.tasks[subtaskindex].order >= payload.newOrderIndex) {
              state.tasks[subtaskindex].order =
                state.tasks[subtaskindex].order + 1;
            }
          }
        });
      }
    }
    if (payload.childtonormal) {
      if (fromparenttaskindex != -1) {
        state.tasks.forEach(function (task, key) {
          if (
            task.status == payload.status &&
            task.id != taskid &&
            task.IssubtaskChild == false
          ) {
            if (task.order >= payload.newOrderIndex) {
              state.tasks[key].order = state.tasks[key].order + 1;
            }
          }
        });
        if (state.tasks[fromparenttaskindex].subtaskIds.length == 1) {
          state.tasks[fromparenttaskindex].IssubtaskChild = false;
          state.tasks[fromparenttaskindex].IssubtaskParent = false;
        }
        state.tasks[taskindex].subtaskParentId = "";
        state.tasks[taskindex].IssubtaskChild = false;
        var checktaskindex = state.tasks[
          fromparenttaskindex
        ].subtaskIds.findIndex((data) => data == taskid);
        state.tasks[fromparenttaskindex].subtaskIds.splice(checktaskindex, 1);
        state.tasks[fromparenttaskindex].subtaskIds.forEach(function (task) {
          var subtaskindex = state.tasks.findIndex(
            (data) => data.id == task && data.IssubtaskChild == true
          );
          if (subtaskindex != -1) {
            if (state.tasks[subtaskindex].order >= payload.oldOrderIndex) {
              state.tasks[subtaskindex].order =
                state.tasks[subtaskindex].order - 1;
            }
          }
        });
      }
    }
    state.tasks[taskindex].status = payload.status;
    state.tasks[taskindex].order = payload.newOrderIndex;
  },
  changeSubtasktoNormal(state, payload) {
    var parenttaskid = payload.pretask.subtaskParentId;
    var taskid = payload.currtask.id;
    var getparenttaskindex = state.tasks.findIndex(
      (elem) => elem.id == parenttaskid
    );
    var index = state.tasks.findIndex((elem) => elem.id == taskid);
    if (getparenttaskindex != -1) {
      var childidindex = state.tasks[getparenttaskindex].subtaskIds.findIndex(
        (elem) => elem == taskid
      );
      state.tasks[getparenttaskindex].subtaskIds.splice(childidindex, 1);
    }
    if (index != -1) {
      state.tasks[index].subtaskParentId = "";
      state.tasks[index].order = payload.currtask.order;
      state.tasks[index].IssubtaskParent = false;
      state.tasks[index].IssubtaskChild = false;
    }
  },
  changesingletaskdata(state, payload) {
    var index = state.tasks.findIndex((elem) => elem.id == payload.task.id);
    if (index != -1) {
      if (payload.type == "status") {
        state.tasks[index].status = payload.task.status;
      } else if (payload.type == "endDate") {
        state.tasks[index].endDate = payload.task.endDate
          ? payload.task.endDate
          : "";
        state.tasks[index].startDate = payload.task.startDate
          ? payload.task.startDate
          : "";
      } else if (payload.type == "priority") {
        state.tasks[index].priority = payload.task.priority;
      } else if (payload.type == "type") {
        state.tasks[index].type = payload.task.type;
      } else if (payload.type == "customFields") {
        state.tasks[index].customFields = payload.task.customFields;
      } else if (payload.type == "Iscompleted") {
        state.tasks[index].Iscompleted = payload.task.Iscompleted;
      }
    }
  },
  changeNormaltoSubtask(state, payload) {
    var index = state.tasks.findIndex((elem) => elem.id == payload.id);
    state.tasks[index].subtaskParentId = payload.subtaskParentId;
    state.tasks[index].IssubtaskParent = false;
    state.tasks[index].IssubtaskChild = true;
    state.tasks[index].subtaskIds = payload.subtaskIds;
    state.tasks[index].order = payload.order;
  },
  changeOnlyTheStatusOfTaskWithoutChangingOrder(state, payload) {
    var taskId = payload.taskId;
    var order = payload.success.order;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    if (payload.success.IssubtaskChild == false) {
      state.tasks[index].order = order;
    }
    state.tasks[index].status = payload.statusId;
  },
  changeOnlyMulyOrder(state, payload) {
    payload.data.forEach(function (data) {
      var index = state.tasks.findIndex((elem) => elem.id == data.id);
      state.tasks[index].order = data.order;
      state.tasks[index].status = data.status;
    });
  },
  deleteTask(state, task) {
    var index = state.tasks.findIndex((elem) => elem.id == task._id);
    state.tasks.splice(index, 1);
    if (task.subtaskIds.length > 0) {
      state.tasks.forEach(function (data) {
        var subindex = state.tasks.findIndex((elem) => elem.id == data);
        state.tasks.splice(subindex, 1);
      });
    }
  },
  addPriority(state, priority) {
    state.priorityItems.push(priority);
  },
  addTaskType(state, type) {
    state.taskTypes = type;
  },
  changeOrderOfTaskPriority(state, payload) {
    var taskId = payload.taskId;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    state.tasks[index].priority = payload.priorities;
  },
  changeOrderOfPriority(state, payload) {
    state.priorityItems = payload;
    state.project ? (state.project.priorityItems = payload) : "";
  },
  taskcustomupdation(state, payload) {
    var taskId = payload.taskId;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    var customindex = state.tasks[index].customFields.findIndex(
      (elem) => elem.id == payload.customdata
    );
    state.tasks[index].customFields[customindex].value =
      payload.customdropdata.name;
    state.tasks[index].customFields[customindex].attributeValueId =
      payload.customdropdata.id;
  },
  deletePriority(state, payload) {
    var priorityid = payload.id;
    var priority_id = state.priorityItems.findIndex(
      (data) => data.id == priorityid
    );
    state.priorityItems[priority_id].activeStatus = false;
  },
  deleteStatus(state, payload) {
    var statusid = payload.id;
    var status_id = state.statuses.findIndex((data) => data.id == statusid);
    if (statusid != -1) {
      state.statuses.splice(status_id, 1);
    }
  },
  deletetaskTypes(state, index) {
    var typeindex = state.taskTypes.findIndex((elem) => elem.id == index);
    state.taskTypes[typeindex].activeStatus = false;
  },
  addStatus(state, status) {
    state.statuses.push(status);
  },
  changeOrderOfStatus(state, statuses) {
    state.statuses = statuses;
    state.project.statuses = statuses;
  },
  addProjectLabels(state, labels) {
    state.projectTags = labels;
    state.project.lables = labels;
  },
  updateProjectLabels(state, payload) {
    var tagIndex = state.projectTags.findIndex(
      (data) => data.id == payload.label.id
    );
    if (tagIndex != -1) {
      state.projectTags[tagIndex].name == payload.label.name;
    }
  },
  assignActionreqiredto(state, payload) {
    var taskId = payload.id;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    state.tasks[index].actionRequiredBy = payload.actionRequiredBy;
    var userindex = state.projectUsers.findIndex(
      (elem) => elem.id == payload.actionRequiredBy
    );
    if (userindex != -1) {
      state.tasks[index].actionRequiredByname =
        state.projectUsers[userindex].firstName;
    } else {
      state.tasks[index].actionRequiredByname = "";
    }
  },
  assignTaskLable(state, payload) {
    var taskId = payload.id;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    state.tasks[index].labels = payload.labels;
  },
  assignemail(state, payload) {
    state.projectData.assignedToEmails = payload;
  },
  Assignactionrequiredtousers(state, payload) {
    var taskId = payload.id;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    state.tasks[index].Taskusers = payload.assignedToUsers;
    state.tasks[index].assignedToUsers = payload.assignedToUsers;
  },
  changetasktype(state, payload) {
    var taskId = payload.taskId;
    var index = state.tasks.findIndex((elem) => elem.id == taskId);
    state.tasks[index].type = payload.type;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
