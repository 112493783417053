<template>
  <v-app id="inspire">
    <v-app-bar app clipped-right class="header-app-bar primary">
      <v-row>
        <v-col class="d-flex align-center justify-start">
          <img
            v-show="$vuetify.theme.dark"
            src="@/assets/dark-mode-logo.png"
            alt="Focts Logo"
            class="logo-header-dark"
            style="border: 0"
          />
          <img
            v-show="!$vuetify.theme.dark"
            src="@/assets/foctus-logo-whitebg-newbg.png"
            alt="Focts Logo"
            class="logo-header-light"
            style="border: 0"
          />
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <companychange place="unsubscribeduser"> </companychange>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="user.Profileimage != ''"
                v-on="on"
                :size="30"
                class="assigned-user secondary"
              >
                <v-img aspect-ratio="1" :src="user.Profileimage"></v-img>
              </v-avatar>
              <v-btn v-else dark v-bind="attrs" v-on="on" icon>
                <v-icon :class="[switch1 == true ? 'active' : 'inactive']"
                  >account_circle</v-icon
                >
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar v-if="user.Profileimage != ''">
                    <v-img :src="user.Profileimage" aspect-ratio="1"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-avatar
                    v-else
                    class="text-uppercase"
                    :class="{
                      'primary white--text ': !$vuetify.theme.dark,
                      'primary black--text': $vuetify.theme.dark,
                    }"
                  >
                    {{ getShortName(user.firstName, user.lastName) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium text-capitalize"
                    >
                      {{
                        firstletterupercase(user.firstName) +
                        " " +
                        firstletterupercase(user.lastName)
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        v-if="
                          user.allcompanies.find(
                            (data) => data.id == $route.query.companyId
                          ).isAdmin == true ||
                          user.allcompanies.find(
                            (data) => data.id == $route.query.companyId
                          ).isManager == true
                        "
                      >
                        <p
                          v-if="
                            user.allcompanies.find(
                              (data) => data.id == $route.query.companyId
                            ).isAdmin
                          "
                        >
                          Admin
                        </p>
                        <p
                          v-else-if="
                            user.allcompanies.find(
                              (data) => data.id == $route.query.companyId
                            ).isManager
                          "
                        >
                          Manager
                        </p>
                      </span>
                      <span v-else>
                        <p>User</p>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      :class="fav ? 'red--text' : ''"
                      icon
                      @click="fav = !fav"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions @click="logout">
                <v-spacer></v-spacer>
                <v-btn
                  block
                  type="submit"
                  class="mt-2"
                  :class="{
                    'gradient-button white--text': !$vuetify.theme.dark,
                    'white black--text': $vuetify.theme.dark,
                  }"
                  tile
                  color="#9e9e9ed1"
                  >log out
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import companychange from "./../layouts/companieschange.vue";
export default {
  data: () => ({
    menu: false,
    switch1: false,
    fav: true,
  }),
  mounted() {},
  computed: {
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
  },
  methods: {
    getShortName(firstName, lastName) {
      return firstName.substr(0, 1) + "" + lastName.substr(0, 1);
    },
    firstletterupercase(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    },
    logout() {
      localStorage.setItem("skipStartTooltip", false);
      var self = this;
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "entrance/logout", {
          date: new Date().getTime(),
        })
        .then(() => {
          self.$store.commit("account/logout");
          window.location.replace("/login");
        })
        .catch((error) => {
          error;
          self.$store.commit("account/logout");
          window.location.replace("/login");
        });
    },
  },
  components: {
    companychange,
  },
};
</script>
<style lang="scss">
@import "src/assets/scss/auth-layout.scss";
</style>
