export default {
  methods: {
    getShortName(firstName, lastName) {
      return firstName.substr(0, 1) + "" + lastName.substr(0, 1);
    },
    validateFiles(files) {
      const typeOfFiles = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/pdf",
        "application/vnd.ms-excel",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
      ];
      var allowedToUpload = true;
      files.forEach((file) => {
        allowedToUpload = typeOfFiles.includes(file.type) && allowedToUpload;
      });
      return allowedToUpload;
    },
  },
};
