import axios from "axios";
const state = {
  templates: [],
  selectedId: "",
  selectedName: "",
  workspaces: [],
  skip: 0,
  limit: 100,
  noofrecord: 100,
  workspaceAllUsers: [],
  workspaceManagers: [],
  workspaceInvitations: [],
  workspaceUsers: [],
  currentworkspaceindex: "",
  companyUsersWhoAreNotAssignedToWorkspace: "",
};
const getters = {
  selectedWorkspaceId: (state) =>
    localStorage.getItem("selectedWorkspaceId") || state.selectedId,
  selectedWorkspaceName: (state) => {
    state.selectedName = localStorage.getItem("selectedWorkspaceName");
    return state.selectedName;
  },
  selectedWorkspaceIndex: (state) => {
    var workspaceid =
      localStorage.getItem("selectedWorkspaceId") || state.selectedId;
    state.currentworkspaceindex = state.workspaces.findIndex(
      (value) => value.id == workspaceid
    );
    return state.currentworkspaceindex;
  },
};
const actions = {
  assignWorkSpace({ commit }, payload) {
    commit("assignWorkSpace", {
      id: payload.id,
      name: payload.name,
    });
  },
  loadmoreworkspaces({ commit }, payload) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "workspace/get-workspaces", {
        params: {
          timestamp: new Date().getTime(),
          skip: payload.skip,
          limit: payload.limit,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          commit("loadmoreworkspaces", {
            workspaceinfo: response.data,
            payload: payload,
          });
        }
      });
  },
  getWorkspaces({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "workspace/get-workspaces", {
        params: {
          timestamp: new Date().getTime(),
          skip: 0,
          limit: 100,
        },
      })
      .then((response) => {
        commit("getWorkspaces", response.data);
      });
  },
  getWorkspaceUsersAndManagers({ commit }, workspaceId) {
    return axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "workspace/get-users-and-managers-of-workspace/" +
          workspaceId
      )
      .then((response) => {
        commit("getWorkspaceUsersAndManagers", {
          users: response.data.users,
          wsid: workspaceId,
          wsinvitations: response.data.wsinvitations,
        });
        commit("otherCompanyUsers", response.data.companyUsers);
      });
  },
  addWorkspace({ commit }, workspace) {
    commit("addWorkspace", workspace);
  },
  deleteWorkspace({ commit }, workspaceId) {
    return axios
      .delete(
        process.env.VUE_APP_ROOT_API +
          "workspace/delete-workspace/" +
          workspaceId
      )
      .then(() => {
        commit("deleteWorkspace", workspaceId);
      });
  },
  deleteprojecttasks({ commit }, data) {
    return axios
      .post(
        process.env.VUE_APP_ROOT_API +
          "workspace/delete-task-workspace-user/" +
          data.id,
        {
          id: data.id,
          userId: data.userId,
        }
      )
      .then((response) => {
        response;
        commit;
      });
  },
  ChangeworkspaceuserResponses({ commit }, data) {
    return axios
      .post(
        process.env.VUE_APP_ROOT_API +
          "workspace/change-workspace-user-responsibilities/" +
          data.workspaceid,
        {
          deleteuserid: data.deleteuserid,
          id: data.workspaceid,
          projectjson: data.projectjson,
          workspaceuser: data.workspaceuser,
          workspacemanagerstatus: data.workspacemanagerstatus,
        }
      )
      .then((response) => {
        commit("assignChangeworkspaceuser", response);
      });
  },
  assignUsersToWorkspace({ commit }, { users, workspaceId, emails }) {
    return axios
      .post(
        process.env.VUE_APP_ROOT_API +
          "workspace/assign-users-to-workspace/" +
          workspaceId,
        {
          users: users,
          emails: emails,
        }
      )
      .then((response) => {
        commit("assignUsersToWorkspace", response.data);
      });
  },
  assignUserAsManager({ commit }, { selecteduser, workspaceid }) {
    return axios
      .post(
        process.env.VUE_APP_ROOT_API +
          "workspace/switch-workspace-user-to-manager/" +
          workspaceid,
        {
          id: workspaceid,
          user: selecteduser._id,
        }
      )
      .then((response) => {
        commit("getWorkspaceUsersAndManagers", {
          users: response.data.users,
          wsinvitations: response.data.wsinvitations,
          wsid: workspaceid,
        });
      });
  },
};
const mutations = {
  assignsearchedindex(state, searchitem) {
    var selectedworksaceid = localStorage.getItem("selectedWorkspaceId");
    if (searchitem) {
      state.currentworkspaceindex = state.workspaces
        .filter((item) =>
          item.name.toLowerCase().includes(searchitem.toLowerCase())
        )
        .findIndex((value) => value.id == selectedworksaceid);
    } else {
      state.currentworkspaceindex = state.workspaces.findIndex(
        (value) => value.id == selectedworksaceid
      );
    }
  },
  assignChangeworkspaceuser(state, payload) {
    payload;
  },
  assignindex(state, payload) {
    state.currentworkspaceindex = payload;
  },
  loadmoreworkspaces(state, params) {
    state.skip = params.payload.skip;
    state.limit = params.payload.limit;
    params.workspaceinfo.forEach((item) => {
      state.workspaces.push(item);
    });
  },
  assignWorkSpace(state, workSpace) {
    localStorage.setItem("selectedWorkspaceId", workSpace.id);
    localStorage.setItem("selectedWorkspaceName", workSpace.name);
    state.selectedId = workSpace.id;
    state.selectedName = workSpace.name;
    state.currentworkspaceindex = state.workspaces.findIndex(
      (value) => value.id == workSpace.id
    );
  },
  getWorkspaces(state, workspaces) {
    state.workspaces = workspaces;
  },
  updateworkspacename(state, workspace) {
    state.selectedName = workspace.name;
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == workspace.id
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].name = workspace.name;
    }
    localStorage.setItem("selectedWorkspaceName", workspace.name);
  },
  updateprojectcount(state, project) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == project.workspace
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].projectlength =
        state.workspaces[workspaceindex].projectlength + 1;
    }
  },
  updateProjectDeletecount(state, workspace) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == workspace
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].projectlength =
        state.workspaces[workspaceindex].projectlength - 1;
    }
  },
  updateTaskdeletecount(state, project) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == project
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].taskcount =
        state.workspaces[workspaceindex].taskcount - 1;
    }
  },
  updateprojectTaskdeletecount(state, ws) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == ws.id
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].taskcount =
        state.workspaces[workspaceindex].taskcount - ws.count;
    }
  },
  updatetaskcount(state, project) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == project
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].taskcount =
        state.workspaces[workspaceindex].taskcount + 1;
    }
  },
  updateworkspaceusercount(state, project) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == project.workspaceid
    );
    if (workspaceindex != -1) {
      state.workspaces[workspaceindex].workapcemanagers = project.managers;
      state.workspaces[workspaceindex].userscount =
        project.users.length + project.managers.length;
    }
  },
  addWorkspace(state, workspace) {
    state.workspaces.unshift(workspace);
  },
  deleteWorkspace(state, workspaceId) {
    var workspaceindex = state.workspaces.findIndex(
      (value) => value.id == workspaceId
    );
    state.workspaces.splice(workspaceindex, 1);
  },
  assignselectedindex(state) {
    var selectedworksaceid = localStorage.getItem("selectedWorkspaceId");
    state.currentworkspaceindex = state.workspaces.findIndex(
      (value) => value.id == selectedworksaceid
    );
  },
  assignUsersToWorkspace(state, payload) {
    payload.users.forEach((element) => {
      state.workspaceUsers.push(element);
    });
    payload.managers.forEach((element) => {
      state.workspaceManagers.push(element);
    });
    state.workspaceInvitations = payload.assignedToEmails;
  },
  getWorkspaceUsersAndManagers(state, payload) {
    var manager = [];
    var user = [];
    state.workspaceAllUsers = payload.users;
    state.workspaceInvitations = payload.wsinvitations;
    payload.users.forEach((element) => {
      var userindex = element.workspaces.findIndex(
        (data) => data.workspace == payload.wsid && data.isWorkspaceManager
      );
      if (userindex != -1) {
        manager.push(element);
      } else {
        user.push(element);
      }
    });
    state.workspaceManagers = manager;
    state.workspaceUsers = user;
  },
  otherCompanyUsers(state, payload) {
    state.companyUsersWhoAreNotAssignedToWorkspace = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
