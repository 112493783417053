var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.clickable)?_c('router-link',{staticClass:"text-decoration-none subtitle-2 black--text inbox-title",attrs:{"to":{
      name: 'singleuser-info',
      params: { userId: _vm.userId },
      query: {
        companyId: _vm.$route.query.companyId,
      },
    }}},[(
        _vm.$route.name == 'leave-control' ||
        _vm.$route.name == 'my-time' ||
        _vm.$route.name == 'my-time.my-time-report' ||
        _vm.$route.name == 'timer.project_timer' ||
        _vm.$route.name == 'timer.project_report'
      )?_c('div',{staticClass:"pl-0"},[_c('div',{staticClass:"avatar primary list-avatar tooltip",class:[
          {
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },
          _vm.$route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
        ]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.user.Profileimage != '')?_c('v-img',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"src":_vm.user.Profileimage,"aspect-ratio":"1"}},'v-img',attrs,false),on)):_c('div',_vm._g(_vm._b({staticClass:"text-uppercase"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getShortName(_vm.user.firstName, _vm.user.lastName))+" ")])]}}],null,false,3788113400)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.user.firstName + " " + _vm.user.lastName))])])],1)]):_c('div',{staticClass:"pl-0"},[_c('div',{staticClass:"avatar primary list-avatar tooltip",class:[
          {
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },
          _vm.$route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
        ]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.user.Profileimage != '')?_c('v-img',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"src":_vm.user.Profileimage,"aspect-ratio":"1"}},'v-img',attrs,false),on)):_c('div',_vm._g(_vm._b({staticClass:"text-uppercase"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getShortName(_vm.user.firstName, _vm.user.lastName))+" ")])]}}],null,false,3788113400)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.user.firstName + " " + _vm.user.lastName))])])],1)])]):_c('div',{staticClass:"text-decoration-none subtitle-2 black--text inbox-title"},[(
        _vm.$route.name == 'leave-control' ||
        _vm.$route.name == 'my-time' ||
        _vm.$route.name == 'my-time.my-time-report' ||
        _vm.$route.name == 'timer.project_timer' ||
        _vm.$route.name == 'timer.project_report'
      )?_c('div',{staticClass:"pl-0"},[_c('div',{staticClass:"avatar primary list-avatar tooltip",class:[
          {
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },
          _vm.$route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
        ]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.user.Profileimage != '')?_c('v-img',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"src":_vm.user.Profileimage,"aspect-ratio":"1"}},'v-img',attrs,false),on)):_c('div',_vm._g(_vm._b({staticClass:"text-uppercase"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getShortName(_vm.user.firstName, _vm.user.lastName))+" ")])]}}],null,false,3788113400)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.user.firstName + " " + _vm.user.lastName))])])],1)]):_c('div',{staticClass:"pl-0"},[_c('div',{staticClass:"avatar primary list-avatar tooltip",class:[
          {
            'white--text': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          },
          _vm.$route.name == 'single-project-tasks' ? 'mr-0' : 'mr-2',
        ]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.user.Profileimage != '')?_c('v-img',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"src":_vm.user.Profileimage,"aspect-ratio":"1"}},'v-img',attrs,false),on)):_c('div',_vm._g(_vm._b({staticClass:"text-uppercase"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getShortName(_vm.user.firstName, _vm.user.lastName))+" ")])]}}])},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.user.firstName + " " + _vm.user.lastName))])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }