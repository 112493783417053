<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" @click:outside="cancel">
      <v-card class="pa-5">
        <p>{{ dialogTitle }}</p>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="cancel">Cancel</v-btn>
          <v-btn text class="primary" @click="submit">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    propdialog: {
      required: true,
      type: Boolean,
    },
    dialogTitle: {
      required: true,
      type: String,
    },
  },
  watch: {
    propdialog(val) {
      this.dialog = val;
    },
  },
  data() {
    return {
      dialog: this.propdialog,
    };
  },
  methods: {
    submit() {
      this.$emit('onConfirmed');
      this.dialog = false;
    },
    cancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped></style>
