<template>
  <div class="mb-0">
    <v-slide-group :show-arrows="windowWidth > 959 ? true : false">
      <v-slide-item v-for="(item, i) in items" :key="i">
        <v-btn
          :class="[
            activeidex == i ? 'v-btn--active white--text' : '',
            $vuetify.theme.dark && activeidex == i ? 'black--text' : '',
          ]"
          :exact="item.exact"
          large
          tile
          class="px-2 summary-tab text-subtitle-2"
          depressed
          @click="gotopage(i, item)"
        >
          <v-icon left small class="mr-1">{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>
<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
  data: () => ({
    showstatus: true,
    column: null,
    row: null,
    showSnackBar: false,
    windowWidth: window.innerWidth,
    createDialog: false,
    btnLoading: false,
    snackbarType: "error",
    snackMessage: "",
    functionName: "",
    items: [
      {
        icon: "notes",
        text: "Summary",
        routeName: "single-project",
        exact: false,
      },
      {
        icon: "list",
        text: "Tasks",
        routeName: "single-project-tasks",
        exact: false,
      },
      {
        icon: "folder_open",
        text: "Files",
        routeName: "single-project-files",
        exact: false,
      },
      {
        icon: "people_outline",
        text: "Users",
        routeName: "project-users",
        exact: false,
      },
    ],
    transactions: "",
    isprojectmanager: [],
  }),
  mounted() {
    var self = this;
    this.$nextTick(() => {
      self.$store.dispatch('project/settingsDispatches', this.$route.params.id);
      self.$store.dispatch('project/Iscaneditproject', this.$route.params.id);
      self.getisprojectmanager();
    });
  },
  watch: {},
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
    activeidex: {
      get() {
        var self = this;
        var index = 0;
        var routeindex = this.items.findIndex(
          (data) => data.routeName == self.$route.name
        );
        if (routeindex == -1) {
          if (
            this.$route.name == "single-project-tasks" ||
            this.$route.name == "single-project-tasks.board" ||
            this.$route.name == "single-project-tasks.calendar" ||
            this.$route.name == "single-project-tasks.ganttChart" ||
            this.$route.name == "single-project-tasks.table"
          ) {
            index = 1;
          } else if (
            this.$route.name == "timer.project_timer" ||
            this.$route.name == "timer.project_report"
          ) {
            index = 6;
          } else {
            index = routeindex;
          }
        } else {
          index = routeindex;
        }
        return index;
      },
      set(newValue) {
        newValue;
      },
    },
  },
  methods: {
    gotopage(i, routevalue) {
      var self = this;
      if (routevalue.text == "Tasks") {
        var localtaskviewitems = localStorage.getItem("taskview");
        if (localtaskviewitems == null) {
          this.$router.push({
            name: "single-project-tasks",
            params: this.$route.params,
            query: {
              companyId: this.$route.query.companyId,
            },
          });
        } else {
          var localview = JSON.parse(localtaskviewitems);
          var taskindex = localview.findIndex(
            (data) => data.project == self.$route.params.id
          );
          if (localview.length > 0 && taskindex != -1) {
            if (localview[taskindex].view !== self.$route.name) {
              this.$router.push({
                name: localview[taskindex].view,
                params: this.$route.params,
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
            }
          } else {
            this.$router.push({
              name: "single-project-tasks",
              params: this.$route.params,
              query: {
                companyId: this.$route.query.companyId,
              },
            });
          }
        }
      } else {
        this.$router.push({
          name: routevalue.routeName,
          params: this.$route.params,
          query: {
            companyId: this.$route.query.companyId,
          },
        });
      }
      this.activeidex = i;
    },
    getisprojectmanager() {
      var self = this;
      this.$store
        .dispatch("project/getProjectUsers", self.$route.params.id)
        .then(() => {
          var response = self.$store.state.project.projectUsers;
          response.forEach((element) => {
            if (element.id == this.user.id) {
              if (element.isAdmin || element.isManager) {
                self.isprojectmanager.push("true");
              }
            }
          });
          self.$nextTick(() => {
            if (
              self.user.allcompanies.find(
                (data) => data.id == this.$route.query.companyId
              ).isAdmin ||
              (self.isprojectmanager.length > 0 &&
                self.isprojectmanager.includes("true"))
            ) {
              self.items.push(
                {
                  icon: "settings",
                  text: "Settings",
                  routeName: "project-settings",
                  exact: false,
                },
                {
                  icon: "account_balance",
                  text: "Project value",
                  routeName: "project-value",
                  exact: false,
                },
                {
                  icon: "timer",
                  text: "Project Timer",
                  routeName: "timer.project_timer",
                  exact: false,
                }
              );
            }
          });
        });
    },
  },
};
</script>

<style type="text/css" scoped>
.summary-tab.primary.white--text.v-btn--active.theme--dark {
  color: #ffffff !important;
  background-color: #333333 !important;
  border: 0 !important;
}
</style>
