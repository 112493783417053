import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import invitation from "./modules/invitation";
import workspace from "./modules/workspace";
import project from "./modules/project";
import mywork from "./modules/mywork";
import chat from "./modules/chat";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectBreadCrumbs: [],
  },
  getters: {
    projectBreadCrumbs: (state) => state.projectBreadCrumbs,
  },
  mutations: {
    assignToProjectBreadCrumb(state, payload) {
      state.projectBreadCrumbs = payload;
    },
    assignToProjectBreadCrumbname(state, payload) {
      state.projectBreadCrumbs[1].text = payload;
    },
  },
  actions: {},
  modules: {
    account,
    invitation,
    workspace,
    project,
    mywork,
    chat,
  },
});
