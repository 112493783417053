<template>
  <v-card
    elevation="0"
    class="comment-single-task"
  >
    <div>
      <div class="d-flex align-center">
        <userinfo
          :user="comment.userInfo"
          :clickable="true"
          :userId="comment.owner"
        ></userinfo>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <div class="title text-body-1">{{ fullname }}</div>
            <span class="pr-6 grey--text lighten-4 text-caption">
              {{ moment(comment.createdAt).fromNow() }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            class="text-right pa-0"
          >
            <v-menu
              offset-y
              right
              v-if="checkeditstatus(comment)"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  v-on="on"
                  icon
                  class="float-right"
                  :class="{
                    'primary--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                >
                  <v-icon>settings </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in deleteitems"
                  :key="index"
                >
                  <v-list-item-title
                    @click="editdeletecomments(item.title, comment)"
                    class="text-capitalize cursor-pointer"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div v-if="editable.id == comment.id && editable.status">
        <div>
          <tiptap-vuetify
            ref="tiptapPostComment"
            v-model="comment.text"
            v-validate="'required'"
            name="commentname"
            data-vv-scope="editcomment"
            :eerrorrror-messages="errors.collect('editcomment.commentname')"
            :toolbar-attributes="{ color: 'grey lighten-5' }"
            :extensions="extensions"
            :card-props="{
              outlined: true,
              loaderHeight: 2,
            }"
            class="editable-field-scroll"
          />
          <div>
            <v-file-input
              v-model="files"
              data-vv-scope="commentscope"
              v-validate="
                'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.txt,.3g2,.webm,.ogg,video/3gpp2,audio/ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,.ppt,.asf,application/vnd.ms-asf, application/vnd.ms-powerpoint'
              "
              accept="application/msword,.asf,,.ppt, application/vnd.ms-asf,application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*,.xlsx,.doc,.docx,.ods,text/plain, application/vnd.ozpenxmlformats-officedocument.wordprocessingml.document, application/msword,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv"
              @change="uploadFileForComment"
              name="updatecommentfile"
              data-vv-as="file"
              :error-messages="errors.collect('commentscope.commentfile')"
              :hide-input="true"
              multiple
            ></v-file-input>
          </div>
          <div class="attach-comment">
            <div v-if="comment.editable">
              <div v-if="commentFiles.length > 0">
                <h6>
                  {{ commentFiles.length }}
                  {{ commentFiles.length > 1 ? 'files' : 'file' }}
                  added
                </h6>
              </div>
              <v-row v-if="commentFiles.length > 0">
                <v-col
                  v-for="(file, index) in commentFiles"
                  :key="index"
                  cols="3"
                >
                  <v-card
                    class="d-flex justify-center align-center pa-0"
                    style="height: 130px; width: 130px"
                  >
                    <template v-if="file.type.split('/')[0] == 'image'">
                      <a
                        :href="file.path"
                        target="_blank"
                      >
                        <v-img
                          :lazy-src="
                            require('src/assets/comment-image-placeholder.png')
                          "
                          :src="file.path"
                          aspect-ratio="1"
                          class="rounded"
                        ></v-img>
                      </a>
                    </template>
                    <template v-else>
                      <v-icon
                        large
                        :title="file.path"
                        >insert_link</v-icon
                      >
                    </template>
                    <v-btn
                      absolute
                      depressed
                      x-small
                      fab
                      class="delete-preview right top error"
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                      @click="removePreview(index)"
                    >
                      <v-icon>delete_outline</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="
            comment.updateType == 'timerstoped' ||
            comment.updateType == 'timerreported'
          "
        >
          <template v-if="comment.commentTimerhistory.length > 0">
            <div class="pa-0 pa-md-4">
              <v-expansion-panels>
                <template>
                  <v-expansion-panel
                    class="mb-4 pa-0"
                    elevation="0"
                  >
                    <v-expansion-panel-header>
                      <div class="d-flex pt-3 align-center inside-timer">
                        <userinfo
                          :user="getuserinfo(comment.commentFromUser)"
                          :clickable="true"
                          :userId="comment.commentFromUser"
                        ></userinfo>
                        <div>
                          <div
                            class="text-body-2 pt-1 comment-content"
                            v-html="returncomment(comment)"
                          ></div>
                        </div>
                      </div>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="(
                        singlehistory, index
                      ) in comment.commentTimerhistory"
                      :key="index"
                    >
                      <div class="d-flex pt-3 align-center inside-timer">
                        <userinfo
                          :user="getuserinfo(singlehistory.userid)"
                          :clickable="true"
                          :userId="singlehistory.userid"
                        ></userinfo>
                        <div>
                          <div
                            v-html="returntimercomment(singlehistory, comment)"
                          ></div>
                          <span class="grey--text lighten-4 text-caption">
                            {{ moment(singlehistory.created).fromNow() }}
                          </span>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </div>
          </template>
          <div v-else>
            <div
              class="text-body-2 pt-1 comment-content"
              v-html="returncomment(comment)"
            ></div>
          </div>
        </div>
        <div v-else>
          <div
            class="text-body-2 pt-1 comment-content"
            v-html="returncomment(comment)"
          ></div>
        </div>
      </div>
      <v-card-actions v-if="comment.editable">
        <div
          class="attachments"
          v-if="comment.attachments.length > 0"
        >
          <div class="d-flex flex-wrap justify-start">
            <v-card
              v-for="(file, index) in sortbycomment(comment.attachments)"
              :key="index"
              class="d-flex justify-center align-center mx-2 pa-2 mt-4 rounded-lg elevation-1"
              :width="130"
              :height="130"
              outlined
            >
              <div class="group-icon-task">
                <template v-if="file.type.split('/')[0] == 'image'">
                  <a
                    :href="file.path"
                    target="_blank"
                  >
                    <v-img
                      :lazy-src="
                        require('src/assets/comment-image-placeholder.png')
                      "
                      :src="file.path"
                      aspect-ratio="1"
                      class="rounded"
                    ></v-img>
                  </a>
                </template>
                <template v-else>
                  <v-icon
                    large
                    :title="file.path"
                    class="insert-link-icon"
                    >insert_link</v-icon
                  >
                </template>
                <v-btn
                  icon
                  :href="file.path"
                  target="_blank"
                  :class="
                    file.type.split('/')[0] == 'image'
                      ? 'absolute launch-icon-image'
                      : ''
                  "
                >
                  <v-icon color="grey lighten-1">launch</v-icon>
                </v-btn>
              </div>
              <div
                v-if="file.filename"
                class="attachment-filename pa-1 text-caption text-truncate"
              >
                {{ file.filename }}
              </div>

              <v-btn
                absolute
                depressed
                x-small
                fab
                class="delete-preview-button error"
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                @click="openRemoveFileDialog(file, index)"
              >
                <v-icon>delete_outline</v-icon>
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-card-actions>
      <div
        class="pt-3 text-right"
        v-if="editable.id == comment.id && editable.status"
      >
        <v-btn
          tile
          small
          outlined
          color="red"
          @click="reseteditdata(comment)"
          >Cancel</v-btn
        >
        <v-btn
          tile
          small
          outlined
          color="primary"
          class="ml-4"
          @click="submiteditdata(comment.text, comment)"
        >
          Update</v-btn
        >
      </div>
    </div>
    <v-dialog
      v-model="updatepopup"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div class="horizontal-dividing-header mb-0">
            <h6 class="header text-uppercase mt-0">
              Update Report Timer Informations
            </h6>
          </div>
        </v-card-title>
        <v-card-text>
          <div v-if="reportTimerstatus == true">
            <v-row>
              <v-col class="pl-0">
                <div>
                  <v-menu
                    ref="reportmenu"
                    v-model="reportmenu"
                    :close-on-content-click="false"
                    :return-value.sync="reporttimerinfo.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        append-icon="event"
                        dense
                        v-model="reporttimerinfo.date"
                        label="Picker in menu"
                        readonly
                        :error-messages="errors.collect('update-timer.date')"
                        data-vv-as="date"
                        data-vv-scope="update-timer"
                        data-vv-name="date"
                        v-validate="'required'"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :max="minEndDate"
                      v-model="reporttimerinfo.date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="reportmenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.reportmenu.save(reporttimerinfo.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-0">
                <v-text-field
                  label="Worked hours"
                  type="number"
                  min="0"
                  dense
                  :error-messages="errors.collect('update-timer.hours')"
                  data-vv-as="hours"
                  data-vv-scope="update-timer"
                  data-vv-name="hours"
                  v-validate="
                    'required|numeric|max_value:23|checkhoursandminutes:minutes'
                  "
                  v-model="reporttimerinfo.hours"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-0">
                <v-text-field
                  label="Worked minutes"
                  min="0"
                  dense
                  :error-messages="errors.collect('update-timer.minutes')"
                  data-vv-as="minutes"
                  data-vv-scope="update-timer"
                  data-vv-name="minutes"
                  v-validate="'required|numeric|max_value:60'"
                  ref="minutes"
                  v-model="reporttimerinfo.minutes"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="startTimerstatus == true">
            <v-row class="flex-column flex-sm-column flex-md-row">
              <v-col class="d-flex flex-column pointerdatepicker">
                <v-datetime-picker
                  label="Select start date & time"
                  v-model="timerStartdate"
                  v-validate="'required|checkstartdate'"
                  data-vv-scope="update-timer"
                  data-vv-name="start date"
                  outlined
                  dense
                  class="pointerdatepicker"
                  ref="timerStartdate"
                ></v-datetime-picker>
                <div
                  class="error_message"
                  v-if="errors.collect('update-timer.start date').length > 0"
                >
                  <div
                    v-for="(error, index) in errors.collect(
                      'update-timer.start date'
                    )"
                    :key="index"
                  >
                    <p>{{ error }}</p>
                  </div>
                </div>
              </v-col>
              <v-col class="d-flex flex-column pointerdatepicker">
                <v-datetime-picker
                  label="Select end date & time"
                  v-model="timerEnddate"
                  :disabled="!timerStartdate"
                  v-validate="'required|checkendtdate:timerStartdate'"
                  data-vv-scope="update-timer"
                  data-vv-name="end date"
                  class="pointerdatepicker"
                ></v-datetime-picker>
                <div
                  class="error_message"
                  v-if="errors.collect('update-timer.end date').length > 0"
                >
                  <div
                    v-for="(error, index) in errors.collect(
                      'update-timer.end date'
                    )"
                    :key="index"
                  >
                    <p>{{ error }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="red"
            text
            @click="closeupdatepopup()"
            >Close</v-btn
          >
          <v-btn
            :class="{
              'gradient-button white--text': !$vuetify.theme.dark,
              'white black--text': $vuetify.theme.dark,
            }"
            tile
            contained
            text
            @click="updateTimerinfo()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="deleteconfirm"
          persistent
          width="500px"
        >
          <v-card>
            <v-card-title class="text-h6"> Are you sure? </v-card-title>
            <v-card-text>
              <div class="text-body-2">
                You want to delete the report time info
              </div></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                small
                outlined
                class="red--text"
                variant="text"
                @click="
                  deleteconfirm = false;
                  deleteinfo = {};
                "
              >
                No
              </v-btn>
              <v-btn
                small
                color="primary"
                variant="text"
                @click="deletetimer"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-snackbar
      v-model="showSnackBar"
      :bottom="true"
      :color="messagecolor"
      >{{ snackMessage }}</v-snackbar
    >
  </v-card>
</template>
<script>
import Vue from 'vue';
import DatetimePicker from 'vuetify-datetime-picker';
import userinfo from './../../../dashboard/_userAvatar.vue';
import timezonemoment from 'moment-timezone';
import { bus } from './../../../../../main';
import moment from 'moment';
import { Validator } from 'vee-validate';
const dictionary = {
  en: {
    messages: {
      max_value: (name) => {
        var maxvalue = name == 'minutes' ? 60 : 23;
        return (
          'The ' + name + ' field should be less than or equal to ' + maxvalue
        );
      },
    },
  },
};
Validator.localize(dictionary);
const validator = new Validator({ minutes: 'max_value', hours: 'max_value' });
validator.localize('en');
Vue.use(DatetimePicker);
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
} from 'tiptap-vuetify';
export default {
  data() {
    return {
      timezonemoment: timezonemoment,
      files: [],
      historypanel: [0, 1],
      disabled: false,
      readonly: false,
      deleteconfirm: false,
      updatepopup: false,
      reportmenu: false,
      reportTimerstatus: false,
      startTimerstatus: false,
      starttimerinfo: {},
      reporttimerinfo: {},
      deleteinfo: {},
      timerStartdate: '',
      timerEnddate: '',
      minEndDate: moment().format('YYYY-MM-DD'),
      commentFiles: [],
      showSnackBar: false,
      snackMessage: '',
      messagecolor: 'red',
      deleteitems: [
        {
          name: 'Edit comment',
          title: 'edit',
          icon: 'edit',
        },
        {
          name: 'Delete comment',
          title: 'delete',
          icon: 'delete',
        },
      ],
      editable: {
        id: '',
        status: false,
      },
      editablecomment: '',
      deletecommentid: '',
      confirmdeletecomment: false,
      moment: moment,
      edit: false,
      settingButton: true,
      editCommentText: '',
      editcommentFiles: [],
      imageattachments: [],
      extensions: [
        Blockquote,
        [
          Link,
          {
            options: {
              target: '_blank',
            },
          },
        ],
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        HardBreak,
      ],
      previewFiles: [],
      comment: this.propcomment,
    };
  },
  props: {
    canDeleteTask: {
      required: false,
      type: Boolean,
    },
    propcomment: {
      required: true,
      type: Object,
    },
    project: {
      required: true,
      type: Object,
    },
    priorityItems: {
      required: true,
      type: Array,
    },
    statuses: {
      required: true,
      type: Array,
    },
    taskTypes: {
      required: true,
      type: Array,
    },
  },
  computed: {
    allusers() {
      return this.$store.state.mywork.companyusers;
    },
    fullname() {
      return this.firstletterupercase(
        this.comment.userInfo.firstName +
          ' ' +
          this.firstletterupercase(this.comment.userInfo.lastName)
      );
    },
    shortenedName() {
      return (
        this.comment.userInfo.firstName[0] +
        '' +
        this.comment.userInfo.lastName[0]
      );
    },
    canEditComment() {
      return (
        (this.comment.owner !== JSON.parse(this.$store.state.account.user).id ||
          this.project.isUserManager) &&
        this.comment.editable
      );
    },
    authuser() {
      return JSON.parse(this.$store.state.account.user).id;
    },
  },
  created() {
    var self = this;
    self.$validator.extend('checkstartdate', {
      getMessage: () =>
        'Start date & time should be less than the current time',
      validate: (value) => {
        var current = new Date().getTime();
        var inputdate = new Date(value).getTime();
        if (inputdate <= current) {
          return true;
        } else {
          return false;
        }
      },
    });
    self.$validator.extend(
      'checkendtdate',
      {
        getMessage: () =>
          'End date & time should be less than the current time and greater than start date & time',
        validate: (value, [timerStartdate]) => {
          var current = new Date().getTime();
          var start = new Date(timerStartdate).getTime();
          var inputdate = new Date(value).getTime();
          if (start > inputdate) {
            return false;
          }
          if (inputdate <= current) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        hasTarget: true,
      }
    );
    this.$validator.extend(
      'checkhoursandminutes',
      {
        validate: (hours, [minutes]) => {
          var returndata = true;
          if (minutes == 0 && parseInt(hours) == 0) {
            returndata = false;
          }
          return returndata;
        },
      },
      {
        hasTarget: true,
      }
    );
  },
  mounted() {},
  methods: {
    checkeditstatus(comment) {
      var editstatus = false;
      if (comment.editable && this.settingButton) {
        if (
          comment.updateType == 'timerstoped' ||
          comment.updateType == 'timerreported'
        ) {
          if (this.canDeleteTask == true || comment.owner == this.authuser) {
            editstatus = true;
          }
        } else {
          if (comment.owner == this.authuser) {
            editstatus = true;
          }
        }
      }
      return editstatus;
    },
    getuserinfo(uesrid) {
      var userindex = this.allusers.findIndex((data) => data.id == uesrid);
      return this.allusers[userindex];
    },
    returntimercomment(history, comment) {
      var message = '<p></p>';
      var userindex = this.allusers.findIndex(
        (data) => data.id == history.userid
      );
      if (comment.updateType == 'timerreported') {
        var fromuser_index = this.allusers.findIndex(
          (user) => user.id == history.reportedinfo.from_user
        );
        var touser_index = this.allusers.findIndex(
          (user) => user.id == history.reportedinfo.to_user
        );
        var updatedstatus = history.reportedinfo.addedstatus
          ? ' reported time for the day ' +
            '<b> ' +
            moment(history.reportedinfo.date).format('DD-MM-YYYY') +
            '</b>'
          : ' updated the time report for the day ' +
            '<b> ' +
            moment(history.reportedinfo.date).format('DD-MM-YYYY') +
            '</b>';
        var timerinfo =
          ' with the total worked hrs being <b>' +
          history.reportedinfo.hours +
          'h:' +
          history.reportedinfo.minutes +
          'm </b>';
        if (history.reportedinfo.isManagerAdded) {
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[fromuser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuser_index].lastName) +
            updatedstatus +
            ' for user ' +
            this.firstletterupercase(this.allusers[touser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[touser_index].lastName) +
            timerinfo +
            '</p>';
        } else {
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[fromuser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuser_index].lastName) +
            updatedstatus +
            timerinfo +
            '</p>';
        }
      } else if (comment.updateType == 'timerstoped') {
        var startdate = this.moment(
          parseInt(history.timerinfo.timerstart)
        ).format('DD-MM-YYYY');
        var enddate = this.moment(
          parseInt(history.timerinfo.timerstoped)
        ).format('DD-MM-YYYY');
        var startampm = this.moment(
          parseInt(history.timerinfo.timerstart)
        ).format('hh:mm A');
        var endampm = this.moment(
          parseInt(history.timerinfo.timerstoped)
        ).format('hh:mm A');
        if (history.timerinfo.stoppedstatus) {
          var timezonedffer = '';
          if (
            this.timezonemoment.tz.guess() != history.timerinfo.startTimezone
          ) {
            timezonedffer =
              ', Please note that there was a timezone change between the start and the end date.';
          }
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[userindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[userindex].lastName) +
            ' started the timer on <b>' +
            startdate +
            '</b> at <b>' +
            startampm +
            '</b> and stopped on <b>' +
            enddate +
            '</b> at <b>' +
            endampm +
            '</b>, with the total worked hrs being <b>' +
            this.getHrsMnts(history.timerinfo) +
            timezonedffer;
          ('</b></p>');
        } else {
          var reportfromuser_index = this.allusers.findIndex(
            (user) => user.id == history.timerinfo.from_user
          );
          var reporttouser_index = this.allusers.findIndex(
            (user) => user.id == history.timerinfo.to_user
          );
          var rangetimerinfo =
            "<p class='text-body-1'>" +
            this.firstletterupercase(
              this.allusers[reportfromuser_index].firstName +
                ' ' +
                this.allusers[reportfromuser_index].lastName
            ) +
            ' updated the start time to <b>' +
            startdate +
            '</b> , <b>' +
            startampm +
            '</b> and the end time to <b>' +
            enddate +
            ' ' +
            endampm +
            '</b> ';
          if (history.timerinfo.isManagerAdded) {
            message =
              rangetimerinfo +
              ' for user ' +
              this.firstletterupercase(
                this.allusers[reporttouser_index].firstName
              ) +
              ' ' +
              this.firstletterupercase(
                this.allusers[reporttouser_index].lastName
              ) +
              ' with the total worked hrs being <b>' +
              this.getHrsMnts(history.timerinfo) +
              '</b></p>';
          } else {
            message =
              rangetimerinfo +
              '</b> with the total worked hrs being <b>' +
              this.getHrsMnts(history.timerinfo) +
              '</b></p>';
          }
        }
      }
      return message;
    },
    getHrsMnts(args) {
      const getDatesBetweenDates = (startDate, endDate) => {
        let dates = [];
        const theDate = new Date(startDate);
        while (theDate <= endDate) {
          dates = [...dates, new Date(theDate)];
          theDate.setDate(theDate.getDate() + 1);
        }
        dates = [...dates];
        var formatedendDate = moment(parseInt(endDate)).format('YYYY-MM-DD');
        var lastdate = dates[dates.length - 1];
        var datesLastdate = moment(lastdate).format('YYYY-MM-DD');
        if (formatedendDate != datesLastdate) {
          dates.push(new Date(endDate));
        }
        var returndates = dates.map((data) =>
          moment(data).format('YYYY-MM-DD')
        );
        return returndates;
      };
      const returnHrs = (interval) => {
        var totalspentTime = {
          minutes: Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60)),
          hours: Math.floor(
            (interval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          day: Math.floor(interval / (1000 * 60 * 60 * 24)),
        };
        var spendtimeformate = '0h 0m';
        if (
          totalspentTime.day != 0 ||
          totalspentTime.hours != 0 ||
          totalspentTime.minutes != 0
        ) {
          var returndate = {
            minutes: totalspentTime.minutes + 'm ',
            hours: totalspentTime.hours + totalspentTime.day * 24 + 'h ',
          };
          spendtimeformate = returndate.hours + returndate.minutes;
        }
        return spendtimeformate;
      };
      var completetimestamp = 0;
      var fromformated = moment(parseInt(args.timerstart)).format('YYYY-MM-DD');
      var toformated = moment(parseInt(args.timerstoped)).format('YYYY-MM-DD');
      var datesaray = getDatesBetweenDates(
        parseInt(args.timerstart),
        parseInt(args.timerstoped)
      );
      var startTimestamp = parseInt(args.timerstart);
      var endTimestamp = parseInt(args.timerstoped);
      for (const singleday of datesaray) {
        if (
          (fromformated == singleday && toformated != singleday) ||
          (toformated == singleday && fromformated != singleday) ||
          toformated == fromformated
        ) {
          if (fromformated == toformated) {
            if (endTimestamp - startTimestamp) {
              completetimestamp =
                completetimestamp + (endTimestamp - startTimestamp);
            }
          } else if (fromformated == singleday && toformated != singleday) {
            var nextday = moment(singleday, 'YYYY-MM-DD')
              .add(1, 'days')
              .format('YYYY-MM-DD HH:mm:ss');
            if (new Date(nextday).getTime() - startTimestamp) {
              completetimestamp =
                completetimestamp +
                (new Date(nextday).getTime() - startTimestamp);
            }
          } else if (toformated == singleday && fromformated != singleday) {
            var predate = moment(singleday, 'YYYY-MM-DD').format(
              'YYYY-MM-DD HH:mm:ss'
            );
            if (endTimestamp - new Date(predate).getTime()) {
              completetimestamp =
                completetimestamp +
                (endTimestamp - new Date(predate).getTime());
            }
          }
        } else if (toformated != singleday && fromformated != singleday) {
          var tomo = moment(new Date()).add(24, 'hours').format('YYYY-MM-DD');
          var today = moment(new Date()).format('YYYY-MM-DD');
          if (new Date(tomo).getTime() - new Date(today).getTime()) {
            completetimestamp =
              completetimestamp +
              (new Date(tomo).getTime() - new Date(today).getTime());
          }
        }
      }
      return returnHrs(completetimestamp);
    },
    deletetimer() {
      if (this.deleteinfo) {
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API +
              'task/delete-timer-info/' +
              this.deleteinfo.task,
            {
              deletetype: this.deleteinfo.deletetype,
              ReportId: this.deleteinfo.ReportId,
              timerId: this.deleteinfo.timerId,
            }
          )
          .then(() => {
            var command = this.comment;
            this.$emit('updatetimer', {
              type: 'delete',
              timer: {
                command: command,
              },
            });
            bus.$emit('delete-timer', this.comment.task);
            this.deleteconfirm = false;
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.messagecolor = 'red';
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.editstatus = true;
            }
          });
      }
    },
    updateTimerinfo() {
      var self = this;
      this.$validator.validateAll('update-timer').then((res) => {
        if (res) {
          if (self.comment.updateType == 'timerstoped') {
            self.$http
              .post(
                process.env.VUE_APP_ROOT_API +
                  'task/update-timer-info/' +
                  self.starttimerinfo.taskId,
                {
                  timezone: self.timezonemoment.tz.guess(),
                  timerId: self.starttimerinfo.id,
                  timerStartdate: new Date(self.timerStartdate).getTime(),
                  timerEnddate: new Date(self.timerEnddate).getTime(),
                }
              )
              .then((res) => {
                self.comment.timerinfo = res.data.command.timerinfo;
                self.$emit('updatetimer', {
                  type: 'timer',
                  timer: res.data,
                });
                self.closeupdatepopup();
                bus.$emit('update-timer', this.comment.task);
              })
              .catch((error) => {
                if (error.response.data && error.response.data.message) {
                  self.messagecolor = 'red';
                  self.snackMessage = error.response.data.message;
                  self.showSnackBar = true;
                  self.editstatus = true;
                }
              });
          } else if (self.comment.updateType == 'timerreported') {
            self.$http
              .post(
                process.env.VUE_APP_ROOT_API +
                  'task/update-reporttimeinfo/' +
                  self.reporttimerinfo.taskId,
                {
                  userId: self.authuser,
                  reportId: self.reporttimerinfo.id,
                  date: self.reporttimerinfo.date,
                  hours: self.reporttimerinfo.hours,
                  minutes: self.reporttimerinfo.minutes,
                }
              )
              .then((res) => {
                self.comment.reportedinfo = res.data.command.reportedinfo;
                self.$emit('updatetimer', {
                  type: 'report',
                  timer: res.data,
                });
                self.closeupdatepopup();
                bus.$emit('update-timer', this.comment.task);
              })
              .catch((error) => {
                if (error.response.data && error.response.data.message) {
                  self.messagecolor = 'red';
                  self.snackMessage = error.response.data.message;
                  self.showSnackBar = true;
                  self.editstatus = true;
                }
              });
          }
        }
      });
    },
    closeupdatepopup() {
      this.updatepopup = false;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    returncomment(comment) {
      var message = '<p></p>';
      var fromuserindex = this.allusers.findIndex(
        (fromuser) => fromuser.id == comment.commentFromUser
      );
      if (comment.updateType == 'message') {
        message = comment.text;
      } else if (
        comment.updateType == 'date' ||
        comment.updateType == 'created' ||
        comment.updateType == 'completed'
      ) {
        message =
          '<p>' +
          this.firstletterupercase(this.allusers[fromuserindex].firstName) +
          ' ' +
          this.firstletterupercase(this.allusers[fromuserindex].lastName) +
          comment.text +
          '</p>';
      } else if (comment.updateType == 'actionrequired') {
        var touuserindex = this.allusers.findIndex(
          (touser) => touser.id == comment.commentToUser
        );
        if (fromuserindex != -1 && touuserindex != -1) {
          message =
            '<p>' +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' updated the action required to ' +
            this.firstletterupercase(this.allusers[touuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[touuserindex].lastName) +
            '</p>';
        }
      } else if (comment.updateType == 'remove_actionrequired') {
        if (fromuserindex != -1) {
          message =
            '<p>' +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' removed the action required by user </p>';
        }
      } else if (comment.updateType == 'tasktype') {
        var typeindex = this.taskTypes.findIndex(
          (touser) => touser.id == comment.commentTaskType
        );
        if (typeindex != -1) {
          message =
            '<p>' +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' changed task type to ' +
            this.taskTypes[typeindex].name +
            '</p>';
        }
      } else if (comment.updateType == 'status') {
        var statusindex = this.statuses.findIndex(
          (touser) => touser.id == comment.commentStatus
        );

        if (statusindex != -1) {
          message =
            '<p>' +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' changed status to <span style=color:' +
            this.statuses[statusindex].color +
            '>' +
            this.statuses[statusindex].name +
            '</span></p>';
        }
      } else if (comment.updateType == 'priority') {
        var priorityindex = this.priorityItems.findIndex(
          (touser) => touser.id == comment.commentPriority
        );
        if (priorityindex != -1) {
          message =
            '<p>' +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' changed priority to <span style=color:' +
            this.priorityItems[priorityindex].color +
            '>' +
            this.priorityItems[priorityindex].name +
            '</span></p>';
        }
      } else if (comment.updateType == 'timerreported') {
        var fromuser_index = this.allusers.findIndex(
          (user) => user.id == comment.reportedinfo.from_user
        );
        var touser_index = this.allusers.findIndex(
          (user) => user.id == comment.reportedinfo.to_user
        );
        var updatedstatus = comment.reportedinfo.addedstatus
          ? ' reported time for the day <b>' +
            moment(comment.reportedinfo.date).format('DD-MM-YYYY') +
            '</b>'
          : ' updated the time report for the day <b>' +
            moment(comment.reportedinfo.date).format('DD-MM-YYYY') +
            '</b>';
        var timerinfo =
          ' with the total worked hrs being <b>' +
          comment.reportedinfo.hours +
          'h:' +
          comment.reportedinfo.minutes +
          'm </b>';
        if (comment.reportedinfo.isManagerAdded) {
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[fromuser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuser_index].lastName) +
            updatedstatus +
            ' for user ' +
            this.firstletterupercase(this.allusers[touser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[touser_index].lastName) +
            timerinfo +
            '</p>';
        } else {
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[fromuser_index].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuser_index].lastName) +
            updatedstatus +
            timerinfo +
            '</p>';
        }
      } else if (comment.updateType == 'timerstoped') {
        var startdate = this.moment(
          parseInt(comment.timerinfo.timerstart)
        ).format('DD-MM-YYYY');
        var startampm = this.moment(
          parseInt(comment.timerinfo.timerstart)
        ).format('hh:mm A');
        var enddate = this.moment(
          parseInt(comment.timerinfo.timerstoped)
        ).format('DD-MM-YYYY');
        var endampm = this.moment(
          parseInt(comment.timerinfo.timerstoped)
        ).format('hh:mm A');
        if (comment.timerinfo.stoppedstatus) {
          var timezonedffer = '';
          if (
            this.timezonemoment.tz.guess() != comment.timerinfo.startTimezone
          ) {
            timezonedffer =
              ', Please note that there was a timezone change between the start and the end date.';
          }
          message =
            "<p class='text-body-1'>" +
            this.firstletterupercase(this.allusers[fromuserindex].firstName) +
            ' ' +
            this.firstletterupercase(this.allusers[fromuserindex].lastName) +
            ' started the timer on <b>' +
            startdate +
            '</b> at <b>' +
            startampm +
            '</b> and stopped on <b>' +
            enddate +
            '</b> at <b>' +
            endampm +
            '</b>, with the total worked hrs being <b>' +
            this.getHrsMnts(comment.timerinfo) +
            '</b>' +
            timezonedffer +
            '</p>';
        } else {
          var reportfromuser_index = this.allusers.findIndex(
            (user) => user.id == comment.timerinfo.from_user
          );
          var reporttouser_index = this.allusers.findIndex(
            (user) => user.id == comment.timerinfo.to_user
          );
          var rangetimerinfo =
            "<p class='text-body-1'>" +
            this.firstletterupercase(
              this.allusers[reportfromuser_index].firstName +
                ' ' +
                this.allusers[reportfromuser_index].lastName
            ) +
            ' updated the start time to <b>' +
            startdate +
            '</b> , <b>' +
            startampm +
            '</b> and the end time to <b>' +
            enddate +
            ' ' +
            endampm +
            '</b> ';
          if (comment.timerinfo.isManagerAdded) {
            message =
              rangetimerinfo +
              ' for user ' +
              this.firstletterupercase(
                this.allusers[reporttouser_index].firstName
              ) +
              ' ' +
              this.firstletterupercase(
                this.allusers[reporttouser_index].lastName
              ) +
              ' with the total worked hrs being <b>' +
              this.getHrsMnts(comment.timerinfo) +
              '</b></p>';
          } else {
            message =
              rangetimerinfo +
              '</b> with the total worked hrs being <b>' +
              this.getHrsMnts(comment.timerinfo) +
              '</b></p>';
          }
        }
      }
      return message;
    },
    firstletterupercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    removePreview(previewindex) {
      this.commentFiles.splice(previewindex, 1);
    },
    gotosingleuser(user) {
      this.$router.push({
        name: 'singleuser-info',
        params: {
          userId: user,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    canEditTask(userid) {
      var authuser = JSON.parse(this.$store.state.account.user);
      var allmanagers = [];
      this.$store.state.project.projectUsers.forEach((user) => {
        if (
          user.user == userid &&
          (user.isManager == true || user.isAdmin == true)
        ) {
          allmanagers.push(user.user);
        }
      });
      if (
        authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin ||
        authuser.id == userid
      ) {
        return true;
      } else {
        return allmanagers.includes(
          JSON.parse(this.$store.state.account.user).id
        );
      }
    },
    reseteditdata(comment) {
      var self = this;
      this.settingButton = true;
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API + 'task/get-commenttext/' + comment.id
        )
        .then((response) => {
          self.comment.text = response.data.text;
          self.editable.id = '';
          self.editable.status = false;
          self.commentFiles = [];
        });
    },
    editdeletecomments(type, item) {
      var self = this;
      this.editablecomment = item.text;
      if (type == 'edit') {
        if (
          item.updateType == 'timerstoped' ||
          item.updateType == 'timerreported'
        ) {
          if (item.updateType == 'timerstoped') {
            self.startTimerstatus = true;
            self.reportTimerstatus = false;
          } else if (item.updateType == 'timerreported') {
            self.reportTimerstatus = true;
            self.startTimerstatus = false;
          }
          self.$http
            .get(
              process.env.VUE_APP_ROOT_API + 'task/get-timerinfo/' + item.task,
              {
                params: {
                  updateType: item.updateType,
                  taskid: item.task,
                  timerId: item.timerId,
                  ReportId: item.ReportId,
                },
              }
            )
            .then((response) => {
              self.starttimerinfo = response.data.timerinfo;
              self.reporttimerinfo = response.data.reportinfo;
              if (response.data.timerinfo) {
                this.timerStartdate = this.moment(
                  parseInt(response.data.timerinfo.startTime)
                ).format('YYYY-MM-DD HH:mm');
                this.timerEnddate = this.moment(
                  parseInt(response.data.timerinfo.endTime)
                ).format('YYYY-MM-DD HH:mm');
              }
            });
          self.updatepopup = true;
        } else {
          self.settingButton = false;
          self.editable.id = item.id;
          self.editable.status = true;
        }
      } else {
        if (
          item.updateType == 'timerstoped' ||
          item.updateType == 'timerreported'
        ) {
          this.deleteinfo = item;
          if (item.updateType == 'timerstoped') {
            self.startTimerstatus = true;
            self.reportTimerstatus = false;
            self.deleteinfo.deletetype = 'timer';
          } else if (item.updateType == 'timerreported') {
            self.reportTimerstatus = true;
            self.startTimerstatus = false;
            self.deleteinfo.deletetype = 'report';
          }
          this.deleteconfirm = true;
        } else {
          this.settingButton = true;
          this.openRemoveCommentDialog();
        }
      }
    },
    submiteditdata(text, comment) {
      var self = this;
      if (
        text != '' &&
        text != '<p></p>' &&
        text != '<ul><li><p></p></li></ul>' &&
        text != '<ol><li><p></p></li></ol>'
      ) {
        if (this.commentFiles.length > 0) {
          this.updateFile(text, comment);
        } else {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                'task/update-comment/' +
                comment.id,
              {
                comment: text,
              },
              {
                headers: {
                  'X-CSRF-Token': this.$store.state.account.csrf,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              self.comment.text = response.data.text;
              this.$nextTick(() => {
                this.$validator.reset();
              });
              self.editable.id = '';
              self.editable.status = false;
              this.settingButton = true;
            })
            .catch(() => {});
        }
      } else {
        this.$emit('editvalidation', 'Your comment cannot be empty');
      }
    },
    openRemoveFileDialog(file, index) {
      this.$emit('openRemoveFileDialog', this.comment.id, file, index);
    },
    openRemoveCommentDialog() {
      this.$emit('openRemoveCommentDialog', this.comment.id);
    },
    updateFile(text, comment) {
      var self = this;
      if (this.commentFiles.length > 0) {
        var formData = new FormData();
        this.settingButton = true;
        var filetotalsize = 0;
        this.commentFiles.forEach((elem) => {
          filetotalsize = filetotalsize + elem.size;
          formData.append('files', elem);
        });
        if (filetotalsize < 50000000) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                'task/upload-files-to-comment/' +
                this.comment.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'X-CSRF-Token': this.$store.state.account.csrf,
                },
                withCredentials: true,
              }
            )
            .then((res) => {
              res.data.forEach((elem) => {
                this.comment.attachments.push(elem);
              });
              this.commentFiles = [];
              this.$validator.reset();
            })
            .catch(() => {});

          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                'task/update-comment/' +
                comment.id,
              {
                comment: text,
              },
              {
                headers: {
                  'X-CSRF-Token': this.$store.state.account.csrf,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              self.comment.text = response.data.text;
              this.$nextTick(() => {
                this.$validator.reset();
              });
              self.editable.id = '';
              self.editable.status = false;
            })
            .catch(() => {});
        } else {
          this.$emit('editvalidation', 'File size should be less than 50mb');
        }
      }
    },
    uploadFileForComment(files) {
      files.forEach((file) => {
        var typeOfFiles = [
          'video/mp4',
          'video/x-msvideo',
          'video/quicktime',
          'audio/ogg',
          'video/x-flv',
          'video/3gpp2',
          'video/ogg',
          'video/x-ms-wmv',
          'video/webm',
          'video/x-ms-asf',
          'video/x-matroska',
          'application/vnd.ms-asf',
          'video/avi',
          'video/msvideo',
          'video/x-msvideo/x-msvideovideo',
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/svg',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/pdf',
          'application/vnd.ms-powerpoint',
          'text/plain',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/vnd.ms-excel',
          'video/mp4',
        ];
        const typeOfFile = file.type.split('/')[0];
        const allowedToUpload = typeOfFiles.includes(file.type);
        if (allowedToUpload) {
          this.commentFiles.push(file);
          if (typeOfFile == 'image') {
            this.previewFiles.push({
              type: 'image',
              url: URL.createObjectURL(file),
            });
          } else {
            this.previewFiles.push({
              type: 'file',
              name: file.name,
            });
          }
        }
      });
      this.files = [];
    },
    sortbycomment(data) {
      return data.slice().sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
    },
  },
  components: {
    TiptapVuetify,
    userinfo,
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.comment-content p,
.inside-timer p {
  font-size: 14px;
  margin-bottom: 0;
}
.comment-content p:first-letter,
.inside-timer p::first-letter {
  text-transform: capitalize;
}
.comment-single-task .attach-outline {
  border: 1px dotted;
}

.comment-single-task .delete-preview-button {
  right: 5px;
  top: 5px;
}

.comment-content {
  display: block;
  width: 100%;
}

.attachment-filename {
  position: absolute;
  bottom: -1px;
  background-color: #f7f7f7;
  width: 100%;
  border-radius: 0 0 6px 6px;
  color: #000;
}

blockquote {
  border-left: 3px solid rgba(#0d0d0d, 0.1);
  padding-left: 1rem;
}

.attachments::v-deep,
#comments::v-deep {
  .delete-preview {
    right: -10px;
    top: -10px;
  }
  .d-flex.justify-center.align-center.v-card.v-sheet.theme--light {
    height: 127px;
  }
}
.launch-icon-image {
  top: -6em;
  left: 4em;
}
.theme--dark .v-time-picker-clock__item--active > span {
  color: #000;
}
</style>
