var getparam = document.location.href
  .substring(document.location.href.indexOf("?") + 1)
  .split("&");
for (var i = 0, urlqueryparam = {}; i < getparam.length; i++) {
  getparam[i] = getparam[i].split("=");
  urlqueryparam[getparam[i][0]] = decodeURIComponent(getparam[i][1]);
}
import axios from "axios";
// initial state
const state = {
  csrf: "",
  subscribedstatus: "",
  token: localStorage.getItem("token") || "",
  user: localStorage.getItem("user") || "",
  Isworkspaceuser: false,
  Isprojectuser: false,
  Isprojectmanager: false,
  companyusers: [],
  companyinvitedusers: [],
};
// getters
const getters = {
  authorized: (state) => (state.user ? state.user : false),
  subscribed: (state) => {
    state.subscribedstatus = false;
    axios
      .get(
        process.env.VUE_APP_ROOT_API + "subscription/getsubscriptionstatus",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        state.subscribedstatus = response.data;
      });
    return state.subscribedstatus;
  },
  isCompanyAdmin: (state) => {
    var returnstatus = false;
    if (state.user) {
      var currcompany = JSON.parse(state.user).allcompanies.find(
        (data) => data.id == urlqueryparam.companyId
      );
      if (currcompany.isAdmin == true) {
        returnstatus = true;
      }
    }
    return returnstatus;
  },
  Isworkspaceuserstatus: (state) => {
    return state.Isworkspaceuser;
  },
  Isprojectmanagerstatus: (state) => {
    return state.Isprojectmanager;
  },
  Isprojectuserstatus: (state) => {
    return state.Isprojectuser;
  },
  user: () => {
    state.user = localStorage.getItem("user");
    return state.user != null ? JSON.parse(state.user) : false;
  },
};
// actions
const actions = {
  storeLoginDetails({ commit }, payload) {
    return new Promise((resolve) => {
      const token = payload.token;
      const user = JSON.stringify(payload.user);
      // TODO: STORE IN COOKIES RATHEER THAN STORAGE
      localStorage.setItem("token", token);
      localStorage.setItem("user", user);
      localStorage.setItem(
        "tooltipInitial",
        user.tooltipDescription === null ||
          user.tooltipDescription === undefined
          ? true
          : user.tooltipDescription
      );
      localStorage.setItem(
        "dontShowAgain",
        user.tooltipTimer === null || user.tooltipTimer === undefined
          ? true
          : user.tooltipTimer
      );
      commit("authSuccess", { token, user });
      resolve();
    });
  },
  setRefreshToken({ commit }, payload) {
    const token = payload.token;
    const user = JSON.stringify(payload.user);
    localStorage.setItem("token", token);
    localStorage.setItem("user", user);
    commit("authSuccess", { token, user });
  },
  logout({ commit }) {
    axios
      .post(process.env.VUE_APP_ROOT_API + "entrance/logout", {
        date: new Date().getTime(),
      })
      .then(() => {
        commit("logout");
      });
  },
  refreshtoken({ commit }) {
    axios
      .get(process.env.VUE_APP_ROOT_API + "refresh")
      .then((response) => {
        const token = response.data.token;
        const user = JSON.stringify(response.data.user);
        // TODO: STORE IN COOKIES RATHEER THAN STORAGE
        localStorage.setItem("token", token);
        localStorage.setItem("user", user);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        commit("authSuccess", {
          token,
          user,
        });
      })
      .catch(() => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      });
  },
  workspaceuserauthorizedstatus({ commit }, payload) {
    return axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "workspace/isworkspaceuser/" +
          payload.workspaceid,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        commit("updateIsworkspaceuser", response.data);
      })
      .catch((error) => {
        if(error.response.status!==404){ 
          commit("updateIsworkspaceuser", false);
        }
      });
  },
  getcompanyandinvitedusers({ commit }) {
    return axios
      .get(process.env.VUE_APP_ROOT_API + "account/get-company-users", {
        timestamp: new Date().getTime(),
      })
      .then((response) => {
        commit("updateinvitedandcompanyusers", response.data);
      });
  },
  projectauthorizedstatus({ commit }, payload) {
    if (payload.projectid) {
      return axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "project/isprojectuser/" +
            payload.projectid,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          commit("updateIsprojectuser", response.data);
        })
        .catch(() => {
          commit("updateIsprojectuser", {
            user: false,
            manager: false,
          });
        });
    }
  },
};
const mutations = {
  updateIsworkspaceuser(state, pyload) {
    state.Isworkspaceuser = pyload;
  },
  updateinvitedandcompanyusers(state, pyload) {
    state.companyusers = pyload.companyUsers;
    state.companyinvitedusers = pyload.companyInvitations;
  },
  addtoinvitaiton(state, pyload) {
    state.companyinvitedusers = pyload;
  },
  removeinvitation(state, pyload) {
    var findEmailIndex = state.companyinvitedusers.findIndex(
      (data) => data.emailAddress == pyload.emailAddress
    );
    if (findEmailIndex != -1) {
      state.companyinvitedusers.splice(findEmailIndex, 1);
    }
  },
  updateIsprojectuser(state, pyload) {
    state.Isprojectuser = pyload.user;
    state.Isprojectmanager = pyload.manager;
  },
  authSuccess(state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  commituser(state, { user }) {
    state.user = JSON.stringify(user);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem(
      "tooltipInitial",
      user.tooltipDescription === null || user.tooltipDescription === undefined
        ? true
        : user.tooltipDescription
    );
    localStorage.setItem(
      "dontShowAgain",
      user.tooltipTimer === null || user.tooltipTimer === undefined
        ? true
        : user.tooltipTimer
    );
  },
  logout(state) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("mytime_filter");
    localStorage.removeItem("myreport_filter");
    localStorage.removeItem("project_time_filter");
    localStorage.removeItem("project_report_filter");
    localStorage.removeItem("project_sortkey");
    localStorage.removeItem("project_filter_array");
    localStorage.removeItem("leave_control");
    localStorage.removeItem("taskview");
    localStorage.removeItem("selectedWorkspaceId");
    localStorage.removeItem("selectedWorkspaceName");
    localStorage.removeItem("myworkfilterdata");
    localStorage.removeItem("iniatialQuickStart");
    state.token = "";
    state.user = "";
    new Promise((resolve) => {
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
    localStorage.setItem("skipStartTooltip", false);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
