<template>
  <div>
    <h6 class="font-weight-bold">Upload Files</h6>
    <div class="post-comment my-3 py-3 post-comment-border">
      <div class="d-flex flex-column align-center justify-center pt-2">
        <template v-if="previewFiles.length > 0">
          <h6 class="flex-grow-1 pl-3" style="width: 100%">
            {{ attachementFiles.length }}
            {{ previewFiles.length > 1 ? "files" : "file" }}
            added
          </h6>
        </template>
        <v-row class="flex-grow-1" style="width: 100%">
          <v-col
            v-for="(singlepreviewfile, index) in previewFiles"
            :key="index"
            cols="3"
          >
            <v-card class="d-flex justify-center align-center rounded-lg">
              <template v-if="singlepreviewfile.type == 'image'">
                <v-img
                  :src="singlepreviewfile.url"
                  aspect-ratio="1"
                  class="comment-file-icon rounded-lg"
                ></v-img>
              </template>
              <template v-if="singlepreviewfile.type == 'file'">
                <v-icon class="comment-file-icon">file_present</v-icon>
              </template>
              <v-btn
                absolute
                depressed
                x-small
                fab
                class="delete-preview-button right top error"
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                @click="removePreview(index)"
              >
                <v-icon>delete_outline</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <div
          class="d-flex align-center flex-column upload-fileSection"
          v-if="canEditTask"
        >
          <v-file-input
            v-model="taskfiles"
            data-vv-scope="commentscope"
            v-validate="
              'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.ppt.txt,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,video/3gpp2,.ppt,audio/ogg,.asf,application/vnd.ms-asf,  application/vnd.ms-powerpoint'
            "
            accept="application/msword,.asf,,.ppt, application/vnd.ms-asf,application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*,.xlsx,.doc,.docx,.ods,text/plain, application/vnd.ozpenxmlformats-officedocument.wordprocessingml.document, application/msword,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv"
            @change="attachFile"
            name="commentfile"
            data-vv-as="file"
            :error-messages="errors.collect('commentscope.commentfile')"
            multiple
            small
            title="Add file"
            :hide-input="true"
            class="pt-0"
            v-if="attachementFiles.length <= 0"
          >
          </v-file-input>
          <p
            v-if="attachementFiles.length <= 0"
            class="text-caption font-weight-bold"
          >
            Select Files
          </p>
        </div>
      </div>
      <div class="d-flex justify-end pr-2">
        <v-btn
          :disabled="commentLoader"
          :card-props="{
            loading: commentLoader,
          }"
          color="primary"
          v-if="attachementFiles.length > 0"
          small
          @click="postComment"
        >
          <v-icon small class="pr-1">upload</v-icon> Upload Files
        </v-btn>
      </div>
    </div>

    <div class="attachments" v-if="task.attachments != null">
      <div
        class="d-flex flex-wrap justify-start"
        v-if="task.attachments.length > 0"
      >
        <h6 class="flex-grow-1 pl-3" style="width: 100%">
          {{ task.attachments.length }}
          {{ task.attachments.length > 1 ? "files" : "file" }}
          attached
        </h6>
        <v-card
          v-for="singleattachement in sortbycomment(task.attachments)"
          :key="singleattachement.id"
          class="d-flex justify-center align-center mx-2 pa-2 mt-4 rounded-lg elevation-2"
          :width="130"
          :height="130"
          outlined
        >
          <template v-if="singleattachement.type.split('/')[0] == 'image'">
            <a :href="singleattachement.path" target="_blank">
              <v-img
                :lazy-src="require('src/assets/comment-image-placeholder.png')"
                :src="singleattachement.path"
                aspect-ratio="1"
                absolute
                class="rounded"
              ></v-img>
            </a>
          </template>
          <template v-else>
            <v-icon large :title="singleattachement.path">insert_link</v-icon>
          </template>
          <div
            v-if="singleattachement.filename"
            class="attachment-filename pa-1 text-caption text-truncate"
          >
            {{ singleattachement.filename }}
          </div>
          <v-btn
            absolute
            depressed
            x-small
            fab
            class="delete-preview-button error"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
            @click="openRemoveFileDialog(singleattachement)"
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
          <v-btn
            icon
            :href="singleattachement.path"
            target="_blank"
            :absolute="
              singleattachement.type.split('/')[0] !== 'image' ? false : true
            "
          >
            <v-icon color="grey lighten-1">launch</v-icon>
          </v-btn>
        </v-card>
      </div>
    </div>
    <confirmation
      :propdialog="deletetaskattachementDialog"
      dialogTitle="Are you sure you want to delete this attachment"
      @onConfirmed="removetaskfileFile"
      @onCancel="closeTaskDeleteFileDialog"
      key="delete-comment-attachment"
    ></confirmation>
    <v-snackbar v-model="showSnackBar" :bottom="true" :color="messagecolor">{{
      snackMessage
    }}</v-snackbar>
  </div>
</template>
<script>
import confirmation from "src/components/ConfirmationDialog";
export default {
  data() {
    return {
      showSnackBar: false,
      snackMessage: "",
      messagecolor: "red",
      taskfiles: [],
      attachementFiles: [],
      commentLoader: false,
      previewFiles: [],
      deletetaskattachementDialog: false,
      deleteattachementIndex: "",
      deleteattachementId: "",
      task: this.proptask,
    };
  },
  props: {
    canDeleteTask: {
      required: false,
      type: Boolean,
    },
    proptask: {
      required: true,
      type: Object,
    },
  },
  computed: {
    authuser() {
      return JSON.parse(this.$store.state.account.user).id;
    },
  },
  created() {},
  mounted() {},
  methods: {
    openRemoveFileDialog(attachement) {
      var self = this;
      var attachements = self.sortbycomment(self.task.attachments);
      var index = attachements.findIndex((data) => data.id == attachement.id);
      self.deletetaskattachementDialog = true;
      self.deleteattachementIndex = index;
      self.deleteattachementId = attachement.id;
    },
    closeTaskDeleteFileDialog() {
      this.deletetaskattachementDialog = false;
      this.deleteattachementIndex = "";
      this.deleteattachementId = "";
    },
    removetaskfileFile() {
      var self = this;
      var postdata = {
        taskid: self.$route.params.taskId,
        attachementid: self.deleteattachementId,
      };
      this.$http
        .post(
          process.env.VUE_APP_ROOT_API +
            "task/delete-fileattachement/" +
            self.$route.params.taskId,
          postdata
        )
        .then(() => {
          this.task.attachments.splice(this.deleteattachementIndex, 1);
          this.deletetaskattachementDialog = false;
          this.deleteattachementIndex = "";
          this.deleteattachementId = "";
        })
        .catch((error) => {
          this.commentLoader = false;
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    sortbycomment(data) {
      return data.slice().sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
    },
    canEditTask(userid) {
      var authuser = JSON.parse(this.$store.state.account.user);
      var allmanagers = [];
      this.$store.state.project.projectUsers.forEach((user) => {
        if (
          user.user == userid &&
          (user.isManager == true || user.isAdmin == true)
        ) {
          allmanagers.push(user.user);
        }
      });
      if (
        authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin ||
        authuser.id == userid
      ) {
        return true;
      } else {
        return allmanagers.includes(
          JSON.parse(this.$store.state.account.user).id
        );
      }
    },
    removePreview(index) {
      this.previewFiles.splice(index, 1);
      this.attachementFiles.splice(index, 1);
    },
    postComment() {
      var self = this;
      self.commentLoader = true;
      var formData = new FormData();
      formData.set("id", self.task.id);
      var filetotalsize = 0;
      self.attachementFiles.forEach((elem) => {
        filetotalsize = filetotalsize + elem.size;
        formData.append("files", elem);
      });
      if (self.attachementFiles.length > 0) {
        if (filetotalsize < 50000000) {
          self.$http
            .post(
              process.env.VUE_APP_ROOT_API +
                "task/task-attachement/" +
                self.$route.params.taskId,
              formData
            )
            .then((res) => {
              this.$emit("commitattachements", res.data.attachments);
              self.attachementFiles = [];
              self.previewFiles = [];
              self.commentLoader = false;
            })
            .catch((error) => {
              self.commentLoader = false;
              if (error.response.data && error.response.data.message) {
                self.snackMessage = error.response.data.message;
                self.showSnackBar = true;
                self.messagecolor = "red";
              }
            });
        } else {
          self.snackMessage = "File size should be less than 50mb";
          self.showSnackBar = true;
          self.commentLoader = false;
        }
      } else {
        self.snackMessage = "please add file";
        self.showSnackBar = true;
        self.commentLoader = false;
      }
    },
    attachFile(files) {
      files.forEach((file) => {
        var typeOfFiles = [
          "video/mp4",
          "video/x-msvideo",
          "video/quicktime",
          "audio/ogg",
          "video/x-flv",
          "video/3gpp2",
          "video/ogg",
          "video/x-ms-wmv",
          "video/webm",
          "video/x-ms-asf",
          "video/x-matroska",
          "application/vnd.ms-asf",
          "video/avi",
          "video/msvideo",
          "video/x-msvideo/x-msvideovideo",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/svg",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/pdf",
          "application/vnd.ms-powerpoint",
          "text/plain",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.ms-excel",
          "video/mp4",
        ];
        const typeOfFile = file.type.split("/")[0];
        const allowedToUpload = typeOfFiles.includes(file.type);
        if (allowedToUpload) {
          this.attachementFiles.push(file);
          if (typeOfFile == "image") {
            this.previewFiles.push({
              type: "image",
              url: URL.createObjectURL(file),
            });
          } else {
            this.previewFiles.push({
              type: "file",
              name: file.name,
            });
          }
        }
      });
      this.taskfiles = [];
    },
  },
  components: { confirmation },
};
</script>
<style scoped>
.attachment-filename {
  width: 130px;
  position: absolute;
  bottom: -2px;
  background-color: #f7f7f7;
  border-radius: 0 0 8px 8px;
  color: #000;
}
.post-comment-border {
  outline-style: dashed;
  border-color: #ccc;
  outline-color: #ccc;
}
.upload-fileSection .v-text-field {
  width: auto;
}
.delete-preview-button {
  right: 5px;
  top: 5px;
}
</style>
