import Vue from "vue";
import Router from "vue-router";
import Store from "./store/store";
import SingleTask from "./views/pages/dashboard/project/SingleProject/Task.vue";
Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/trial-expired",
      name: "trial-expired",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./views/pages/_trialExpired.vue"
        ),
      meta: {
        layout: "unsubscribe-layout",
        requiresAuth: true,
        subscription: false,
      },
    },
    {
      path: "/token-expired",
      name: "token-expired",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/pages/expiredError.vue"),
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/password/new/:token?",
      name: "forgotpassword",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./views/pages/forgotPassword.vue"
        ),
      beforeEnter: (to, from, next) => {
        next();
      },
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/email/confirm",
      name: "emailconfirmationpage",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./views/pages/Emailconfirmation.vue"
        ),
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/error",
      name: "errorpage",
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./views/pages/dashboard/error.vue"
        ),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/pages/Login.vue"),
      beforeEnter: (to, from, next) => {
        if (Store.getters["account/authorized"]) {
          if (Store.getters["account/user"]) {
            var routename =
              Store.getters["account/user"].isAdmin == false
                ? "/mywork"
                : "/subscriptionflows";
            next({
              path: routename,
              query: {
                companyId:
                  from.query && from.query.companyId
                    ? from.query.companyId
                    : Store.getters["account/user"].companyId,
              },
            });
          } else {
            next();
          }
        } else {
          next();
        }
      },
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/signup/:companyid?/:token?",
      name: "signup",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/pages/Register.vue"),
      beforeEnter: (to, from, next) => {
        if (Store.getters["account/authorized"]) {
          if (Store.getters["account/user"]) {
            var routename =
              Store.getters["account/user"].isAdmin == false ||
              from.name == "login"
                ? "/mywork"
                : "/subscriptionflows";
            next({
              path: routename,
              query: {
                companyId:
                  from.query && from.query.companyId
                    ? from.query.companyId
                    : Store.getters["account/user"].companyId,
              },
            });
          } else {
            next();
          }
        } else {
          next();
        }
      },
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/join/:companyid/:token",
      name: "join",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/pages/Join.vue"),
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
    },
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "landing-page" */ "./views/pages/Home.vue"),
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
    },

    {
      path: "/views",
      name: "views",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/views.vue"
        ),
    },
    {
      path: "/project-management",
      name: "project-management",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/project-management.vue"
        ),
    },
    {
      path: "/customizing-my-work",
      name: "customizing-my-work",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/customizing-my-work.vue"
        ),
    },
    {
      path: "/crm-overview",
      name: "crm-overview",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/crm-overview.vue"
        ),
    },
    {
      path: "/task-management",
      name: "task-management",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/task-management.vue"
        ),
    },
    {
      path: "/roles-management",
      name: "roles-management",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/roles-management.vue"
        ),
    },
    {
      path: "/user-interface",
      name: "user-interface",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/user-interface.vue"
        ),
    },
    {
      path: "/faq",
      name: "landing-faq",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/landing-faq.vue"
        ),
    },
    {
      path: "/time-management",
      name: "time-management",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/time-management.vue"
        ),
    },
    {
      path: "/workspaces",
      name: "workspaces",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/workspaces.vue"
        ),
    },
    {
      path: "/team-collaboration",
      name: "team-collaboration",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/team-collaboration.vue"
        ),
    },
    {
      path: "/holiday-management",
      name: "holiday-management",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/holiday-management.vue"
        ),
    },
    {
      path: "/customization",
      name: "customization",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/customization.vue"
        ),
    },
    {
      path: "/contact-us",
      name: "contact-us",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/contact-us.vue"
        ),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/privacy-policy.vue"
        ),
    },
    {
      path: "/terms-conditions",
      name: "terms-condition",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/terms-condition.vue"
        ),
    },
    {
      path: "/return-policy",
      name: "return-policy",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/return-policy.vue"
        ),
    },
    {
      path: "/pricing",
      name: "pricing",
      meta: {
        layout: "landingpage",
        requiresAuth: false,
        subscription: false,
        section: "landing",
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/landingpage/pricing.vue"
        ),
    },

    {
      path: "/notifications",
      name: "notifications",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "./views/pages/dashboard/Notifications.vue"
        ),
    },
    {
      path: "/subscriptionflows",
      name: "subscriptionflows",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "subscription" */ "./views/pages/dashboard/subscriptionflows.vue"
        ),
      beforeEnter: (to, from, next) => {
        if (Store.getters["account/authorized"]) {
          if (Store.getters["account/user"].isAdmin == true) {
            next();
          } else {
            next("/error");
          }
        } else {
          next("/error");
        }
      },
    },
    {
      path: "/allSubscriptions",
      name: "allSubscriptions",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "subscription" */ "./views/pages/dashboard/allSubscriptions.vue"
        ),
      beforeEnter: (to, from, next) => {
        if (Store.getters["account/authorized"]) {
          if (Store.getters["account/user"].isAdmin == true) {
            next();
          } else {
            next("/error");
          }
        } else {
          next("/error");
        }
      },
    },
    {
      path: "/help",
      name: "faq",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "landing-page" */ "./views/pages/dashboard/help.vue"
        ),
    },
    {
      path: "/chat",
      name: "chat",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "chat" */ "./views/pages/dashboard/chat/Chat.vue"
        ),
      children: [
        {
          path: ":chatroomid",
          name: "chat.chatroom",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var chatroomid = to.params.chatroomid;
          //   await Store.dispatch("chat/authorizedChatStatus", {
          //     chatroomid,
          //   }).then(() => {
          //     if (Store.getters["chat/Authstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "chat"*/ "./views/pages/dashboard/chat/ChatRoom.vue"
            ),
        },
      ],
    },
    {
      path: "/myTime",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "my-time" */ "./views/pages/dashboard/mytimer.vue"
        ),
      children: [
        {
          path: "/myTime",
          name: "my-time",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "my-time" */ "./views/pages/dashboard/MyTime.vue"
            ),
        },
        {
          path: "myTimeReport",
          name: "my-time.my-time-report",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "my-time" */ "./views/pages/dashboard/_mytimereport.vue"
            ),
        },
      ],
    },
    {
      path: "/featureRequest",
      name: "features",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "feature" */ "./views/pages/dashboard/_features.vue"
        ),
    },
    {
      layout: "dashboard-layout",
      path: "/search/:searchdata",
      name: "search-expand",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "search" */ "./views/pages/dashboard/_searchexpand.vue"
        ),
    },
    {
      path: "/invite-user",
      name: "invite-user",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
        isCompanyAdmin: true,
      },
      beforeEnter: (to, from, next) => {
        if (
          Store.getters["account/user"].allcompanies &&
          Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ) &&
          (Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ).isAdmin ||
            Store.getters["account/user"].allcompanies.find(
              (data) => data.id == to.query.companyId
            ).isManager)
        ) {
          next();
        } else {
          next("/error");
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/InviteUser.vue"
        ),
    },
    {
      path: "/about",
      name: "about",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "./views/pages/About.vue"),
    },
    {
      path: "/workspace/:id",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
        section: "workspace",
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/SingleWorkspace.vue"
        ),
      // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
      // beforeEnter: async (to, from, next) => {
      //   var workspaceid = to.params.id;
      //   await Store.dispatch("account/workspaceuserauthorizedstatus", {
      //     workspaceid,
      //   }).then(() => {
      //     if (Store.getters["account/Isworkspaceuserstatus"]) {
      //       next();
      //     } else {
      //       next("/error");
      //     }
      //   });
      // },
      children: [
        {
          path: "",
          name: "single-workspace",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "workspace",
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_workspaceProjects.vue"
            ),
        },
        {
          path: "template",
          name: "single-workspace.template",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "workspace",
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_workspaceTemplates.vue"
            ),
        },
      ],
    },
    {
      path: "/mywork",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/myWork.vue"
        ),
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      children: [
        {
          path: "",
          name: "my-work.inbox",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_inbox.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
        },
        {
          path: "today",
          name: "my-work.today",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_today.vue"
            ),
        },
        {
          path: "week",
          name: "my-work.week",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_week.vue"
            ),
        },
        {
          path: "mytask",
          name: "my-work.mytask",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_mytask.vue"
            ),
        },
        {
          path: "updatemytime",
          name: "updatemytime",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "my-time" */ "./views/pages/dashboard/updatemytime.vue"
            ),
        },
      ],
    },
    {
      path: "/project/:id/task/:taskId",
      name: "single-task",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
        section: "project",
      },
      beforeEnter: async (to, from, next) => {
        // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
        // var projectid = to.params.id;
        // await Store.dispatch("account/projectauthorizedstatus", {
        //   projectid,
        // }).then(() => {
        // if (Store.getters["account/Isprojectuserstatus"]) {
        const twModalView = from.name ? true : false;
        if (!twModalView) {
          // For direct access TODO: DO WE NEED DIRECT ACCESS
          //
          to.matched[0].components = {
            default: SingleTask,
            modal: false,
          };
        }
        if (twModalView) {
          //
          // For twModalView access
          //
          if (from.matched.length > 1) {
            // copy nested router
            const childrenView = from.matched.slice(1, from.matched.length);
            for (let view of childrenView) {
              to.matched.push(view);
            }
          }
          if (to.matched[0].components) {
            // Rewrite components for `default`
            to.matched[0].components.default =
              from.matched[0].components.default;
            // Rewrite components for `modal`
            to.matched[0].components.modal = SingleTask;
            // Fix [vue-router] missing param for named route
            Object.assign(to.params, from.params);
          }
        }
        next();
        // } else {
        //   next("/error");
        // }
        // });
      },
    },
    {
      path: "/project/:id",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
        section: "project",
      },
      component: () =>
        import(
          /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject.vue"
        ),
      children: [
        {
          path: "",
          name: "single-project",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectuserstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Summary.vue"
            ),
        },
        {
          path: "tasks",
          meta: {
            layout: "dashboard-layout",
            view: "tasks",
            requiresAuth: true,
            subscription: true,
            breadCrumb: "Tasks",
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectuserstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Tasks.vue"
            ),
          children: [
            {
              path: "",
              name: "single-project-tasks",
              meta: {
                layout: "dashboard-layout",
                view: "list",
                requiresAuth: true,
                subscription: true,
                section: "project",
              },
              component: () =>
                import(
                  /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/List.vue"
                ),
            },
            {
              path: "board",
              name: "single-project-tasks.board",
              meta: {
                layout: "dashboard-layout",
                requiresAuth: true,
                subscription: true,
                view: "board",
                section: "project",
              },
              component: () =>
                import(
                  /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Board.vue"
                ),
            },
            {
              path: "calendar",
              name: "single-project-tasks.calendar",
              meta: {
                layout: "dashboard-layout",
                requiresAuth: true,
                subscription: true,
                view: "calendar",
                section: "project",
              },
              component: () =>
                import(
                  /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Calendar.vue"
                ),
            },
            {
              path: "ganttChart",
              name: "single-project-tasks.ganttChart",
              meta: {
                layout: "dashboard-layout",
                requiresAuth: true,
                subscription: true,
                view: "gantt",
                section: "project",
              },
              component: () =>
                import(
                  /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/GanttChart.vue"
                ),
            },
            {
              path: "table",
              name: "single-project-tasks.table",
              meta: {
                layout: "dashboard-layout",
                requiresAuth: true,
                subscription: true,
                view: "table",
                section: "project",
              },
              component: () =>
                import(
                  /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Table.vue"
                ),
            },
          ],
        },
        {
          path: "files",
          name: "single-project-files",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectuserstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-task" */ "./views/pages/dashboard/project/SingleProject/Files.vue"
            ),
        },
        {
          path: "users",
          name: "project-users",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectuserstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Users.vue"
            ),
        },
        {
          path: "settings",
          name: "project-settings",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectmanagerstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Settings.vue"
            ),
        },
        {
          path: "value",
          name: "project-value",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectmanagerstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "single-project" */ "./views/pages/dashboard/project/SingleProject/Value.vue"
            ),
        },
        {
          path: "project-time",
          name: "timer.project_timer",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectmanagerstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "my-time" */ "./views/pages/dashboard/project/SingleProject/Projectimer.vue"
            ),
        },
        {
          path: "project-report",
          name: "timer.project_report",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "project",
          },
          // NOTE::CAUSE OF IMPROVE REDIRECT WITHOUT GETTING DELAY COMMENTED
          // beforeEnter: async (to, from, next) => {
          //   var projectid = to.params.id;
          //   await Store.dispatch("account/projectauthorizedstatus", {
          //     projectid,
          //   }).then(() => {
          //     if (Store.getters["account/Isprojectmanagerstatus"]) {
          //       next();
          //     } else {
          //       next("/error");
          //     }
          //   });
          // },
          component: () =>
            import(
              /* webpackChunkName: "my-time" */ "./views/pages/dashboard/project/SingleProject/ProjectReport.vue"
            ),
        },
      ],
    },
    // TODO: GROUP THIS UNDER ONE PARENT
    {
      path: "/leave/leavecontrol",
      name: "leave-control",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/leave/LeaveControl.vue"
        ),
    },
    {
      path: "/user:userId/",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/projectuser/_singleuser.vue"
        ),
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
        section: "single-user",
      },
      children: [
        {
          path: "",
          name: "singleuser-info",
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            section: "single-user",
            subscription: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/projectuser/_userSummary.vue"
            ),
        },
        {
          path: "useractivity",
          name: "singleuser-info.useractivity",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/projectuser/_useractivity.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
            section: "single-user",
          },
        },
      ],
    },
    {
      path: "/profile",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/Profile.vue"
        ),
      children: [
        {
          path: "",
          name: "profile.user-profile",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_userProfile.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
        },
        {
          path: "/update-password",
          name: "profile.update-password",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_updateuserPassword.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
        },
        {
          path: "/subscriptions",
          name: "profile.subscriptions",
          beforeEnter: (to, from, next) => {
            if (
              Store.getters["account/user"].allcompanies &&
              Store.getters["account/user"].allcompanies.find(
                (data) => data.id == to.query.companyId
              ) &&
              Store.getters["account/user"].allcompanies.find(
                (data) => data.id == to.query.companyId
              ).isAdmin
            ) {
              next();
            } else {
              next("/error");
            }
          },
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/_subscriptions.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
        },
        {
          path: "/payments",
          name: "profile.payments",
          beforeEnter: (to, from, next) => {
            if (
              Store.getters["account/user"].allcompanies &&
              Store.getters["account/user"].allcompanies.find(
                (data) => data.id == to.query.companyId
              ) &&
              Store.getters["account/user"].allcompanies.find(
                (data) => data.id == to.query.companyId
              ).isAdmin
            ) {
              next();
            } else {
              next("/error");
            }
          },
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "./views/pages/dashboard/_payments.vue"
            ),
          meta: {
            layout: "dashboard-layout",
            requiresAuth: true,
            subscription: true,
          },
        },
      ],
    },
    {
      path: "/layout-display",
      name: "LayoutDisplay",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/LayoutDisplay.vue"
        ),
    },
    //to create sunscription on company ownwr
    {
      path: "/allplans",
      name: "allplans",
      meta: {
        layout: "unsubscribe-layout",
        requiresAuth: true,
        subscription: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/allPlans.vue"
        ),
      beforeEnter: (to, from, next) => {
        if (
          Store.getters["account/user"].allcompanies &&
          Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ) &&
          Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ).isAdmin
        ) {
          next();
        } else {
          next("/error");
        }
      },
    },
    {
      path: "/cardDetail/:id",
      name: "cardDetail",
      meta: {
        layout: "unsubscribe-layout",
        requiresAuth: true,
        subscription: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "card-detail" */ "./views/pages/dashboard/Subscription/CardDetail.vue"
        ),
      beforeEnter: (to, from, next) => {
        if (
          Store.getters["account/user"].allcompanies &&
          Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ) &&
          Store.getters["account/user"].allcompanies.find(
            (data) => data.id == to.query.companyId
          ).isAdmin
        ) {
          next();
        } else {
          next("/error");
        }
      },
    },
    {
      path: "/company/:id",
      name: "singlecompany",
      meta: {
        layout: "dashboard-layout",
        requiresAuth: true,
        subscription: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/dashboard/singlecompany.vue"
        ),
      beforeEnter: (to, from, next) => {
        var authuser = Store.getters["account/user"];
        if (authuser.isAdmin) {
          next();
        } else {
          next("/error");
        }
      },
    },
    {
      path: "*",
      name: "commonerrorpage",
      meta: {
        layout: "auth-layout",
        requiresAuth: false,
        subscription: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/pages/pageNotFound.vue"
        ),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    Store.getters["account/authorized"] &&
    Store.getters["account/authorized"] != "undefined"
  ) {
    let returnstatus = false;
    if (Store.getters["account/user"].allcompanies && to.query.companyId) {
      if (
        Store.getters["account/user"].allcompanies.findIndex(
          (data) => data.id == to.query.companyId
        ) > -1
      ) {
        returnstatus = true;
      } else {
        next("/error");
      }
    } else {
      returnstatus = true;
    }
    if (returnstatus) {
      if (
        Store.getters["account/user"].isAdmin == true ||
        to.matched.some((record) => record.meta.layout == "landingpage")
      ) {
        next();
      } else {
        if (!to.query.companyId) {
          to.query.companyId =
            from.query && from.query.companyId
              ? from.query.companyId
              : Store.getters["account/user"].companyId;
          next({ path: to.path, query: to.query });
        } else {
          next();
        }
      }
    }
    return;
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      to.query.to = to.fullPath;
      to.query.companyId =
        from.query && from.query.companyId
          ? from.query.companyId
          : Store.getters["account/user"].companyId;
      next({
        name: "login",
        query: to.query,
      });
    } else {
      if (!to.query && !to.query.companyId) {
        if (from.query && from.query.companyId) {
          to.query.companyId = from.query.companyId;
          next({ path: to.path, query: to.query });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  }
});
export default router;
